import React, { FC } from 'react'
import { Alert } from '../../../components/ui'
const AlertExample: FC = () => {
  return (
    <div style={styles.container}>
      <div style={{ marginBottom: 60, display: 'flex', flexDirection: 'column', gap: 40 }}>
        <Alert type={'info'} label={'Additional description and information about copyrighting'} />
        <Alert type={'warning'} label={'Additional description and information about copyrighting'} />
        <Alert type={'success'} label={'Additional description and information about copyrighting'} />
        <Alert type={'danger'} label={'Additional description and information about copyrighting'} />
        <Alert type={'info'} label={'Additional description and information about copyrighting'} size={'sm'} />
        <Alert type={'warning'} label={'Additional description and information about copyrighting'} size={'sm'} />
        <Alert type={'success'} label={'Additional description and information about copyrighting'} size={'sm'} />
        <Alert type={'danger'} label={'Additional description and information about copyrighting'} size={'sm'} />
      </div>
    </div>
  )
}
const styles: CSSObject = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    padding: '15%',
    justifyContent: 'center',
  },
}
export default AlertExample
