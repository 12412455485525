import React, { FC } from 'react'
import Checkbox from '../../../components/ui/Checkbox'

const CheckboxExample: FC = () => {
  return (
    <div style={styles.container}>
      <Checkbox size={'sm'} onChange={(checked) => console.log(checked)} />
      <Checkbox size={'md'} />
      <Checkbox size={'lg'} />
      <Checkbox size={'sm'} checked={true} />
      <Checkbox size={'md'} checked={true} />
      <Checkbox size={'lg'} checked={true} />
      <Checkbox size={'sm'} disabled={true} />
      <Checkbox size={'md'} disabled={true} />
      <Checkbox size={'lg'} disabled={true} />
      <Checkbox size={'sm'} disabled={true} checked={true} />
      <Checkbox size={'md'} disabled={true} checked={true} />
      <Checkbox size={'lg'} disabled={true} checked={true} />
    </div>
  )
}
const styles: CSSObject = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '15%',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 40,
  },
}
export default CheckboxExample
