import React from 'react'
import { RouteConfig } from './index'
import ButtonExample from '../../views/dev/showcase/ButtonExample'
import CardExample from '../../views/dev/showcase/CardExample'
import IconExample from '../../views/dev/showcase/IconExample'
import TypographyExample from '../../views/dev/showcase/TypographyExample'
import TableExample from '../../views/dev/showcase/TableExample'
import FormExample from '../../views/dev/showcase/FormExample'
import DatePickerExample from '../../views/dev/showcase/DatePickerExample'
import CalendarExample from '../../views/dev/showcase/CalendarExample'
import AvatarExample from '../../views/dev/showcase/AvatarExample'
import AvatarGroupExample from '../../views/dev/showcase/AvatarGroupExample'
import BadgeExample from '../../views/dev/showcase/BadgeExample'
import DropdownExample from '../../views/dev/showcase/DropdownExample'
import AlertExample from '../../views/dev/showcase/AlertExample'
import IndicatorExample from '../../views/dev/showcase/IndicatorExample'
import DrawerExample from '../../views/dev/showcase/DrawerExample'
import UpdatedIconExample from '../../views/dev/showcase/UpdatedIconExample'
import CheckboxExample from '../../views/dev/showcase/CheckboxExample'
import UserDropdownExample from '../../views/dev/showcase/UserDropdownExample'
import InputExample from '../../views/dev/showcase/InputExample'
import BrandExample from '../../views/dev/showcase/BrandExample'
import ProgressLoaderExample from '../../views/dev/showcase/ProgressLoaderExample'
import TagExample from '../../views/dev/showcase/TagExample'
import SignInScreen from '../../views/auth/SignIn'
import ToastExample from '../../views/dev/showcase/ToastExample'
import TooltipExample from '../../views/dev/showcase/TooltipExample'
import DialogueExample from '../../views/dev/showcase/DialogueExample'
import SegmentExample from '../../views/dev/showcase/SegmentExample'
import SelectExample from '../../views/dev/showcase/SelectExample'
import PaginationExample from '../../views/dev/showcase/PaginationExample'
import DividerExample from '../../views/dev/showcase/DividerExample'
import HeaderExample from '../../views/dev/showcase/HeaderExample'
import NotificationDropdownItemExample from '../../views/dev/showcase/NotificationDropdownItemExample'
import NotificationDropdownButtonExample from '../../views/dev/showcase/NotificationDropdownButtonExample'
import Dev from '../../views/dev'
import NotificationDropdownHeaderExample from '../../views/dev/showcase/NotificationDropdownHeaderExample'
import TabsExample from '../../views/dev/showcase/TabsExample'
import NotificationDropdownFooterExample from '../../views/dev/showcase/NotificationDropdownFooterExample'
import NotificationDropdownExample from '../../views/dev/showcase/NotificationDropdownExample'
import ProgramDropdownExample from '../../views/dev/showcase/ProgramDropdownExample'

const devRoutes: RouteConfig[] = [
  {
    title: 'Dev',
    key: 'dev',
    path: '/',
    component: React.lazy(() => Promise.resolve({ default: Dev })),
  },
  {
    title: 'UI Button',
    key: 'uiButton',
    path: '/ui/button',
    component: React.lazy(() => Promise.resolve({ default: ButtonExample })),
  },
  {
    title: 'UI Icon',
    key: 'uiIcon',
    path: '/ui/icon',
    component: React.lazy(() => Promise.resolve({ default: IconExample })),
  },
  {
    title: 'theme Typography',
    key: 'themeTypography',
    path: '/theme/typography',
    component: React.lazy(() => Promise.resolve({ default: TypographyExample })),
  },
  {
    title: 'UI Table',
    key: 'uiTable',
    path: '/ui/table',
    component: React.lazy(() => Promise.resolve({ default: TableExample })),
  },
  {
    title: 'UI Form',
    key: 'uiForm',
    path: '/ui/form',
    component: React.lazy(() => Promise.resolve({ default: FormExample })),
  },
  {
    title: 'UI Date Picker',
    key: 'uiDatePicker',
    path: '/ui/date-picker',
    component: React.lazy(() => Promise.resolve({ default: DatePickerExample })),
  },
  {
    title: 'UI Calendar',
    key: 'uiCalendar',
    path: '/ui/calendar',
    component: React.lazy(() => Promise.resolve({ default: CalendarExample })),
  },
  {
    title: 'UI Avatar',
    key: 'uiAvatar',
    path: '/ui/avatar',
    component: React.lazy(() => Promise.resolve({ default: AvatarExample })),
  },
  {
    title: 'UI Avatar Group',
    key: 'uiAvatarGroup',
    path: '/ui/avatar-group',
    component: React.lazy(() => Promise.resolve({ default: AvatarGroupExample })),
  },
  {
    title: 'UI Badge',
    key: 'uiBadge',
    path: '/ui/badge',
    component: React.lazy(() => Promise.resolve({ default: BadgeExample })),
  },
  {
    title: 'UI Dropdown',
    key: 'uiDropdown',
    path: '/ui/dropdown',
    component: React.lazy(() => Promise.resolve({ default: DropdownExample })),
  },
  {
    title: 'UI Alert',
    key: 'uiAlert',
    path: '/ui/alert',
    component: React.lazy(() => Promise.resolve({ default: AlertExample })),
  },
  {
    title: 'UI Indicator',
    key: 'uiIndicator',
    path: '/ui/indicator',
    component: React.lazy(() => Promise.resolve({ default: IndicatorExample })),
  },
  {
    title: 'UI Drawer',
    key: 'uiDrawer',
    path: '/ui/drawer',
    component: React.lazy(() => Promise.resolve({ default: DrawerExample })),
  },
  {
    title: 'UI Icon Update',
    key: 'uiIconUpdate',
    path: '/ui/icon-update',
    component: React.lazy(() => Promise.resolve({ default: UpdatedIconExample })),
  },
  {
    title: 'UI Checkbox',
    key: 'uiCheckbox',
    path: '/ui/checkbox',
    component: React.lazy(() => Promise.resolve({ default: CheckboxExample })),
  },
  {
    title: 'Layout User Dropdown',
    key: 'layoutUserDropdown',
    path: '/layout/user-dropdown',
    component: React.lazy(() => Promise.resolve({ default: UserDropdownExample })),
  },
  {
    title: 'UI Input',
    key: 'uiInput',
    path: '/ui/input',
    component: React.lazy(() => Promise.resolve({ default: InputExample })),
  },
  {
    title: 'UI Brand',
    key: 'uiBrand',
    path: '/ui/brand',
    component: React.lazy(() => Promise.resolve({ default: BrandExample })),
  },
  {
    title: 'UI Progress Loader',
    key: 'uiProgressLoader',
    path: '/ui/progress-loader',
    component: React.lazy(() => Promise.resolve({ default: ProgressLoaderExample })),
  },
  {
    title: 'UI Tag',
    key: 'uiTag',
    path: '/ui/tag',
    component: React.lazy(() => Promise.resolve({ default: TagExample })),
  },
  {
    title: 'Layout Side',
    key: 'layoutSide',
    path: '/layout/side',
    component: React.lazy(() => Promise.resolve({ default: SignInScreen })),
  },
  {
    title: 'UI Toast',
    key: 'uiToast',
    path: '/ui/toast',
    component: React.lazy(() => Promise.resolve({ default: ToastExample })),
  },
  {
    title: 'UI Tooltip',
    key: 'uiTooltip',
    path: '/ui/tooltip',
    component: React.lazy(() => Promise.resolve({ default: TooltipExample })),
  },
  {
    title: 'UI Divider',
    key: 'uiDivider',
    path: '/ui/divider',
    component: React.lazy(() => Promise.resolve({ default: DividerExample })),
  },
  {
    title: 'UI Dialog',
    key: 'uiDialog',
    path: '/ui/dialog',
    component: React.lazy(() => Promise.resolve({ default: DialogueExample })),
  },
  {
    title: 'UI Segment',
    key: 'uiSegment',
    path: '/ui/segment',
    component: React.lazy(() => Promise.resolve({ default: SegmentExample })),
  },
  {
    title: 'UI Select',
    key: 'uiSelect',
    path: '/ui/select',
    component: React.lazy(() => Promise.resolve({ default: SelectExample })),
  },
  {
    title: 'UI Pagination',
    key: 'uiPagination',
    path: '/ui/pagination',
    component: React.lazy(() => Promise.resolve({ default: PaginationExample })),
  },
  {
    title: 'UI Tabs',
    key: 'uiTabs',
    path: '/ui/tabs',
    component: React.lazy(() => Promise.resolve({ default: TabsExample })),
  },
  {
    title: 'Layout Header',
    key: 'layoutHeader',
    path: '/layout/header',
    component: React.lazy(() => Promise.resolve({ default: HeaderExample })),
  },
  {
    title: 'Layout Notification Dropdown Item',
    key: 'layoutNotificationDropdownItem',
    path: '/layout/notification-dropdown-item',
    component: React.lazy(() => Promise.resolve({ default: NotificationDropdownItemExample })),
  },
  {
    title: 'Layout Notification Dropdown Button',
    key: 'layoutNotificationDropdownButton',
    path: '/layout/notification-dropdown-button',
    component: React.lazy(() => Promise.resolve({ default: NotificationDropdownButtonExample })),
  },
  {
    title: 'Layout Notification Dropdown Header',
    key: 'layoutNotificationDropdownHeader',
    path: '/layout/notification-dropdown-header',
    component: React.lazy(() => Promise.resolve({ default: NotificationDropdownHeaderExample })),
  },
  {
    title: 'Layout Notification Dropdown Footer',
    key: 'layoutNotificationDropdownFooter',
    path: '/layout/notification-dropdown-footer',
    component: React.lazy(() => Promise.resolve({ default: NotificationDropdownFooterExample })),
  },
  {
    title: 'Layout Notification Dropdown',
    key: 'layoutNotificationDropdown',
    path: '/layout/notification-dropdown',
    component: React.lazy(() => Promise.resolve({ default: NotificationDropdownExample })),
  },
  {
    title: 'Layout Program Dropdown',
    key: 'layoutProgramDropdown',
    path: '/layout/program-dropdown-example',
    component: React.lazy(() => Promise.resolve({ default: ProgramDropdownExample })),
  },
  {
    title: 'UI Card',
    key: 'uiCard',
    path: '/ui/card',
    component: React.lazy(() => Promise.resolve({ default: CardExample })),
  },
].map((route) => ({
  ...route,
  path: ('/dev' + route.path) as Path,
}))

export default devRoutes
