import React from 'react'
import NotificationDropdownItem from '../../../components/layout/components/Header/HeaderItem/NotificationDropdown/NotificationDropdownItem'
import { Avatar } from '../../../components/ui'

const NotificationDropdownItemExample = () => {
  const now = new Date()

  const yesterday = new Date()
  yesterday.setDate(yesterday.getDate() - 1)

  const twoDaysAgo = new Date()
  twoDaysAgo.setDate(twoDaysAgo.getDate() - 2)

  const threeWeeksAgo = new Date()
  threeWeeksAgo.setDate(threeWeeksAgo.getDate() - 21)

  return (
    <div className={'flex justify-center items-center h-screen'}>
      <div className={'w-[530px] bg-gray-dark p-5 body-sm'}>
        <NotificationDropdownItem
          onClick={() => alert('clicked notification!')}
          timestamp={now}
          prefix={'John Doe'}
          text={'accepted your invitation to begin Christian Discipleship'}
          onPrefixClick={() => alert('clicked John Doe!')}
          customIcon={<Avatar src={'https://randomuser.me/api/portraits/men/1.jpg'} size={40} />}
        />
        <NotificationDropdownItem
          onClick={() => alert('clicked notification!')}
          timestamp={yesterday}
          prefix={'Jane Doe'}
          text={'says hi!'}
          customIcon={<Avatar src={'https://randomuser.me/api/portraits/women/1.jpg'} size={40} />}
        />
        <NotificationDropdownItem
          onClick={() => alert('clicked notification!')}
          timestamp={twoDaysAgo}
          unread={false}
          textHtml={
            'This is an example of using the <b>textHtml</b> prop <i>instead of</i> <b>prefix</b> and <b>title</b>'
          }
          customIcon={<Avatar src={'https://randomuser.me/api/portraits/men/2.jpg'} size={40} />}
        />
        <NotificationDropdownItem
          onClick={() => alert('clicked notification!')}
          timestamp={threeWeeksAgo}
          unread={false}
          onPrefixClick={() => alert('clicked Anne Smith!')}
          prefix={'Anne Smith'}
          text={'accepted your invitation to begin Christian Discipleship'}
          customIcon={<Avatar src={'https://randomuser.me/api/portraits/women/2.jpg'} size={40} />}
        />
      </div>
    </div>
  )
}

export default NotificationDropdownItemExample
