import React, { FC, ReactNode } from 'react'

type FormActionsProps = {
  size?: 'lg' | 'md' | 'sm'
  children: ReactNode
  layout?: 'horizontal' | 'vertical'
}

const FormActions: FC<FormActionsProps> = ({ layout = 'horizontal', size = 'md', children }) => {
  const buttonContainerClasses = `flex ${layout === 'vertical' ? 'flex-col gap-[15px]' : 'gap-[15px]'}`

  return <div className={buttonContainerClasses}>{children}</div>
}

export default FormActions
