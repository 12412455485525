import React, { FC } from 'react'

type TooltipProps = {
  title: string
}

const Tooltip: FC<TooltipProps> = ({ title }) => {
  return (
    <div className="absolute z-10 p-1 bg-gray-200 text-black text-center rounded border border-gray-400 whitespace-nowrap text-xs transform -translate-x-1/2 bottom-full left-1/2">
      {title}
    </div>
  )
}
export default Tooltip
