import React, { forwardRef, ForwardedRef, cloneElement } from 'react'
import classNames from 'classnames'
import { useTabs } from './context'
import useCallbackRef from '../../../hooks/useCallbackRef'

export type TabNavProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  value: string
  disabled?: boolean
  icon?: React.ReactNode
  className?: string
}

const TabNav = forwardRef(function TabNav(
  { value: valueProp, disabled, className, icon, children, ...rest }: TabNavProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const { value, onValueChange, variant } = useTabs()
  const isSelected = valueProp === value

  const onTabNavClick = useCallbackRef(() => {
    if (!isSelected && !disabled) {
      onValueChange(valueProp)
    }
  })

  const tabNavClass = classNames(
    'tab-nav leading-[20px] font-semibold flex items-center justify-center transition duration-200 ease-in-out cursor-pointer text-gray-dark -mb-[1px]',
    `${
      variant === 'underline' ? 'py-3.5 px-4 border-b-[2px] border-[transparent]' : 'py-3 px-4 mr-1 rtl:ml-1 rounded-md'
    }`,
    isSelected && 'tab-nav-active !text-breakerBay',
    isSelected && variant === 'underline' && '!border-breakerBay',
    isSelected && variant === 'pill' && '!bg-breakerBay-50',
    disabled && 'opacity-60 !cursor-not-allowed',
    !disabled && !isSelected && 'tab-nav-selectable hover:text-breakerBay',
    className,
  )

  return (
    <div className={tabNavClass} role="tab" aria-selected={isSelected} ref={ref} onClick={onTabNavClick} {...rest}>
      {icon && (
        <div className={'mr-1'}>
          {icon ? cloneElement(icon as React.ReactElement, { className: 'transition duration-200 ease' }) : null}
        </div>
      )}
      {children}
    </div>
  )
})

export default TabNav
