import React, { FC } from 'react'
import { Divider } from '../../../components/ui'

const DividerExample: FC = () => {
  return (
    <div className={'p-5 bg-white mt-10'}>
      <div className={'mt-10'}>
        <Divider className={'w-[150px]'} />
      </div>
      <div className={'mt-10'}>
        <Divider className={'w-[250px]'} />
      </div>
      <div className={'mt-10'}>
        <Divider className={'w-[350px]'} />
      </div>
      <div className={'mt-10'}>
        <Divider className={'w-[500px]'} />
      </div>
    </div>
  )
}

export default DividerExample
