import React from 'react'
import NotificationDropdownHeader from '../../../components/layout/components/Header/HeaderItem/NotificationDropdown/NotificationDropdownHeader'

const NotificationDropdownHeaderExample = () => {
  return (
    <div className={'flex justify-center items-center h-screen'}>
      <div className={'w-[530px] bg-gray-100 body-sm'}>
        <NotificationDropdownHeader onMarkAllRead={() => alert('marked as read')} />
      </div>
    </div>
  )
}

export default NotificationDropdownHeaderExample
