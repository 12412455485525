import React, { FC } from 'react'
import { Avatar } from '../../../components/ui'

const AvatarGroupExample: FC = () => {
  const handleClick = () => {
    alert('Omitted avatars clicked!')
  }

  return (
    <div className="flex justify-center flex-col gap-3 items-center mt-24">
      <Avatar.Group
        chained
        maxCount={4}
        omittedAvatarProps={{ shape: 'circle', size: 'lg' }}
        onOmittedAvatarClick={handleClick}
        omittedAvatarTooltip={true}>
        <Avatar shape="circle" size="lg" src={'https://randomuser.me/api/portraits/men/7.jpg'} name="User 1" />
        <Avatar shape="circle" size="lg" src={'https://randomuser.me/api/portraits/men/5.jpg'} name="User 2" />
        <Avatar shape="circle" size="lg" src={'https://randomuser.me/api/portraits/women/71.jpg'} name="User 3" />
        <Avatar shape="circle" size="lg" src={'https://randomuser.me/api/portraits/men/4.jpg'} name="User 4" />
        <Avatar shape="circle" size="lg" src={'https://randomuser.me/api/portraits/women/7.jpg'} name="User 5" />
        <Avatar shape="circle" size="lg" src={'https://randomuser.me/api/portraits/women/11.jpg'} name="User 6" />
        <Avatar shape="circle" size="lg" src={'https://randomuser.me/api/portraits/women/71.jpg'} name="User 7" />
      </Avatar.Group>
      <Avatar.Group chained maxCount={4} onOmittedAvatarClick={handleClick} omittedAvatarTooltip={true}>
        <Avatar
          onClick={() => alert('First avatar clicked!')}
          src={'https://randomuser.me/api/portraits/men/7.jpg'}
          name="User 1"
        />
        <Avatar src={'https://randomuser.me/api/portraits/men/5.jpg'} name="User 2" />
        <Avatar src={'https://randomuser.me/api/portraits/women/71.jpg'} name="User 3" />
        <Avatar src={'https://randomuser.me/api/portraits/men/4.jpg'} name="User 4" />
        <Avatar src={'https://randomuser.me/api/portraits/women/7.jpg'} name="User 5" />
        <Avatar src={'https://randomuser.me/api/portraits/women/11.jpg'} name="User 6" />
        <Avatar
          onClick={() => alert('First avatar clicked!')}
          src={'https://randomuser.me/api/portraits/women/71.jpg'}
          name="User 7"
        />
      </Avatar.Group>
      <Avatar.Group
        chained
        maxCount={4}
        omittedAvatarProps={{ shape: 'circle', size: 'sm' }}
        onOmittedAvatarClick={handleClick}
        omittedAvatarTooltip={true}>
        <Avatar shape="circle" size="sm" src={'https://randomuser.me/api/portraits/men/7.jpg'} name="User 1" />
        <Avatar shape="circle" size="sm" src={'https://randomuser.me/api/portraits/men/5.jpg'} name="User 2" />
        <Avatar shape="circle" size="sm" src={'https://randomuser.me/api/portraits/women/71.jpg'} name="User 3" />
        <Avatar shape="circle" size="sm" src={'https://randomuser.me/api/portraits/men/4.jpg'} name="User 4" />
        <Avatar shape="circle" size="sm" src={'https://randomuser.me/api/portraits/women/7.jpg'} name="User 5" />
        <Avatar shape="circle" size="sm" src={'https://randomuser.me/api/portraits/women/11.jpg'} name="User 6" />
        <Avatar shape="circle" size="sm" src={'https://randomuser.me/api/portraits/women/71.jpg'} name="User 7" />
      </Avatar.Group>
      <Avatar.Group
        chained
        maxCount={4}
        omittedAvatarProps={{ shape: 'rounded', size: 'sm' }}
        onOmittedAvatarClick={handleClick}
        omittedAvatarTooltip={true}>
        <Avatar shape="rounded" size="sm" src={'https://randomuser.me/api/portraits/men/7.jpg'} name="User 1" />
        <Avatar shape="rounded" size="sm" src={'https://randomuser.me/api/portraits/men/5.jpg'} name="User 2" />
        <Avatar shape="rounded" size="sm" src={'https://randomuser.me/api/portraits/women/71.jpg'} name="User 3" />
        <Avatar shape="rounded" size="sm" src={'https://randomuser.me/api/portraits/men/4.jpg'} name="User 4" />
        <Avatar shape="rounded" size="sm" src={'https://randomuser.me/api/portraits/women/7.jpg'} name="User 5" />
        <Avatar shape="rounded" size="sm" src={'https://randomuser.me/api/portraits/women/11.jpg'} name="User 6" />
        <Avatar shape="rounded" size="sm" src={'https://randomuser.me/api/portraits/women/71.jpg'} name="User 7" />
      </Avatar.Group>
      <Avatar.Group
        chained
        maxCount={4}
        omittedAvatarProps={{ shape: 'rounded' }}
        onOmittedAvatarClick={handleClick}
        omittedAvatarTooltip={true}>
        <Avatar shape="rounded" src={'https://randomuser.me/api/portraits/men/7.jpg'} name="User 1" />
        <Avatar shape="rounded" src={'https://randomuser.me/api/portraits/men/5.jpg'} name="User 2" />
        <Avatar shape="rounded" src={'https://randomuser.me/api/portraits/women/71.jpg'} name="User 3" />
        <Avatar shape="rounded" src={'https://randomuser.me/api/portraits/men/4.jpg'} name="User 4" />
        <Avatar shape="rounded" src={'https://randomuser.me/api/portraits/women/7.jpg'} name="User 5" />
        <Avatar shape="rounded" src={'https://randomuser.me/api/portraits/women/11.jpg'} name="User 6" />
        <Avatar shape="rounded" src={'https://randomuser.me/api/portraits/women/71.jpg'} name="User 7" />
      </Avatar.Group>
    </div>
  )
}

export default AvatarGroupExample
