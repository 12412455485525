import React, { FC } from 'react'
import Segment from '../../../components/ui/Segment/Segment'

const SegmentExample: FC = () => {
  return (
    <div className="p-8 space-y-6">
      <Segment size={'lg'} selectionType={'single'}>
        <Segment.Item isFirst value={'Option1'}>
          Segment
        </Segment.Item>
        <Segment.Item disabled value={'Option2'}>
          Segment
        </Segment.Item>
        <Segment.Item isLast value={'Option3'}>
          Segment
        </Segment.Item>
      </Segment>
      <Segment size={'md'} selectionType={'multiple'}>
        <Segment.Item isFirst value={'Option1'}>
          Segment
        </Segment.Item>
        <Segment.Item value={'Option2'}>Segment</Segment.Item>
        <Segment.Item isLast value={'Option3'}>
          Segment
        </Segment.Item>
      </Segment>
      <Segment size={'sm'} selectionType={'single'}>
        <Segment.Item isFirst value={'Option1'}>
          Segment
        </Segment.Item>
        <Segment.Item value={'Option2'}>Segment</Segment.Item>
        <Segment.Item isLast value={'Option3'}>
          Segment
        </Segment.Item>
      </Segment>
      <Segment size={'xs'} selectionType={'multiple'}>
        <Segment.Item isFirst value={'Option1'}>
          Segment
        </Segment.Item>
        <Segment.Item value={'Option2'}>Segment</Segment.Item>
        <Segment.Item isLast value={'Option3'}>
          Segment
        </Segment.Item>
      </Segment>
    </div>
  )
}

export default SegmentExample
