import React from 'react'
import { Button, Dropdown } from '../../../components/ui'
import { Checkbox, Icon, Input, Notification, toast } from '../../../components/ui'
import DropdownItem from '../../../components/ui/Dropdown/DropdownItem'
import useToast from '../../../hooks/useToast'

const ToastExample = () => {
  const [type, setType] = React.useState<'info' | 'success' | 'warning' | 'error'>('info')
  const [placement, setPlacement] = React.useState<
    'top-start' | 'top-center' | 'top-end' | 'bottom-start' | 'bottom-center' | 'bottom-end'
  >('top-end')
  const [transition, setTransition] = React.useState<'scale' | 'fade' | 'slide'>('fade')
  const [message, setMessage] = React.useState<string>('Hello')
  const [title, setTitle] = React.useState<string>('Title')
  const [duration, setDuration] = React.useState<number>(3000)
  const [closable, setClosable] = React.useState<boolean>(false)

  const _toastHook = useToast()

  const onClick = () => {
    toast.push(
      <Notification title={title} duration={duration} type={type} closable={closable}>
        {message}
      </Notification>,
      { placement, transitionType: transition },
    )
  }

  const onClickCustomOne = () => {
    const CustomIcon = <Icon name={'gift'} color={'blue-400'} />
    toast.push(
      <Notification
        title={'You received a gift!'}
        duration={duration}
        type={type}
        closable={closable}
        customIcon={CustomIcon}>
        Something you may like...
      </Notification>,
      { placement, transitionType: transition },
    )
  }

  const onClickCustomTwo = () => {
    toast.push(
      <Notification
        title={'Jonathan Hooper'}
        duration={duration}
        type={type}
        closable={closable}
        customIconUrl={'https://s3.us-east-2.amazonaws.com/cdn.www.oos-studio.com/prod/Jonathan.jpg'}>
        There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in
        some form, by injected humour, or randomised words which dont look even slightly believable.
      </Notification>,
      { placement, transitionType: transition },
    )
  }

  const onClickHook = () => {
    _toastHook.push('Title', 'Message')
  }

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div className={'mb-5'}>
        <Dropdown title={type || 'Notification Type'}>
          <DropdownItem title={'info'} onClick={() => setType('info')} />
          <DropdownItem title={'success'} onClick={() => setType('success')} />
          <DropdownItem title={'warning'} onClick={() => setType('warning')} />
          <DropdownItem title={'error'} onClick={() => setType('error')} />
        </Dropdown>
      </div>
      <div className={'mb-5'}>
        <Dropdown title={placement || 'Notification Placement'}>
          <DropdownItem title={'top-start'} onClick={() => setPlacement('top-start')} />
          <DropdownItem title={'top-center'} onClick={() => setPlacement('top-center')} />
          <DropdownItem title={'top-end'} onClick={() => setPlacement('top-end')} />
          <DropdownItem title={'bottom-start'} onClick={() => setPlacement('bottom-start')} />
          <DropdownItem title={'bottom-center'} onClick={() => setPlacement('bottom-center')} />
          <DropdownItem title={'bottom-end'} onClick={() => setPlacement('bottom-end')} />
        </Dropdown>
      </div>
      <div className={'mb-5'}>
        <Dropdown title={transition || 'Toast Transition'}>
          <DropdownItem title={'scale'} onClick={() => setTransition('scale')} />
          <DropdownItem title={'fade'} onClick={() => setTransition('fade')} />
          <DropdownItem title={'slide'} onClick={() => setTransition('slide')} />
        </Dropdown>
      </div>
      <div className={'mb-5'}>
        Toast Title
        <Input
          placeholder={'Title'}
          onChange={(e: { target: { value: React.SetStateAction<string> } }) => setTitle(e.target.value)}
        />
      </div>
      <div className={'mb-5'}>
        Toast Message
        <Input
          placeholder={'Hello'}
          onChange={(e: { target: { value: React.SetStateAction<string> } }) => setMessage(e.target.value)}
        />
      </div>
      <div className={'mb-5'}>
        Duration
        <Input
          placeholder={'3000'}
          onChange={(e: { target: { value: string } }) => setDuration(Number(e.target.value))}
        />
      </div>
      <div className={'mb-5'}>
        <Checkbox checked={closable} onChange={() => setClosable(!closable)} label={'Closable'} />
      </div>
      <div className={'mb-5'}>
        <Button disabled={!type || !placement || !transition} variant={'solid'} onClick={onClick}>
          Create Toast
        </Button>
      </div>
      <div className={'mb-5'}>
        <Button disabled={!type || !placement || !transition} variant={'solid'} onClick={onClickCustomOne}>
          View Custom Toast 1
        </Button>
      </div>
      <div className={'mb-5'}>
        <Button disabled={!type || !placement || !transition} variant={'solid'} onClick={onClickCustomTwo}>
          View Custom Toast 2
        </Button>
      </div>
      <div className={'mb-5'}>
        <Button disabled={!type || !placement || !transition} variant={'solid'} onClick={onClickHook}>
          View Default Hook Toast
        </Button>
      </div>
    </div>
  )
}

export default ToastExample
