import { NotificationsDocument, UpsertNotificationsDocument, ViewAs } from '../../graphql'

const notificationMocks = [
  // Queries
  {
    request: {
      query: NotificationsDocument,
      variables: {
        data: {
          programId: '1',
          viewingAs: ViewAs.Admin,
        },
      },
    },
    result: {
      data: {
        notifications: [
          {
            id: '1',
            text: 'accepted your invitation to begin Christian Discipleship Testing Program Trial v1.0',
            type: 'info',
            viewed: false,
            createdAt: '2023-09-29T00:00:00.000Z',
            person: {
              firstName: 'John',
              lastName: 'Doe',
              avatar: {
                src: 'https://randomuser.me/api/portraits/men/1.jpg',
                __typename: 'Image',
              },
              __typename: 'Person',
            },
            __typename: 'Notification',
          },
          {
            id: '2',
            text: 'says hi!',
            type: 'info',
            createdAt: '2023-06-04T00:00:00.000Z',
            viewed: false,
            person: {
              firstName: 'Jane',
              lastName: 'Doe',
              avatar: {
                src: 'https://randomuser.me/api/portraits/women/1.jpg',
                __typename: 'Image',
              },
              __typename: 'Person',
            },
            __typename: 'Notification',
          },
        ],
      },
    },
  },
  {
    request: {
      query: NotificationsDocument,
      variables: {
        data: {
          programId: '1',
          viewingAs: ViewAs.Admin,
        },
      },
    },
    result: {
      data: {
        notifications: [
          {
            id: '1',
            text: 'accepted your invitation to begin Christian Discipleship Testing Program Trial v1.0',
            type: 'info',
            viewed: true,
            createdAt: '2023-09-29T00:00:00.000Z',
            person: {
              firstName: 'John',
              lastName: 'Doe',
              avatar: {
                src: 'https://randomuser.me/api/portraits/men/1.jpg',
                __typename: 'Image',
              },
              __typename: 'Person',
            },
            __typename: 'Notification',
          },
          {
            id: '2',
            text: 'says hi!',
            type: 'info',
            createdAt: '2023-06-04T00:00:00.000Z',
            viewed: true,
            person: {
              firstName: 'Jane',
              lastName: 'Doe',
              avatar: {
                src: 'https://randomuser.me/api/portraits/women/1.jpg',
                __typename: 'Image',
              },
              __typename: 'Person',
            },
            __typename: 'Notification',
          },
        ],
      },
    },
  },
  {
    request: {
      query: NotificationsDocument,
      variables: {
        data: {
          programId: '1',
          viewingAs: ViewAs.Leader,
        },
      },
    },
    result: {
      data: {
        notifications: [
          {
            id: '3',
            text: 'accepted your invitation to begin Christian Discipleship',
            type: 'info',
            createdAt: '2023-09-04T00:00:00.000Z',
            viewed: false,
            person: {
              firstName: 'Jane',
              lastName: 'Doe',
              avatar: {
                src: 'https://randomuser.me/api/portraits/women/1.jpg',
                __typename: 'Image',
              },
              __typename: 'Person',
            },
          },
          {
            id: '4',
            text: 'Your credit card is about to expire',
            type: 'warning',
            createdAt: '2023-05-29T00:00:00.000Z',
            viewed: false,
          },
        ],
      },
    },
  },
  {
    request: {
      query: NotificationsDocument,
      variables: {
        data: {
          programId: '1',
          viewingAs: ViewAs.Apprentice,
        },
      },
    },
    result: {
      data: {
        notifications: [
          {
            id: '5',
            text: 'accepted your invitation to begin Christian Discipleship',
            type: 'info',
            viewed: false,
            person: {
              firstName: 'Robert',
              lastName: 'Smith',
              avatar: {
                src: 'https://randomuser.me/api/portraits/women/2.jpg',
                __typename: 'Image',
              },
              __typename: 'Person',
            },
          },
          {
            id: '6',
            type: 'error',
            text: 'There was a problem with your payment method.',
            createdAt: '2023-08-29T00:00:00.000Z',
            viewed: true,
          },
        ],
      },
    },
  },
  // Mutations
  {
    request: {
      query: UpsertNotificationsDocument,
      variables: {
        data: {
          programId: '1',
          viewingAs: ViewAs.Admin,
          notifications: [
            {
              id: '1',
              viewed: true,
            },
            {
              id: '2',
              viewed: true,
            },
          ],
        },
      },
    },
    result: {
      data: {
        upsertNotifications: [
          {
            id: '1',
            text: 'accepted your invitation to begin Christian Discipleship Testing Program Trial v1.0',
            type: 'info',
            viewed: true,
            createdAt: '2023-09-29T00:00:00.000Z',
            person: {
              firstName: 'John',
              lastName: 'Doe',
              avatar: {
                src: 'https://randomuser.me/api/portraits/men/1.jpg',
                __typename: 'Image',
              },
              __typename: 'Person',
            },
            __typename: 'Notification',
          },
          {
            id: '2',
            text: 'says hi!',
            type: 'info',
            createdAt: '2023-06-04T00:00:00.000Z',
            viewed: true,
            person: {
              firstName: 'Jane',
              lastName: 'Doe',
              avatar: {
                src: 'https://randomuser.me/api/portraits/women/1.jpg',
                __typename: 'Image',
              },
              __typename: 'Person',
            },
            __typename: 'Notification',
          },
        ],
      },
    },
  },
  {
    request: {
      query: UpsertNotificationsDocument,
      variables: {
        data: {
          programId: '1',
          viewingAs: ViewAs.Leader,
          notifications: [
            {
              id: '3',
              viewed: true,
            },
            {
              id: '4',
              viewed: true,
            },
          ],
        },
      },
    },
    result: {
      data: {
        upsertNotifications: [
          {
            id: '3',
            text: 'accepted your invitation to begin Christian Discipleship',
            type: 'info',
            createdAt: '2023-09-04T00:00:00.000Z',
            viewed: true,
            person: {
              firstName: 'Jane',
              lastName: 'Doe',
              avatar: {
                src: 'https://randomuser.me/api/portraits/women/1.jpg',
                __typename: 'Image',
              },
              __typename: 'Person',
            },
          },
          {
            id: '4',
            text: 'Your credit card is about to expire',
            type: 'warning',
            createdAt: '2023-05-29T00:00:00.000Z',
            viewed: true,
          },
        ],
      },
    },
  },
  {
    request: {
      query: UpsertNotificationsDocument,
      variables: {
        data: {
          programId: '1',
          viewingAs: ViewAs.Apprentice,
          notifications: [
            {
              id: '5',
              viewed: true,
            },
            {
              id: '6',
              viewed: true,
            },
          ],
        },
      },
    },
    result: {
      data: {
        upsertNotifications: [
          {
            id: '5',
            text: 'accepted your invitation to begin Christian Discipleship',
            type: 'info',
            viewed: true,
            person: {
              firstName: 'Robert',
              lastName: 'Smith',
              avatar: {
                src: 'https://randomuser.me/api/portraits/women/2.jpg',
                __typename: 'Image',
              },
              __typename: 'Person',
            },
          },
          {
            id: '6',
            type: 'error',
            text: 'There was a problem with your payment method.',
            createdAt: '2023-08-29T00:00:00.000Z',
            viewed: true,
          },
        ],
      },
    },
  },
]

export default notificationMocks
