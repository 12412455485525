import React, { useMemo, forwardRef } from 'react'
import classNames from 'classnames'
import dayjs from 'dayjs'
import Day from './Day'
import getDayProps, { DayProps } from './utils/getDayProps'
import { isSameDate, getWeekdaysNames, getMonthDays } from '../../utils'
import useTheme from '../../../../../hooks/useTheme'
const noop = () => false

type MonthProps = {
  month: Date
  value: Date
  onChange: (date: Date) => void
  disableOutOfMonth?: boolean
  locale: string
  dayClassName: (date: Date, props: DayProps) => string
  dayStyle: (date: Date, props: DayProps) => React.CSSProperties
  minDate?: Date
  maxDate?: Date
  disableDate: (date: Date) => boolean
  range?: [Date, Date]
  onDayKeyDown: (payload: KeyDownPayload, event: React.KeyboardEvent) => void
  daysRefs: HTMLButtonElement[][]
  renderDay: (date: Date, props: DayProps | null) => React.ReactNode
  labelFormat: { weekday: string; month: string; year: string }
  weekendDays: number[]
  hideOutOfMonthDates?: boolean
  onDayMouseEnter?: (date: Date) => void
  isDateInRange?: (date: Date, props: DayProps) => boolean
  isDateFirstInRange?: (date: Date, props: DayProps) => boolean
  isDateLastInRange?: (date: Date, props: DayProps) => boolean
  hideWeekdays?: boolean
  fullWidth?: boolean
  preventFocus?: boolean
  focusable?: boolean
  className?: string
  firstDayOfWeek?: 'monday' | 'sunday'
  styles?: React.CSSProperties
}

const Month = forwardRef((props: MonthProps, ref) => {
  const {
    className,
    month,
    value,
    onChange,
    disableOutOfMonth = false,
    locale,
    dayClassName,
    dayStyle,
    styles,
    minDate,
    maxDate,
    disableDate,
    onDayMouseEnter,
    range,
    hideWeekdays = false,
    fullWidth = false,
    preventFocus = false,
    focusable = true,
    firstDayOfWeek = 'monday',
    onDayKeyDown,
    daysRefs,
    hideOutOfMonthDates = false,
    isDateInRange = noop,
    isDateFirstInRange = noop,
    isDateLastInRange = noop,
    renderDay,
    labelFormat,
    weekendDays,
    ...rest
  } = props

  const { locale: themeLocale } = useTheme()

  const finalLocale = locale || themeLocale
  const days = getMonthDays(month, firstDayOfWeek)

  const weekdays = getWeekdaysNames(finalLocale, firstDayOfWeek, labelFormat.weekday).map((weekday) => (
    <th className="week-day-cell" key={weekday}>
      <span className="week-day-cell-content">{weekday}</span>
    </th>
  ))

  const hasValue = Array.isArray(value) ? value.every((item) => item instanceof Date) : true

  const hasValueInMonthRange =
    dayjs(value).isAfter(dayjs(month).startOf('month')) && dayjs(value).isBefore(dayjs(month).endOf('month'))

  const firstIncludedDay = useMemo(
    () =>
      days
        .flatMap((_) => _)
        .find((date) => {
          const dayProps = getDayProps({
            date,
            month,
            hasValue,
            minDate: minDate || new Date(0),
            maxDate: maxDate || new Date(9999, 11, 31),
            value,
            disableDate,
            disableOutOfMonth: disableOutOfMonth || false,
            range: range || undefined,
            weekendDays,
          })

          return !dayProps.disabled && !dayProps.outOfMonth
        }) || dayjs(month).startOf('month').toDate(),
    [days, disableDate, disableOutOfMonth, hasValue, maxDate, minDate, month, range, value, weekendDays],
  )

  const rows = days.map((row, rowIndex) => {
    const cells = row.map((date, cellIndex) => {
      const dayProps = getDayProps({
        date,
        month,
        hasValue,
        minDate: minDate || new Date(0),
        maxDate: maxDate || new Date(9999, 11, 31),
        value,
        disableDate,
        disableOutOfMonth: disableOutOfMonth || false,
        range: range || undefined,
        weekendDays,
      })

      const onKeyDownPayload = { rowIndex, cellIndex, date }

      return (
        <td className={classNames('date-picker-cell')} key={cellIndex}>
          <Day
            ref={(button) => {
              if (daysRefs) {
                if (!Array.isArray(daysRefs[rowIndex])) {
                  daysRefs[rowIndex] = []
                }
                daysRefs[rowIndex][cellIndex] = button as HTMLButtonElement
              }
            }}
            onClick={() => typeof onChange === 'function' && onChange(date)}
            onMouseDown={(event: React.MouseEvent) => (preventFocus ? event.preventDefault() : undefined)}
            outOfMonth={dayProps.outOfMonth}
            weekend={dayProps.weekend}
            inRange={dayProps.inRange || isDateInRange(date, dayProps)}
            firstInRange={dayProps.firstInRange || isDateFirstInRange(date, dayProps)}
            lastInRange={dayProps.lastInRange || isDateLastInRange(date, dayProps)}
            firstInMonth={isSameDate(date, firstIncludedDay)}
            selected={dayProps.selected || dayProps.selectedInRange}
            hasValue={hasValueInMonthRange}
            onKeyDown={(event: React.KeyboardEvent) =>
              typeof onDayKeyDown === 'function' && onDayKeyDown(onKeyDownPayload, event)
            }
            className={typeof dayClassName === 'function' ? dayClassName(date, dayProps) : undefined}
            style={dayStyle(date, dayProps)}
            disabled={dayProps.disabled}
            onMouseEnter={typeof onDayMouseEnter === 'function' ? onDayMouseEnter : noop}
            fullWidth={fullWidth}
            focusable={focusable}
            hideOutOfMonthDates={hideOutOfMonthDates}
            styles={styles}
            renderDay={renderDay}
            isToday={isSameDate(date, new Date())}
            value={date}
          />
        </td>
      )
    })

    return (
      <tr className={classNames('date-picker-week-cell')} key={rowIndex}>
        {cells}
      </tr>
    )
  })

  return (
    // @ts-ignore
    <table className={classNames('picker-table', className)} ref={ref} cellSpacing="0" {...rest}>
      {!hideWeekdays && (
        <thead>
          <tr>{weekdays}</tr>
        </thead>
      )}
      <tbody>{rows}</tbody>
    </table>
  )
})

export default Month
