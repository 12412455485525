import React, { MouseEvent, ReactNode, CSSProperties, forwardRef } from 'react'
import classNames from 'classnames'
import { ModalSheet } from '../index'

type DropdownMenuProps = {
  hidden: boolean
  children?: ReactNode
  placement?:
    | 'top-start'
    | 'top-center'
    | 'top-end'
    | 'bottom-start'
    | 'bottom-center'
    | 'bottom-end'
    | 'middle-start-top'
    | 'middle-start-bottom'
    | 'middle-end-top'
    | 'middle-end-bottom'
  menuStyle?: CSSProperties
  menuClass?: string
  renderHeader?: ReactNode
  onMouseLeave?: (e: MouseEvent) => void
  onMouseEnter?: (e: MouseEvent) => void
  open: boolean
  onClose: () => void
  useSheet?: boolean
}

const DropdownMenu = forwardRef<HTMLDivElement, DropdownMenuProps>(
  (
    {
      hidden,
      placement = 'bottom-start',
      menuClass,
      children,
      menuStyle,
      renderHeader,
      onMouseLeave,
      onMouseEnter,
      open,
      onClose,
      useSheet = false,
    }: DropdownMenuProps,
    ref,
  ) => {
    const dropdownMenuDefaultClass =
      'float-left transition-all duration-100 rounded-[10px] max-w-[355px] w-screen flex-col w-fit absolute border-gray-light border-[1px] z-10 bg-white py-[10px] shadow-[3px_3px_30px_0px_rgba(0,0,0,0.10)]'

    let placementClasses = ''

    switch (placement) {
      case 'top-start':
        placementClasses = 'bottom-full origin-bottom mb-[10px]'
        break
      case 'top-center':
        placementClasses = 'bottom-full origin-bottom mb-[10px]'
        break
      case 'top-end':
        placementClasses = 'right-0 bottom-full origin-bottom mb-[10px]'
        break
      case 'bottom-start':
        placementClasses = 'top-full origin-top mt-[10px]'
        break
      case 'bottom-center':
        placementClasses = 'top-full origin-top mt-[10px]'
        break
      case 'bottom-end':
        placementClasses = 'right-0 top-full origin-top mt-[10px]'
        break
      case 'middle-start-top':
        placementClasses = 'left-full top-0 origin-top ml-[10px]'
        break
      case 'middle-start-bottom':
        placementClasses = 'left-full bottom-0 origin-bottom ml-[10px]'
        break
      case 'middle-end-top':
        placementClasses = 'right-full top-0 origin-top mr-[10px]'
        break
      case 'middle-end-bottom':
        placementClasses = 'right-full bottom-0 origin-bottom mr-[10px]'
        break
    }

    const dropdownMenuClass = classNames(dropdownMenuDefaultClass, placementClasses, menuClass)

    return useSheet ? (
      <ModalSheet className={'bg-white'} open={open} onClose={onClose}>
        <div
          ref={ref}
          className={
            'flex flex-col py-[10px] rounded-t-[10px]  border-gray-light border-[1px] shadow-[3px_3px_30px_0px_rgba(0,0,0,0.10)]'
          }
          onMouseLeave={onMouseLeave}
          onMouseEnter={onMouseEnter}
          style={{ ...menuStyle, display: hidden ? 'none' : 'flex' }}>
          {renderHeader}
          {children}
        </div>
      </ModalSheet>
    ) : (
      <div
        ref={ref}
        onClick={(e) => {
          e.stopPropagation()
        }}
        className={dropdownMenuClass}
        onMouseLeave={onMouseLeave}
        onMouseEnter={onMouseEnter}
        style={{ ...menuStyle, display: hidden ? 'none' : 'flex' }}>
        {renderHeader}
        {children}
      </div>
    )
  },
)

export default DropdownMenu
