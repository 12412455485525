import React, { ElementType, HTMLProps, ReactNode, forwardRef } from 'react'
import classNames from 'classnames'

type ThProps<HTMLPropsT = HTMLTableHeaderCellElement> = HTMLProps<HTMLPropsT> & {
  asElement?: ElementType
  children?: ReactNode
}

const Th = forwardRef(({ children, className, asElement: Component = 'th', ...rest }: ThProps, ref) => {
  const thClass = classNames(Component !== 'th' && 'th', className)
  return (
    <Component className={thClass} {...rest} ref={ref}>
      {children}
    </Component>
  )
})

export default Th
