import React, { FC } from 'react'
import FloatingBrand from '../../../components/layout/AuthLayout/FloatingBrand'

const BrandExample: FC = () => {
  return (
    <div style={styles.container}>
      <FloatingBrand />
    </div>
  )
}
const styles: CSSObject = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100vw',
  },
}
export default BrandExample
