import { ToastWrapperProps } from './ToastWrapper'

type AnimationProps = {
  initial?: { opacity: number; transform: string }
  animate?: { opacity: number; transform: string }
  exit?: { opacity: number; transform: string }
}
export const getPlacementTransition = ({ offsetX, offsetY, placement, transitionType }: ToastWrapperProps) => {
  if (transitionType === 'scale' && offsetX && offsetY && placement) {
    return scaleTransition(offsetX, offsetY)[placement]
  }

  if (transitionType === 'fade' && offsetX && offsetY && placement) {
    return fadeTransition(offsetX, offsetY)[placement]
  }

  if (transitionType === 'slide' && offsetX && offsetY && placement) {
    return slideTransition(offsetX, offsetY, placement)
  }
}

const getSlideMotionProps = (
  placement: 'top-end' | 'top-start' | 'top-center' | 'bottom-end' | 'bottom-start' | 'bottom-center',
) => {
  const props: AnimationProps = {}
  switch (placement) {
    case 'top-end':
    case 'bottom-end':
      props.initial = {
        opacity: 0,
        transform: 'translateX(100%)',
      }
      props.animate = {
        opacity: 1,
        transform: 'translateX(0%)',
      }
      props.exit = {
        opacity: 0,
        transform: 'translateX(100%)',
      }
      break
    case 'top-start':
    case 'bottom-start':
      props.initial = {
        opacity: 0,
        transform: 'translateX(-100%)',
      }
      props.animate = {
        opacity: 1,
        transform: 'translateX(0%)',
      }
      props.exit = {
        opacity: 0,
        transform: 'translateX(-100%)',
      }
      break
    case 'bottom-center':
      props.initial = {
        opacity: 0,
        transform: 'translateY(100%)',
      }
      props.animate = {
        opacity: 1,
        transform: 'translateY(0%)',
      }
      props.exit = {
        opacity: 0,
        transform: 'translateY(100%)',
      }
      break
    case 'top-center':
      props.initial = {
        opacity: 0,
        transform: 'translateY(-100%)',
      }
      props.animate = {
        opacity: 1,
        transform: 'translateY(0%)',
      }
      props.exit = {
        opacity: 0,
        transform: 'translateY(-100%)',
      }
      break
    default:
      break
  }
  return props
}

const scaleMotionProps = {
  initial: {
    opacity: 0,
    transform: 'scale(0.75)',
  },
  animate: {
    transform: 'scale(1)',
    opacity: 1,
  },
  exit: {
    opacity: 0,
    transform: 'scale(0.75)',
  },
}

const fadeMotionProps = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
}

const slideTransition = (
  offsetX: number,
  offsetY: number,
  placement: 'top-end' | 'top-start' | 'top-center' | 'bottom-end' | 'bottom-start' | 'bottom-center',
) => {
  const retObj = {
    default: {},
    variants: {
      ...getSlideMotionProps(placement),
    },
  }
  switch (placement) {
    case 'top-end':
      retObj.default = {
        top: offsetY,
        right: offsetX,
      }
      break
    case 'top-start':
      retObj.default = {
        top: offsetY,
        left: offsetX,
      }
      break
    case 'top-center':
      retObj.default = {
        top: offsetY,
        left: '50%',
        transform: 'translateX(-50%)',
      }
      break
    case 'bottom-end':
      retObj.default = {
        bottom: offsetY,
        right: offsetX,
      }
      break
    case 'bottom-start':
      retObj.default = {
        bottom: offsetY,
        left: offsetX,
      }
      break
    case 'bottom-center':
      retObj.default = {
        bottom: offsetY,
        left: '50%',
        transform: 'translateX(-50%)',
      }
      break
    default:
      break
  }
  return retObj
}

const scaleTransition = (offsetX: number, offsetY: number) => {
  return {
    'top-end': {
      default: {
        top: offsetY,
        right: offsetX,
      },
      variants: {
        ...scaleMotionProps,
      },
    },
    'top-start': {
      default: {
        top: offsetY,
        left: offsetX,
      },
      variants: {
        ...scaleMotionProps,
      },
    },
    'top-center': {
      default: {
        top: offsetY,
        left: '50%',
        transform: 'translateX(-50%)',
      },
      variants: {
        ...scaleMotionProps,
      },
    },
    'bottom-end': {
      default: {
        bottom: offsetY,
        right: offsetX,
      },
      variants: {
        ...scaleMotionProps,
      },
    },
    'bottom-start': {
      default: {
        bottom: offsetY,
        left: offsetX,
      },
      variants: {
        ...scaleMotionProps,
      },
    },
    'bottom-center': {
      default: {
        bottom: offsetY,
        left: '50%',
        transform: 'translateX(-50%)',
      },
      variants: {
        ...scaleMotionProps,
      },
    },
  }
}

const fadeTransition = (offsetX: number, offsetY: number) => {
  return {
    'top-end': {
      default: {
        top: offsetY,
        right: offsetX,
      },
      variants: {
        ...fadeMotionProps,
      },
    },
    'top-start': {
      default: {
        top: offsetY,
        left: offsetX,
      },
      variants: {
        ...fadeMotionProps,
      },
    },
    'top-center': {
      default: {
        top: offsetY,
        left: '50%',
        transform: 'translateX(-50%)',
      },
      variants: {
        ...fadeMotionProps,
      },
    },
    'bottom-end': {
      default: {
        bottom: offsetY,
        right: offsetX,
      },
      variants: {
        ...fadeMotionProps,
      },
    },
    'bottom-start': {
      default: {
        bottom: offsetY,
        left: offsetX,
      },
      variants: {
        ...fadeMotionProps,
      },
    },
    'bottom-center': {
      default: {
        bottom: offsetY,
        left: '50%',
        transform: 'translateX(-50%)',
      },
      variants: {
        ...fadeMotionProps,
      },
    },
  }
}
