import { ProgramsDocument } from '../../graphql'
import appConfig from '../../config/app'

const programMocks = [
  {
    request: {
      query: ProgramsDocument,
    },
    result: {
      data: {
        programs: [
          {
            id: '1',
            name: 'Christian Discipleship',
            process: {
              name: 'Discipleship',
              __typename: 'Process',
            },
            icon: {
              src: `${appConfig.issuePath}/img/samples/LifeStoneChristianDiscipleshipIcon.jpeg`,
              __typename: 'Image',
            },
            tenant: {
              id: '1',
              name: 'Lifestone Ministries',
              __typename: 'Tenant',
            },
            __typename: 'Program',
          },
          {
            id: '2',
            name: 'Christian Discipleship',
            process: {
              name: 'Discipleship',
              __typename: 'Process',
            },
            icon: {
              src: `${appConfig.issuePath}/img/samples/ProvidenceChristianDiscipleshipIcon.jpeg`,
              __typename: 'Image',
            },
            tenant: {
              id: '2',
              name: 'Providence Church',
              __typename: 'Tenant',
            },
            __typename: 'Program',
          },
          {
            id: '3',
            name: 'Celebrate Freedom',
            process: {
              name: 'Freedom Recovery',
              __typename: 'Process',
            },
            icon: {
              src: `${appConfig.issuePath}/img/samples/ProvidenceFreedomIcon.jpeg`,
              __typename: 'Image',
            },
            tenant: {
              id: '2',
              name: 'Providence Church',
              __typename: 'Tenant',
            },
            __typename: 'Program',
          },
        ],
      },
    },
  },
]

export default programMocks
