import React, { ForwardedRef, forwardRef, HTMLProps, ElementType, ReactNode } from 'react'
import classNames from 'classnames'

type TBodyProps<HTMLPropsT = HTMLTableSectionElement> = HTMLProps<HTMLPropsT> & {
  asElement?: ElementType
  children?: ReactNode
}

const TBody = forwardRef(function TBody<HTMLPropsT, HTMLElementT = HTMLTableSectionElement>(
  { children, className, asElement: Component = 'tbody', ...rest }: TBodyProps<HTMLPropsT>,
  ref: ForwardedRef<HTMLElementT>,
) {
  const tBodyClass = classNames(Component !== 'tbody' && 'tbody', className)
  return (
    <Component className={tBodyClass} {...rest} ref={ref}>
      {children}
    </Component>
  )
})

export default TBody
