import React from 'react'
import { useNavigate } from 'react-router-dom'
import { devRoutes, RouteConfig } from '../../config/routes'
import { Button, Icon } from '../../components/ui'

const Dev = () => {
  const navigate = useNavigate()

  const uiComponents = devRoutes.filter((route) => route.key.includes('ui'))
  const layoutComponents = devRoutes.filter((route) => route.key.includes('layout'))
  const themeComponents = devRoutes.filter((route) => route.key.includes('theme'))

  const renderRoute = (route: RouteConfig) => (
    <Button
      block
      key={route.key}
      onClick={() => navigate(route.path)}
      label={route.title}
      iconLocation={'right'}
      icon={<Icon name={'arrow-right'} />}
    />
  )

  return (
    <div className={'flex flex-col items-center pl-6 pr-6 pb-6'}>
      <h1>Component Showcase</h1>
      <div className={'flex flex-1 gap-10 flex-wrap'}>
        <div className={'flex flex-col gap-2 flex-1'}>
          <h2>UI</h2>
          {uiComponents.map(renderRoute)}
        </div>
        <div className={'flex flex-col gap-2 flex-1'}>
          <h2>Layout</h2>
          {layoutComponents.map(renderRoute)}
        </div>
        <div className={'flex flex-col gap-2 flex-1'}>
          <h2>Theme</h2>
          {themeComponents.map(renderRoute)}
        </div>
      </div>
    </div>
  )
}

export default Dev
