import React, { ReactNode, ReactElement } from 'react'

type Position = 'top-right' | 'top-left' | 'bottom-right' | 'bottom-left'

type BadgeProps = {
  position: Position
  children?: ReactNode
  content?: string | number
  maxCount?: number
  innerClass?: string
  render?: () => ReactElement
  hideEmptyDot?: boolean
}

const Badge: React.FC<BadgeProps> = ({ position, children, content, maxCount, render, hideEmptyDot = false }) => {
  const getBadgePosition = (pos: Position): string => {
    switch (pos) {
      case 'top-right':
        return 'top-0 right-0 -m-2'
      case 'top-left':
        return 'top-0 left-0 -m-2'
      case 'bottom-right':
        return 'bottom-0 right-0 -m-2'
      case 'bottom-left':
        return 'bottom-0 left-0 -m-2'
      default:
        return 'top-0 right-0 -m-2'
    }
  }

  const getDotPosition = (pos: Position): string => {
    switch (pos) {
      case 'top-right':
        return 'top-0 right-0 -m-[2px]'
      case 'top-left':
        return 'top-0 left-0 -m-[2px]'
      case 'bottom-right':
        return 'bottom-0 right-0 -m-[2px]'
      case 'bottom-left':
        return 'bottom-0 left-0 -m-[2px]'
      default:
        return 'top-0 right-0 -m-[2px]'
    }
  }

  let displayContent: string | number | undefined = content
  if (typeof content === 'number' && maxCount != null && content > maxCount) {
    displayContent = `${maxCount}+`
  }

  let badgeClass = 'h-[20px] min-w-[20px] px-[4px] py-[4px] text-xs'

  return (
    <div className="relative flex">
      {children}
      {render ? (
        <div className={`absolute ${getBadgePosition(position)}`}>{render()}</div>
      ) : content ? (
        <span
          className={`figtree absolute ${getBadgePosition(
            position,
          )} text-white rounded-full flex items-center justify-center bg-red ${badgeClass}`}>
          {displayContent}
        </span>
      ) : (
        !hideEmptyDot && (
          <span className={`figtree absolute ${getDotPosition(position)} rounded-full w-2 h-2 bg-red`}></span>
        )
      )}
    </div>
  )
}

export default Badge
