import { SlidesDocument, Slider } from '../../graphql'
import appConfig from '../../config/app'

const sideMocks = [
  {
    request: {
      query: SlidesDocument,
      variables: {
        data: {
          slider: Slider.Auth,
        },
      },
    },
    result: {
      data: {
        slides: [
          {
            title: 'John Doe',
            subtitle: 'founder, anychurch ministries',
            text: '"Sit amet mauris commodo quis imperdiet massa tincidunt nunc pulvinar sapien et ligula."',
            image: {
              src: `${appConfig.issuePath}/img/samples/Coffee_Shop_Stock.jpeg`,
            },
          },
          {
            title: 'Joe Doe',
            subtitle: 'founder, everychurch ministries',
            text: '"Amici, romani, cives, date aures vestras mihi sepultum neque laudatum venio."',
            image: {
              src: `${appConfig.issuePath}/img/samples/AdobeStock_240325420.jpeg`,
            },
          },
        ],
      },
    },
  },
]

export default sideMocks
