import React from 'react'
import classNames from 'classnames'

type DropdownGroupProps = {
  title?: string
  className?: string
}

const DropdownGroup = ({ title, className }: DropdownGroupProps) => {
  return <h6 className={classNames('px-[20px] mt-0 mb-[6px] text-gray-dark', ` ${className}`)}>{title}</h6>
}

export default DropdownGroup
