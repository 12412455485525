import React from 'react'
import { ApolloProvider, ApolloClient } from '@apollo/client'
import decorateClient from './ApolloService'
import MockedProvider from '../mock'
import useSignOut from '../hooks/useSignOut'

// Add additional decorative props as needed here
export type DecoratedClientProps = {
  auth: {
    signOut: () => void
  }
}

type DecoratedApolloProviderProps = Omit<DecoratedClientProps, 'auth'> & {
  children: React.ReactNode
  mock?: boolean
}

export let client: ApolloClient<object> | null = null

function DecoratedApolloProvider({ children, mock = false, ...rest }: DecoratedApolloProviderProps) {
  const signOut = useSignOut()

  const decoratedProps = {
    auth: {
      signOut,
    },
    ...rest,
  }

  client = decorateClient({
    auth: {
      signOut,
    },
    ...rest,
  })

  if (mock) {
    return <MockedProvider {...decoratedProps}>{children}</MockedProvider>
  }

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export async function handleErrors<T>(promise?: Promise<T>) {
  if (!promise) throw new Error('Client is not initialized')
  try {
    return await promise
  } catch (error) {
    if (error instanceof Array && error.length > 0) {
      const firstError = error[0]
      if (firstError.message) {
        throw new Error(firstError.message, {
          cause: error,
        })
      }
    }
    if (error instanceof Error) {
      if (error.message) {
        throw new Error(error.message, {
          cause: error,
        })
      }
    }
    throw new Error('Something went wrong', {
      cause: error,
    })
  }
}

export default DecoratedApolloProvider
