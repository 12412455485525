import React from 'react'
import TableFilters from './TableFilters'
import Button from '../../ui/Button'
import { Filters } from '../Filter'
import {
  clearFilters,
  TableRow,
  TableFilters as TFilters,
  selectTable,
  TableFilters as TableFiltersT,
  setPageIndex,
  setPageSize,
} from '../../../store/data/tableSlice'
import { useDispatch, useSelector } from 'react-redux'
import TableSearch from './TableSearch'
import Icon from '../Icon'

type TableToolsProps<F extends TableFiltersT, FilterAdditional> = {
  tableName: string
  filters?: Filters<F, FilterAdditional>
  search?: boolean
  onSearchInputChange?: (value: string) => void
  title?: string
}

function TableTools<Row extends TableRow, F extends TFilters, FilterAdditional>({
  tableName,
  filters,
  search = false,
  title = '',
  onSearchInputChange,
}: TableToolsProps<F, FilterAdditional>) {
  const dispatch = useDispatch()
  const { totalRecords } = useSelector(selectTable(tableName))

  let tableSearch: HTMLInputElement | null = null

  const handleClearAll = () => {
    if (tableSearch) {
      tableSearch.value = ''
    }
    dispatch(clearFilters(tableName))
  }

  const handleSearchInputChange = (value: string) => {
    onSearchInputChange?.(value)
  }

  const handleViewAll = () => {
    dispatch(setPageIndex({ index: 0, key: tableName }))
    dispatch(setPageSize({ size: Number(totalRecords), key: tableName }))
  }

  const handleAdd = () => {
    console.log('Add')
  }

  const { search: s, filters: f } = useSelector(selectTable<Row, F>(tableName))

  let showClear = false

  if (filters) {
    Object.keys(filters).forEach((key) => {
      const value = f[key]
      if (Array.isArray(value)) {
        if (value.length) {
          showClear = true
        }
      } else if (value && (value as unknown as string) !== '') {
        showClear = true
      }
    })
  }

  if (s && s !== '') {
    showClear = true
  }

  let clearAll = null

  if (showClear) {
    clearAll = (
      <Button size="xs" variant={'default'} icon={<Icon name={'x'} />} onClick={handleClearAll}>
        Clear Filters
      </Button>
    )
  }

  return (
    <div className={'flex w-full justify-between items-end mb-[15px]'}>
      <h2 className={'m-0'}>{title}</h2>
      <div className="md:flex flex-wrap items-center gap-x-4 justify-end">
        <div className="flex items-center gap-x-4">
          {search ? (
            <div className={'flex justify-end items-center'}>
              <TableSearch
                ref={(r) => (tableSearch = r)}
                tableName={tableName}
                onInputChange={handleSearchInputChange}
                value={s}
              />
            </div>
          ) : null}
          {filters ? (
            <div className={'min-w-[130px]'}>
              <TableFilters tableName={tableName} filters={filters} />
            </div>
          ) : null}
          {clearAll}
          <Button size="xs" variant={'default'} onClick={handleViewAll}>
            View All
          </Button>
          <Button size="xs" variant={'solid'} onClick={handleAdd} icon={<Icon name={'plus'} color={'white'} />}>
            Add
          </Button>
        </div>
      </div>
    </div>
  )
}

export default TableTools
