import React, { FC } from 'react'
import Table from '../../../components/ui/Table'
import TableTools from '../../../components/ui/Table/TableTools'
import DataTable, { DataTableColumn } from '../../../components/ui/Table/DataTable'

type Person = {
  firstName: string
  lastName: string
  age: number
  visits: number
  progress: number
  status: string
}

const columns: DataTableColumn<Person>[] = [
  {
    header: 'First Name',
    accessorKey: 'firstName',
    sortable: true,
  },
  {
    header: 'Last Name',
    accessorKey: 'lastName',
    sortable: true,
  },
  {
    header: 'Age',
    accessorKey: 'age',
    sortable: true,
  },
  {
    header: 'Visits',
    accessorKey: 'visits',
    sortable: true,
  },
  {
    header: 'Status',
    accessorKey: 'status',
    sortable: true,
  },
  {
    header: 'Profile Progress',
    accessorKey: 'progress',
    sortable: true,
  },
]

const data = [
  {
    id: 1,
    firstName: 'Maria',
    lastName: 'Anders',
    age: 24,
    visits: 28,
    progress: 56,
    status: 'complicated',
  },
  {
    id: 2,
    firstName: 'Francisco',
    lastName: 'Chang',
    age: 9,
    visits: 90,
    progress: 77,
    status: 'single',
  },
  {
    id: 3,
    firstName: 'Roland',
    lastName: 'Mendel',
    age: 1,
    visits: 16,
    progress: 56,
    status: 'single',
  },
  {
    id: 4,
    firstName: 'Helen',
    lastName: 'Bennett',
    age: 43,
    visits: 94,
    progress: 53,
    status: 'single',
  },
  {
    id: 5,
    firstName: 'Yoshi ',
    lastName: 'Tannamuri',
    age: 37,
    visits: 85,
    progress: 28,
    status: 'single',
  },
  {
    id: 6,
    firstName: 'Maria',
    lastName: 'Anders',
    age: 24,
    visits: 28,
    progress: 56,
    status: 'complicated',
  },
  {
    id: 7,
    firstName: 'Francisco',
    lastName: 'Chang',
    age: 9,
    visits: 90,
    progress: 77,
    status: 'single',
  },
  {
    id: 8,
    firstName: 'Roland',
    lastName: 'Mendel',
    age: 1,
    visits: 16,
    progress: 56,
    status: 'single',
  },
  {
    id: 9,
    firstName: 'Helen',
    lastName: 'Bennett',
    age: 43,
    visits: 94,
    progress: 53,
    status: 'single',
  },
  {
    id: 10,
    firstName: 'Yoshi ',
    lastName: 'Tannamuri',
    age: 37,
    visits: 85,
    progress: 28,
    status: 'single',
  },
  {
    id: 11,
    firstName: 'Maria',
    lastName: 'Anders',
    age: 24,
    visits: 28,
    progress: 56,
    status: 'complicated',
  },
  {
    id: 12,
    firstName: 'Francisco',
    lastName: 'Chang',
    age: 9,
    visits: 90,
    progress: 77,
    status: 'single',
  },
  {
    id: 13,
    firstName: 'Roland',
    lastName: 'Mendel',
    age: 1,
    visits: 16,
    progress: 56,
    status: 'single',
  },
  {
    id: 14,
    firstName: 'Helen',
    lastName: 'Bennett',
    age: 43,
    visits: 94,
    progress: 53,
    status: 'single',
  },
  {
    id: 15,
    firstName: 'Yoshi ',
    lastName: 'Tannamuri',
    age: 37,
    visits: 85,
    progress: 28,
    status: 'single',
  },
]

const THead = (
  <Table.THead>
    <Table.Tr key={'header-row'}>
      <Table.Th key={'header-1'} colSpan={1}>
        <div>
          <h6 className={'m-0'}>Header One</h6>
        </div>
      </Table.Th>
      <Table.Th key={'header-2'} colSpan={1}>
        <div>
          <h6 className={'m-0'}>Header Two</h6>
        </div>
      </Table.Th>
      <Table.Th key={'header-3'} colSpan={1}>
        <div>
          <h6 className={'m-0'}>Header Three</h6>
        </div>
      </Table.Th>
      <Table.Th key={'header-4'} colSpan={1}>
        <div>
          <h6 className={'m-0'}>Header Four</h6>
        </div>
      </Table.Th>
      <Table.Th key={'header-5'} colSpan={1}>
        <div>
          <h6 className={'m-0'}>Header Five</h6>
        </div>
      </Table.Th>
      <Table.Th key={'header-6'} colSpan={1}>
        <div>
          <h6 className={'m-0'}>Header Six</h6>
        </div>
      </Table.Th>
    </Table.Tr>
  </Table.THead>
)

const TBody = (
  <Table.TBody>
    <Table.Tr key={'body-row-1'}>
      <Table.Td key={'data-1'}>Data One</Table.Td>
      <Table.Td key={'data-2'}>Data Two</Table.Td>
      <Table.Td key={'data-3'}>Data Three</Table.Td>
      <Table.Td key={'data-4'}>Data Four</Table.Td>
      <Table.Td key={'data-5'}>Data Five</Table.Td>
      <Table.Td key={'data-6'}>The quick brown fox jumped over the lazy dog</Table.Td>
    </Table.Tr>
    <Table.Tr key={'body-row-2'}>
      <Table.Td key={'data-7'}>Data One</Table.Td>
      <Table.Td key={'data-8'}>Data Two</Table.Td>
      <Table.Td key={'data-9'}>Data Three</Table.Td>
      <Table.Td key={'data-10'}>Data Four</Table.Td>
      <Table.Td key={'data-11'}>Data Five</Table.Td>
      <Table.Td key={'data-12'}>The quick brown fox jumped over the lazy dog</Table.Td>
    </Table.Tr>
    <Table.Tr key={'body-row-3'}>
      <Table.Td key={'data-13'}>Data One</Table.Td>
      <Table.Td key={'data-14'}>Data Two</Table.Td>
      <Table.Td key={'data-15'}>Data Three</Table.Td>
      <Table.Td key={'data-16'}>Data Four</Table.Td>
      <Table.Td key={'data-17'}>Data Five</Table.Td>
      <Table.Td key={'data-18'}>The quick brown fox jumped over the lazy dog</Table.Td>
    </Table.Tr>
    <Table.Tr key={'body-row-4'}>
      <Table.Td key={'data-19'}>Data One</Table.Td>
      <Table.Td key={'data-20'}>Data Two</Table.Td>
      <Table.Td key={'data-21'}>Data Three</Table.Td>
      <Table.Td key={'data-22'}>Data Four</Table.Td>
      <Table.Td key={'data-23'}>Data Five</Table.Td>
      <Table.Td key={'data-24'}>The quick brown fox jumped over the lazy dog</Table.Td>
    </Table.Tr>
  </Table.TBody>
)

const TableExample: FC = () => {
  return (
    <div style={styles.container}>
      <h2 className={'m-0'}>Plain Table</h2>
      <Table className={'w-full'} compact={false} overflow={false}>
        {THead}
        {TBody}
      </Table>
      <h2 className={'m-0'}>Compact Table</h2>
      <Table className={'w-full'} compact={true} overflow={false}>
        {THead}
        {TBody}
      </Table>
      <h2 className={'m-0'}>Borderless Table (No Hover)</h2>
      <Table className={'w-full'} borderlessRow={true} overflow={false} hoverable={false}>
        {THead}
        {TBody}
      </Table>
      <h2 className={'m-0'}>Data Table (No Sort/Tools/Pagination)</h2>
      <div className={'w-full'}>
        <DataTable
          sortable={false}
          pagination={false}
          name={'test-one'}
          columns={columns as DataTableColumn<any>[]}
          data={data}
          total={data?.length}
        />
      </div>
      <div className={'w-full'}>
        <TableTools
          title={'Data Table'}
          tableName={'test'}
          onSearchInputChange={(val: string) => val}
          filters={{
            status: {
              placeholder: 'Filter Status',
              options: [
                { label: 'Single', value: 'single' },
                { label: 'Complicated', value: 'complicated' },
              ],
            },
          }}
          search
        />
        <DataTable
          name={'test'}
          columns={columns as DataTableColumn<any>[]}
          data={data}
          total={data?.length}
          onRowClick={(row) => console.log(row)}
        />
      </div>
    </div>
  )
}

const styles: CSSObject = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10%',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 40,
    height: '100%',
    width: '100%',
    background: '#eee',
  },
}
export default TableExample
