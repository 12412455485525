import React, { FC, ReactNode } from 'react'
import { Form } from 'formik'

type FormContainerProps = {
  children: ReactNode
}

const FormContainer: FC<FormContainerProps> = ({ children }) => {
  return (
    <Form>
      <div className="w-full flex flex-col gap-5 p-[20px] bg-white">{children}</div>
    </Form>
  )
}

export default FormContainer
