import React, { ElementType, HTMLProps, ReactNode, forwardRef } from 'react'
import classNames from 'classnames'

type TdProps<HTMLPropsT = HTMLTableDataCellElement> = HTMLProps<HTMLPropsT> & {
  asElement?: ElementType
  children?: ReactNode
}

const Td = forwardRef(({ children, className, asElement: Component = 'td', ...rest }: TdProps, ref) => {
  const tdClass = classNames(Component !== 'td' && 'td', className)
  return (
    <Component className={tdClass} ref={ref} {...rest}>
      {children}
    </Component>
  )
})

export default Td
