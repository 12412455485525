import React, { ElementType, forwardRef, HTMLProps, ReactNode } from 'react'
import classNames from 'classnames'

type TFootProps<HTMLPropsT = HTMLTableSectionElement> = HTMLProps<HTMLPropsT> & {
  asElement?: ElementType
  children?: ReactNode
}

const TFoot = forwardRef(({ children, className, asElement: Component = 'tfoot', ...rest }: TFootProps, ref) => {
  const tBodyClass = classNames(Component !== 'tfoot' && 'tfoot', className)
  return (
    <Component className={tBodyClass} {...rest} ref={ref}>
      {children}
    </Component>
  )
})

export default TFoot
