import React, { FC } from 'react'

type DialogueFooterProps = {
  children: React.ReactNode
  className?: string
  style?: React.CSSProperties
}

const DialogueFooter: FC<DialogueFooterProps> = ({ children, className = '', style }) => (
  <div className={`flex justify-end items-center space-x-[10px] mt-5 ${className}`} style={style}>
    {children}
  </div>
)

export default DialogueFooter
