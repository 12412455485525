import React, { MouseEvent } from 'react'
import Icon from '../Icon'

type PrevProps = {
  currentPage?: number
  onPrev?: (e: MouseEvent<HTMLSpanElement>) => void
  darkHover?: boolean
}

const Prev = ({ currentPage = 1, onPrev, darkHover = false }: PrevProps) => {
  const disabled = currentPage <= 1

  const onPrevClick = (e: MouseEvent<HTMLSpanElement>) => {
    e.preventDefault()
    if (disabled) {
      return
    }
    onPrev?.(e)
  }

  const pagerPrevClass = `relative m-0 p-0 mr-[10px] inline-flex items-center h-[30px] w-[30px] justify-center select-none rounded-[10px] ${
    disabled ? 'bg-white cursor-not-allowed' : `cursor-pointer bg-white hover:bg-${darkHover ? 'gray-100' : 'gray-50'}`
  }`

  return (
    <span className={pagerPrevClass} onClick={onPrevClick}>
      <Icon name={'chevron-left'} color={disabled ? 'gray-light' : 'gray-900'} />
    </span>
  )
}

export default Prev
