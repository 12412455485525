import { ApolloLink, from } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { DecoratedClientProps } from './index'

export const decorateErrorLink: (props: DecoratedClientProps) => ApolloLink = ({ auth }) =>
  onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.map(async ({ message, locations, path, extensions }) => {
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
        if (extensions.code === 'UNAUTHENTICATED') {
          await auth.signOut()
        }
      })
    if (networkError) console.log(`[Network error]: ${networkError}`)
  })

const decorateLink = (props: DecoratedClientProps) => {
  const errorLink = decorateErrorLink(props)
  return from([errorLink])
}

export default decorateLink
