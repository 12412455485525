import React, { ReactNode, MouseEvent } from 'react'

type HeaderItemProps = {
  selected?: boolean
  children?: ReactNode
  onClick?: (event: MouseEvent<HTMLDivElement>, value: boolean) => void
}

const Index = ({ children, onClick, selected = false }: HeaderItemProps) => {
  const handleOnClick = (e: MouseEvent<HTMLDivElement>) => {
    onClick?.(e, !selected)
  }
  return (
    <div
      onClick={handleOnClick}
      className={`${
        selected ? 'bg-gray-light' : 'bg-white hover:bg-gray-100'
      } rounded-[5px] p-[4px] cursor-pointer transition duration-150`}>
      {children}
    </div>
  )
}

export default Index
