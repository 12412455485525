import React, { FC } from 'react'
const TypographyExample: FC = () => {
  return (
    <div style={styles.container}>
      <h1>Heading 1</h1>
      <p className={'body-xl'} style={{ marginBottom: 20 }}>
        Felis eget velit aliquet sagittis id consectetur purus ut faucibus pulvinar elementum integer enim Heque
        volutpat ac tincidunt vitae semper quis lectus nulla Volutpat diam ut venenatis tellus in metus vulputate eu
        scelerisque felis imperdiet proin fermentum vera et.
      </p>
      <p className={'body-bold-xl'}>
        Felis eget velit aliquet sagittis id consectetur purus ut faucibus pulvinar elementum integer enim Heque
        volutpat ac tincidunt vitae semper quis lectus nulla Volutpat diam ut venenatis tellus in metus vulputate eu
        scelerisque felis imperdiet proin fermentum vera et.
      </p>
      <h2>Heading 2</h2>
      <p className={'body-lg'} style={{ marginBottom: 20 }}>
        Felis eget velit aliquet sagittis id consectetur purus ut faucibus pulvinar elementum integer enim Heque
        volutpat ac tincidunt vitae semper quis lectus nulla Volutpat diam ut venenatis tellus in metus vulputate eu
        scelerisque felis imperdiet proin fermentum vera et.
      </p>
      <p className={'body-bold-lg'}>
        Felis eget velit aliquet sagittis id consectetur purus ut faucibus pulvinar elementum integer enim Heque
        volutpat ac tincidunt vitae semper quis lectus nulla Volutpat diam ut venenatis tellus in metus vulputate eu
        scelerisque felis imperdiet proin fermentum vera et.
      </p>
      <h3>Heading 3</h3>
      <p className={'body-md'} style={{ marginBottom: 20 }}>
        Felis eget velit aliquet sagittis id consectetur purus ut faucibus pulvinar elementum integer enim Heque
        volutpat ac tincidunt vitae semper quis lectus nulla Volutpat diam ut venenatis tellus in metus vulputate eu
        scelerisque felis imperdiet proin fermentum vera et.
      </p>
      <p className={'body-bold-md'}>
        Felis eget velit aliquet sagittis id consectetur purus ut faucibus pulvinar elementum integer enim Heque
        volutpat ac tincidunt vitae semper quis lectus nulla Volutpat diam ut venenatis tellus in metus vulputate eu
        scelerisque felis imperdiet proin fermentum vera et.
      </p>
      <h4>Heading 4</h4>
      <p className={'body-sm'} style={{ marginBottom: 20 }}>
        Felis eget velit aliquet sagittis id consectetur purus ut faucibus pulvinar elementum integer enim Heque
        volutpat ac tincidunt vitae semper quis lectus nulla Volutpat diam ut venenatis tellus in metus vulputate eu
        scelerisque felis imperdiet proin fermentum vera et.
      </p>
      <p className={'body-bold-sm'}>
        Felis eget velit aliquet sagittis id consectetur purus ut faucibus pulvinar elementum integer enim Heque
        volutpat ac tincidunt vitae semper quis lectus nulla Volutpat diam ut venenatis tellus in metus vulputate eu
        scelerisque felis imperdiet proin fermentum vera et.
      </p>
      <h5>Heading 5</h5>
      <p className={'body-xs'} style={{ marginBottom: 20 }}>
        Felis eget velit aliquet sagittis id consectetur purus ut faucibus pulvinar elementum integer enim Heque
        volutpat ac tincidunt vitae semper quis lectus nulla Volutpat diam ut venenatis tellus in metus vulputate eu
        scelerisque felis imperdiet proin fermentum vera et.
      </p>
      <p className={'body-bold-xs'}>
        Felis eget velit aliquet sagittis id consectetur purus ut faucibus pulvinar elementum integer enim Heque
        volutpat ac tincidunt vitae semper quis lectus nulla Volutpat diam ut venenatis tellus in metus vulputate eu
        scelerisque felis imperdiet proin fermentum vera et.
      </p>
      <h6>Heading 6</h6>
      <p className={'body-xs'} style={{ marginBottom: 20 }}>
        Felis eget velit aliquet sagittis id consectetur purus ut faucibus pulvinar elementum integer enim Heque
        volutpat ac tincidunt vitae semper quis lectus nulla Volutpat diam ut venenatis tellus in metus vulputate eu
        scelerisque felis imperdiet proin fermentum vera et.
      </p>
      <p className={'body-bold-xs'}>
        Felis eget velit aliquet sagittis id consectetur purus ut faucibus pulvinar elementum integer enim Heque
        volutpat ac tincidunt vitae semper quis lectus nulla Volutpat diam ut venenatis tellus in metus vulputate eu
        scelerisque felis imperdiet proin fermentum vera et.
      </p>
      <div style={{ marginTop: 40, display: 'flex', flexDirection: 'column', gap: 40 }}>
        <a href="/" className={'link-xl'}>
          Body Extra Large
        </a>
        <a href="/" className={'link-lg'}>
          Body Large
        </a>
        <a href="/" className={'link-md'}>
          Body Medium
        </a>
        <a href="/" className={'link-sm'}>
          Body Small
        </a>
        <a href="/" className={'link-xs'}>
          Body Extra Small
        </a>
      </div>
      <ul style={{ marginTop: 40, marginBottom: 40 }}>
        <li>Now this is a story all about how, my life got flipped turned upside-down.</li>
        <li>And I like to take a minute and sit right here.</li>
        <li>I’ll tell you how I became the prince of a town called Bel-Air.</li>
      </ul>
      <ol style={{ marginBottom: 40 }}>
        <li>Now this is a story all about how, my life got flipped turned upside-down.</li>
        <li>And I like to take a minute and sit right here.</li>
        <li>I’ll tell you how I became the prince of a town called Bel-Air.</li>
      </ol>
    </div>
  )
}
const styles: CSSObject = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '15%',
  },
}
export default TypographyExample
