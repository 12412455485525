import React, { FC, ReactNode, forwardRef } from 'react'
import Line from './Line'
import Circle from './Circle'

type ProgressLoaderProps = {
  color?: string
  customInfo?: string | ReactNode
  gapDegree?: number
  gapPosition?: 'top' | 'right' | 'bottom' | 'left'
  percent?: number
  showInfo?: boolean
  size?: 'sm' | 'lg'
  strokeLinecap?: 'round' | 'square'
  strokeWidth?: number
  variant?: 'line' | 'circle'
  width?: number | string
  className?: string
  infoPosition?: 'center' | 'right'
}

const ProgressLoader: FC<ProgressLoaderProps> = forwardRef<HTMLDivElement, ProgressLoaderProps>((props, ref) => {
  const {
    variant = 'line',
    color,
    percent = 0,
    showInfo = true,
    customInfo,
    className,
    width,
    strokeLinecap = 'round',
    strokeWidth = 6,
    gapDegree = 0,
    gapPosition = 'top',
    size = 'lg',
    infoPosition = 'right',
  } = props

  const renderProcessInfo = () => {
    if (!showInfo) return null
    return (
      <span className={`inline-block ${variant === 'line' ? (document.dir === 'rtl' ? 'mr-2' : 'ml-2') : ''}`}>
        {customInfo || `${percent}%`}
      </span>
    )
  }

  const renderProgress = () => {
    const progressInfo = renderProcessInfo()
    if (variant === 'line') {
      return (
        <Line
          strokeColor={color}
          percent={percent}
          size={size}
          strokeLinecap={strokeLinecap}
          customInfo={progressInfo}
        />
      )
    }
    if (variant === 'circle') {
      return (
        <Circle
          percent={percent}
          size={size}
          customInfo={progressInfo}
          strokeLinecap={strokeLinecap}
          strokeWidth={strokeWidth}
          gapDegree={gapDegree}
          gapPosition={gapPosition}
          width={width}
          infoPosition={infoPosition}
          strokeColor={color}
        />
      )
    }
  }

  return (
    <div ref={ref} className={className}>
      {renderProgress()}
    </div>
  )
})

export default ProgressLoader
