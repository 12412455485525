import * as config from '../constants'

type ThemeConfig = {
  primaryColor: string
  color: string
  colorLevel: number | string
  locale: string
}

function useTheme(): ThemeConfig {
  const color = config.THEME_COLOR
  const colorLevel = config.THEME_COLOR_LEVEL
  const primaryColor = `${color}-${colorLevel}`
  const locale = config.LOCALE

  return {
    primaryColor,
    color,
    colorLevel,
    locale,
  }
}

export default useTheme
