import Dropdown, { DropdownProps } from './Dropdown'
import DropdownItem from './DropdownItem'
import DropdownMenu from './DropdownMenu'
import DropdownGroup from './DropdownGroup'
import DropdownHeader from './DropdownHeader'

Dropdown.Item = DropdownItem
Dropdown.Menu = DropdownMenu
Dropdown.Group = DropdownGroup
Dropdown.Header = DropdownHeader

export { type DropdownProps }

export default Dropdown as typeof Dropdown & {
  Item: typeof DropdownItem
  Menu: typeof DropdownMenu
  Group: typeof DropdownGroup
  Header: typeof DropdownHeader
}
