import React from 'react'
import classNames from 'classnames'
import Icon from '../Icon'

const IconClose = <Icon name={'x'} />

export type CloseButtonProps = {
  absolute?: boolean
  className?: string
  onClick?: (e: React.MouseEvent<HTMLSpanElement>) => void
  hoverBackground?: boolean
}

const CloseButton = React.forwardRef((props: CloseButtonProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
  const { absolute = false, className, hoverBackground = false, ...rest } = props
  const closeButtonAbsoluteClass = 'absolute z-10'

  const closeButtonClass = classNames(
    'outline-none cursor-pointer transition duration-200 text-xl rounded-[5px] p-[3px] hover:text-gray-600',
    absolute && closeButtonAbsoluteClass,
    !hoverBackground && 'hover:opacity-70',
    hoverBackground && 'hover:bg-gray-100',
    className,
  )

  return (
    <span className={closeButtonClass} role="button" {...rest} ref={ref}>
      {IconClose}
    </span>
  )
})

export default CloseButton
