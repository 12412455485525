import React from 'react'
import Icon from '../Icon'

const Sorter = ({ sort }: { sort: boolean | string }) => {
  const renderSort = () => {
    if (typeof sort === 'boolean' && !sort) {
      return <Icon name={'sort'} size={14} color={'gray-900'} />
    }
    if (typeof sort === 'string' && sort === 'asc') {
      return <Icon name={'sort-down'} size={14} color={'gray-900'} />
    }
    if (typeof sort === 'string' && sort === 'desc') {
      return <Icon name={'sort-up'} size={14} color={'gray-900'} />
    }
  }
  return <div className="inline-flex mr-1">{renderSort()}</div>
}

export default Sorter
