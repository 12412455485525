import React from 'react'
import DefaultFilter from './components/DefaultFilter'
import { SelectProps } from '../Select'
import { TableFilters as TableFiltersT, FilterOption } from '../../../store/data/tableSlice'

export type Filters<F extends TableFiltersT, Additional> = {
  [P in keyof F]?: FilterProps<FilterOption<F[P]>, Additional>
}

export type FilterProps<Option, Additional> = SelectProps<Option, Additional> & {
  type?: 'default'
}

const Filter: <Option, Additional>(props: FilterProps<Option, Additional>) => JSX.Element = ({
  type = 'default',
  ...props
}) => {
  return <DefaultFilter {...props} />
}

export default Filter
