import React, { useEffect, useState, MouseEventHandler, FC } from 'react'
import { ReactSVG, WrapperType } from 'react-svg'

type IconProps = {
  name: IconName
  size?: number | string
  color?: string
  className?: string
  onClick?: MouseEventHandler<WrapperType> & MouseEventHandler<SVGSVGElement>
}

const Icon: FC<IconProps> = ({ name, size = 20, color = 'gray-900', className = '', onClick }) => {
  const [icon, setIcon] = useState('')

  useEffect(() => {
    import(`../../../assets/icons/icon-${name}.svg`).then((res) => {
      setIcon(res.default)
    })
  }, [name])

  return (
    <ReactSVG
      beforeInjection={(svg) => {
        if (color.startsWith('#')) {
          // Convert NodeListOf<Element> to an array and then iterate
          Array.from(svg.querySelectorAll('path')).forEach((path) => {
            path.setAttribute('fill', color)
          })
        } else {
          svg.classList.add(`text-${color}`) // use tailwind class
        }
        if (className) {
          svg.classList.add(...className.split(' '))
        }
        svg.setAttribute('height', `${size}`)
        svg.setAttribute('width', `${size}`)
      }}
      src={icon}
      onClick={onClick}
    />
  )
}

export default Icon
