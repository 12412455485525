import React, { useState, useCallback, useRef, ReactNode } from 'react'
import { Popper, Reference, Manager } from 'react-popper'
import { motion, AnimatePresence } from 'framer-motion'
import Arrow from './Arrow'

type TooltipProps = {
  title: string | ReactNode
  placement?:
    | 'top'
    | 'top-start'
    | 'top-end'
    | 'bottom'
    | 'bottom-start'
    | 'bottom-end'
    | 'right'
    | 'right-start'
    | 'right-end'
    | 'left'
    | 'left-start'
    | 'left-end'
  wrapperClass?: string
  isOpen?: boolean
  children?: any
}

type PopperProps = {
  title: string | ReactNode
}

const PopperElement = ({ title }: PopperProps) => {
  return <span className={'font-figtree text-[14px] leading-[14px] text-white font-normal'}>{title}</span>
}

const Tooltip = ({ children, title, placement = 'top', wrapperClass = '', isOpen = false }: TooltipProps) => {
  const [tooltipOpen, setTooltipOpen] = useState(isOpen)
  const tooltipNode = useRef<any>()
  const toggleTooltip = useCallback(
    (bool: boolean) => {
      if (!isOpen) {
        setTooltipOpen(bool)
      }
    },
    [isOpen],
  )

  let offset = placement?.includes('top') ? 12 : 12

  return (
    <Manager>
      <Reference>
        {({ ref }: { ref: any }) => (
          <span
            className={`${wrapperClass}`}
            ref={ref}
            onMouseEnter={() => toggleTooltip(true)}
            onMouseLeave={() => toggleTooltip(false)}>
            {children}
          </span>
        )}
      </Reference>
      {tooltipOpen && (
        <Popper
          placement={placement}
          innerRef={(node: any) => (tooltipNode.current = node)}
          modifiers={[
            { name: 'offset', options: { offset: [0, offset] } },
            { name: 'flip', options: { fallbackPlacements: [] } },
          ]}
          strategy={'fixed'}>
          {({ ref, style, ...popperProps }: any) => (
            <AnimatePresence>
              <motion.div
                className={'flex justify-center items-center bg-gray-900 p-[12px] rounded-[5px]'}
                ref={ref}
                style={style}
                initial={{ opacity: 0, visibility: 'hidden' }}
                animate={tooltipOpen ? { opacity: 1, visibility: 'visible' } : { opacity: 0, visibility: 'hidden' }}
                transition={{ duration: 0.15, type: 'tween' }}>
                <PopperElement open={tooltipOpen} title={title} {...popperProps} />
                <Arrow placement={placement} />
              </motion.div>
            </AnimatePresence>
          )}
        </Popper>
      )}
    </Manager>
  )
}

export default Tooltip
