import React, { useState, useEffect, CSSProperties, ReactNode, FC } from 'react'
import { SegmentContextProvider } from './context'
import SegmentItem from './SegmentItem'

interface SegmentProps {
  defaultValue?: string[]
  onChange?: (segmentValue: string[]) => void
  selectionType?: 'single' | 'multiple'
  size?: 'lg' | 'md' | 'sm' | 'xs'
  value?: string[]
  style?: CSSProperties
  children?: ReactNode
}

type SegmentType = FC<SegmentProps> & {
  Item: typeof SegmentItem
}

const Segment: SegmentType = ({
  defaultValue = [],
  onChange,
  selectionType = 'single',
  size = 'md',
  value,
  style,
  children,
}) => {
  const [internalValues, setInternalValues] = useState<string[]>(defaultValue)

  useEffect(() => {
    if (value) {
      setInternalValues(value)
    }
  }, [value])

  const handleSegmentItemClick = (segmentValue: string) => {
    let updatedValues = [...internalValues]

    if (selectionType === 'multiple') {
      if (updatedValues.includes(segmentValue)) {
        updatedValues = updatedValues.filter((v) => v !== segmentValue)
      } else {
        updatedValues.push(segmentValue)
      }
    } else {
      // single selection
      if (updatedValues.includes(segmentValue)) {
        updatedValues = []
      } else {
        updatedValues = [segmentValue]
      }
    }

    setInternalValues(updatedValues)
    onChange?.(updatedValues)
  }

  return (
    <SegmentContextProvider
      value={{
        activeValues: internalValues,
        onSegmentItemClick: handleSegmentItemClick,
        size,
      }}>
      <div style={style}>{children}</div>
    </SegmentContextProvider>
  )
}

Segment.Item = SegmentItem

export default Segment
