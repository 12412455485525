import React, { MouseEvent } from 'react'
import { Button } from '../../../../../ui'
import Icon from '../../../../../ui/Icon'

export type NotificationDropdownFooterProps = {
  onClickViewAll: (e: MouseEvent<HTMLButtonElement>) => void
}

const RightChevronIcon = <Icon name={'chevron-right'} />

const NotificationDropdownFooter = ({ onClickViewAll }: NotificationDropdownFooterProps) => {
  return (
    <div className={'pt-[10px] pb-[5px] px-[20px] flex justify-center items-center'}>
      <Button
        className={'pr-[8px]'}
        size={'sm'}
        onClick={onClickViewAll}
        variant={'plain'}
        icon={RightChevronIcon}
        iconLocation={'right'}>
        View all
      </Button>
    </div>
  )
}

export default NotificationDropdownFooter
