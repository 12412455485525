import React from 'react'

const useMergedRef =
  (...refs: React.ForwardedRef<unknown>[]) =>
  (element: unknown) =>
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(element)
      } else if (ref && typeof ref === 'object') {
        ref.current = element
      }
    })

export default useMergedRef
