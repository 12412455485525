import React from 'react'
import { Icon } from '../../../../ui'
import { ProgramFieldsFragment } from '../../../../../graphql'

export type ProgramDropdownButtonProps = {
  selectedProgram: ProgramFieldsFragment
  collapsed: boolean
  selected?: boolean
}

const ProgramDropdownButton = ({ selectedProgram, collapsed, selected = false }: ProgramDropdownButtonProps) => {
  const { icon, name } = selectedProgram

  const duration = 'duration-[150ms]'
  const containerWidth = collapsed ? 'w-[64px]' : 'w-full'
  const subContainerWidth = collapsed ? 'w-[0px]' : 'w-full'
  const opacity = collapsed ? 'opacity-0' : 'opacity-1'

  const chevronRotation = selected ? 'rotate-0' : 'rotate-180'

  return (
    <div
      className={`transition-all ${duration} ${containerWidth} overflow-hidden cursor-pointer flex items-center p-[17px] hover:bg-gray-800/[0.25] ${
        selected && 'bg-gray-800/[0.25]'
      }`}>
      <img className={`w-[30px] h-[30px] mr-[9px] rounded-[7px]`} src={icon?.src} alt={`${name}-icon`} />
      <div className={`transition-all ${duration} whitespace-nowrap flex ${subContainerWidth} items-center`}>
        <span
          className={`flex-1 transition-all ${duration} ${opacity} body-sm text-white font-semibold leading-[18.2px] mr-[9px]`}>
          {name}
        </span>
        <div className={`${chevronRotation} transition-all duration-[250ms]`}>
          <Icon name={'chevron-up'} color={'white'} />
        </div>
      </div>
    </div>
  )
}

export default ProgramDropdownButton
