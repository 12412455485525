import React, { MouseEvent, useState } from 'react'
import { Avatar, Icon, Badge } from '../../../components/ui'
import Index from '../../../components/layout/components/Header/HeaderItem'
import Header from '../../../components/layout/components/Header'

const HeaderExample: React.FC = () => {
  const [selected, setSelected] = useState(false)
  const handleOnClick = (e: MouseEvent<HTMLDivElement>, val: boolean) => {
    console.log(e, val)
    setSelected(val)
  }
  const start = [<Icon key={1} name={'menu'} size={24} />]
  const end = [
    <Badge key={2} position="top-right" content="6">
      <Icon name={'bell'} size={24} />
    </Badge>,
    <Index key={3} selected={selected} onClick={handleOnClick}>
      <Icon name={'check-square'} size={24} />
    </Index>,
    <Avatar key={4} shape="circle" size={'sm'} src={'https://randomuser.me/api/portraits/men/1.jpg'} />,
  ]
  return <Header start={start} end={end} />
}

export default HeaderExample
