import React from 'react'
import NotificationDropdown from '../../../components/layout/components/Header/HeaderItem/NotificationDropdown'

const NotificationDropdownExample = () => {
  return (
    <div className={'flex justify-center items-center h-screen'}>
      <div className={'w-[530px] bg-gray-100 p-[10px]'}>
        <NotificationDropdown />
      </div>
    </div>
  )
}

export default NotificationDropdownExample
