import React from 'react'
import { Icon } from '../../../components/ui'

const ExampleIcon: React.FC = () => {
  const handleClick = () => {
    console.log('click!')
  }

  return (
    <div>
      <Icon
        name={'x-small'}
        size={25}
        color={'black'}
        className="cursor-pointer transition duration-200 hover:opacity-70"
        onClick={handleClick}
      />
    </div>
  )
}

export default ExampleIcon
