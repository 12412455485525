import React, { ElementType, useEffect, useRef } from 'react'
import Indicator from '../Indicator'
import { ReactComponent as InfoIcon } from '../../../assets/icons/icon-info.svg'
import { ReactComponent as WarningIcon } from '../../../assets/icons/icon-alert-triangle.svg'
import { ReactComponent as SuccessIcon } from '../../../assets/icons/icon-check-circle.svg'
import { ReactComponent as DangerIcon } from '../../../assets/icons/icon-x-circle.svg'
import { ReactComponent as PlaceholderIcon } from '../../../assets/icons/icon-placeholder.svg'
import { ReactComponent as CloseIcon } from '../../../assets/icons/icon-x-small.svg'

type AlertProps = {
  showIcon?: boolean
  customIcon?: ElementType | undefined
  type?: 'info' | 'warning' | 'success' | 'danger'
  closeable?: boolean
  customClose?: ElementType | undefined
  rounded?: boolean
  onClose?: (e: MouseEvent) => void
  onRemoved?: (e: MouseEvent) => void
  size?: 'sm' | 'lg'
  label?: string | null
  show?: boolean
}

const Alert = ({
  showIcon = true,
  customIcon,
  type = 'warning',
  closeable = true,
  customClose,
  rounded = true,
  onClose,
  size = 'lg',
  label = '',
  onRemoved,
  show = true,
}: AlertProps) => {
  let backgroundColor = ''
  let borderColor = ''
  let textColor = ''
  let icon = PlaceholderIcon
  let Close = customClose ? customClose : CloseIcon
  const ref = useRef<any>()

  if (type) {
    switch (type) {
      case 'info':
        backgroundColor = 'bg-blue-50'
        textColor = 'text-blue'
        borderColor = 'border-blue-200'
        icon = InfoIcon
        break
      case 'warning':
        backgroundColor = 'bg-yellow-50'
        textColor = 'text-yellow'
        borderColor = 'border-yellow-200'
        icon = WarningIcon
        break
      case 'success':
        backgroundColor = 'bg-green-50'
        textColor = 'text-green'
        borderColor = 'border-green-200'
        icon = SuccessIcon
        break
      case 'danger':
        backgroundColor = 'bg-red-50'
        textColor = 'text-red'
        borderColor = 'border-red-200'
        icon = DangerIcon
        break
      default:
        backgroundColor = 'bg-yellow-50'
        textColor = 'text-yellow'
        borderColor = 'border-yellow-200'
        icon = WarningIcon
        break
    }
  }

  useEffect(() => {
    if (show) {
      ref?.current?.classList?.remove('hidden')
      ref?.current?.classList?.remove('opacity-0')
    } else {
      ref?.current?.classList?.add('opacity-0')
      setTimeout(() => {
        ref?.current?.classList?.add('hidden')
      }, 350)
    }
  }, [show])

  const handleOnClick = (e: MouseEvent) => {
    onClose?.(e)
    ref?.current?.classList?.add('opacity-0')
    setTimeout(() => {
      ref?.current?.classList?.add('hidden')
    }, 350)
  }

  return (
    <div
      ref={ref}
      className={`hidden ${backgroundColor} ${textColor} ${borderColor} ${
        size === 'lg' ? 'body-bold-md' : 'body-bold-sm'
      } flex justify-between items-center font-semibold p-[15px] border-[1px] transition-all duration-300 ${
        rounded ? 'rounded-[10px]' : ''
      }`}>
      <div className={'flex items-center gap-2.5'}>
        {showIcon ? <Indicator indicator={customIcon ? customIcon : icon} size={24} color={textColor} /> : null}
        {label}
      </div>
      <div>
        {closeable ? <Close className={`${textColor} cursor-pointer ml-[10px]`} onClick={handleOnClick} /> : null}
      </div>
    </div>
  )
}

export default Alert
