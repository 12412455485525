import { combineReducers } from 'redux'
import base from './base'
import data from './data'
import layout from './layout'

const rootReducer = () => (state: any, action: any) => {
  const combinedReducer = combineReducers({
    base,
    data,
    layout,
  })
  return combinedReducer(state, action)
}

export default rootReducer
