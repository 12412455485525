import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { Input } from '../../ui'
import { useDispatch } from 'react-redux'
import { setSearch } from '../../../store/data/tableSlice'
import Icon from '../Icon'

type TableSearchProps = {
  onInputChange?: (value: string) => void
  tableName?: string
  value?: string
}

const TableSearch = forwardRef<HTMLInputElement, TableSearchProps>(function TableSearch(
  { onInputChange, tableName, value },
  ref,
) {
  const [searching, setSearching] = useState(false)
  const wrapperRef = useRef<HTMLDivElement>(null)

  const dispatch = useDispatch()

  const handleInputChange = (e: { target: { value: string } }) => {
    if (tableName) {
      dispatch(setSearch({ key: tableName, search: e.target.value }))
    }
    onInputChange?.(e.target.value)
  }

  const handleOnClickSearch = () => {
    const input = wrapperRef?.current?.getElementsByTagName('input')[0]
    input?.focus()
    setSearching(true)
  }

  useEffect(() => {
    const handleCloseSearch = (e: any) => {
      if (!e.target.classList.contains('injected-svg')) {
        if (!value && searching && !wrapperRef?.current?.parentNode?.contains(e.target)) {
          setSearching(false)
        }
      }
    }
    document.addEventListener('click', handleCloseSearch)
    return () => {
      document.removeEventListener('click', handleCloseSearch)
    }
  }, [searching, value])

  return (
    <div ref={wrapperRef}>
      <Input
        ref={ref}
        size="xs"
        placeholder="Search All"
        prefix={
          <div className={'search-icon z-[1] relative cursor-pointer'} onClick={handleOnClickSearch}>
            <Icon name={'search'} size={20} />
          </div>
        }
        onChange={handleInputChange}
        className={`transition-all ease-in-out duration-500 p-0 ${
          searching ? '!pl-10 !w-[175px] opacity-1' : '!pl-0 !w-[30px] opacity-0 cursor-default'
        }`}
      />
    </div>
  )
})

export default TableSearch
