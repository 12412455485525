import React, { ReactNode, MouseEvent, useCallback, useContext } from 'react'
import Icon from '../Icon'
import { DropdownContext } from './Dropdown'

export type DropdownItemProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  title?: string
  icon?: ReactNode
  active?: boolean
  disabled?: boolean
  render?: ReactNode
  eventKey?: string
  onClick?: (e: MouseEvent) => void
}

const DropdownItem = ({ title, icon, disabled = false, render, eventKey = 'click', onClick }: DropdownItemProps) => {
  const dropdown = useContext(DropdownContext)
  const { activeKey, onSelect } = dropdown

  const handleClick = useCallback(
    (e: MouseEvent) => {
      if (!disabled) {
        onClick?.(e)
        onSelect?.(eventKey, e)
      }
    },
    [disabled, eventKey, onClick, onSelect],
  )
  return (
    <div
      className={`font-figtree text-gray-900 flex items-center text-[16px] leading-[24px] ${
        disabled ? 'opacity-50' : 'hover:bg-gray-100 cursor-pointer'
      } py-[7px] px-[20px]`}
      onClick={handleClick}>
      {render ? (
        render
      ) : (
        <div className={'flex w-full justify-between items-center'}>
          <div className={'flex items-center gap-2.5'}>
            {icon ? icon : null}
            {title}
          </div>
          {activeKey === eventKey ? <Icon name={'check'} /> : null}
        </div>
      )}
    </div>
  )
}

export default DropdownItem
