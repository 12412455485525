import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { devRoutes, protectedRoutes, publicRoutes } from '../../../../config/routes'
import { PublicRoute, ProtectedRoute } from '../Route'
import AuthorityGuard from './AuthorityGuard'
import ProtectedView from './ProtectedView'
import AppRoute from '../Route/AppRoute'
import PublicView from './PublicView'
import appConfig from '../../../../config/app'

const { authenticatedEntryPath } = appConfig

const View = () => {
  return (
    <Routes>
      <Route path={'/'} element={<ProtectedRoute />}>
        <Route path={'/'} element={<Navigate replace to={authenticatedEntryPath} />} />
        {protectedRoutes.map((route, index) => (
          <Route
            key={route.key + index}
            path={route.path}
            element={
              <AuthorityGuard authority={route.authority}>
                <ProtectedView title={route.title} meta={route.meta}>
                  <AppRoute routeKey={route.key}>{route.component}</AppRoute>
                </ProtectedView>
              </AuthorityGuard>
            }
          />
        ))}
        <Route path={'*'} element={<Navigate to={'/'} replace />} />
      </Route>
      <Route path={'/'} element={<PublicRoute />}>
        {publicRoutes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              <PublicView title={route.title} meta={route.meta}>
                <AppRoute routeKey={route.key}>{route.component}</AppRoute>
              </PublicView>
            }
          />
        ))}
        {devRoutes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              <PublicView title={route.title} meta={route.meta}>
                <AppRoute routeKey={route.key}>{route.component}</AppRoute>
              </PublicView>
            }
          />
        ))}
      </Route>
    </Routes>
  )
}

export default View
