import React, { useState, forwardRef } from 'react'
import dayjs from 'dayjs'
import { isSameDate } from './utils'
import CalendarBase from './CalendarBase'
import { CalendarModifiers } from './Calendar'

export type RangeCalendarProps = {
  value: [Date, Date]
  onChange: (dates: [Date | null, Date | null]) => void
  dayStyle: (date: Date, modifiers: CalendarModifiers) => React.CSSProperties
  onMouseLeave?: (event: MouseEvent) => void
  singleDate?: boolean
  dateViewCount?: number
  paginateBy?: number
  locale: string
  month: Date
  defaultMonth: Date
  onMonthChange: (date?: Date) => void
  labelFormat: { weekday: string; month: string; year: string }
  dayClassName: (date: Date, modifiers: CalendarModifiers) => string
  disableOutOfMonth: boolean
  disableDate: (date: Date) => boolean
  preventFocus: boolean
  defaultView: 'date' | 'month' | 'year'
  hideOutOfMonthDates: boolean
  hideWeekdays: boolean
  renderDay: (date: Date) => React.ReactNode
  weekendDays: number[]
  firstDayOfWeek?: 'monday' | 'sunday'
  enableHeaderLabel?: boolean
  multipleSelection?: boolean
  className?: string
  minDate?: Date
  maxDate?: Date
  isDateFirstInRange?: (date: Date, modifiers: CalendarModifiers) => boolean
  isDateInRange?: (date: Date, modifiers: CalendarModifiers) => boolean
  isDateLastInRange?: (date: Date, modifiers: CalendarModifiers) => boolean
  onDayMouseEnter?: (date: Date) => void
  range?: [Date, Date]
  style?: React.CSSProperties
}

const RangeCalendar = forwardRef((props: RangeCalendarProps, ref) => {
  const {
    hideOutOfMonthDates,
    value,
    onChange,
    dayStyle,
    onMouseLeave,
    singleDate = false,
    dateViewCount = 1,
    paginateBy = dateViewCount,
    ...rest
  } = props

  const [hoveredDay, setHoveredDay] = useState<Date | null>(null)
  const [pickedDate, setPickedDate] = useState<Date | null>(null)

  const setRangeDate = (date: Date) => {
    if (pickedDate instanceof Date) {
      if (isSameDate(date, pickedDate) && !singleDate) {
        setPickedDate(null)
        setHoveredDay(null)
        return null
      }

      const result: [Date, Date] = [date, pickedDate]
      result.sort((a, b) => a.getTime() - b.getTime())
      onChange(result)
      setPickedDate(null)
      return null
    }

    if (value[0] && isSameDate(date, value[0]) && !singleDate) {
      setPickedDate(null)
      setHoveredDay(null)
      onChange([null, null])
      return null
    }

    onChange([date, null])
    setPickedDate(date)
    return null
  }

  const handleMouseLeave = (event: MouseEvent) => {
    typeof onMouseLeave === 'function' && onMouseLeave(event)
    setHoveredDay(null)
  }

  const shouldHighlightDate = (date: Date, modifiers: CalendarModifiers) => {
    if (pickedDate instanceof Date && hoveredDay instanceof Date) {
      const result = [hoveredDay, pickedDate]
      result.sort((a, b) => a.getTime() - b.getTime())
      return (
        !modifiers.selected &&
        dayjs(date).subtract(1, 'day').isBefore(result[1]) &&
        dayjs(date).add(1, 'day').isAfter(result[0])
      )
    }

    return false
  }

  const isPickedDateFirstInRange = (date: Date, modifiers: CalendarModifiers) => {
    if (pickedDate instanceof Date && hoveredDay instanceof Date) {
      const result = [hoveredDay, pickedDate]
      result.sort((a, b) => a.getTime() - b.getTime())
      return modifiers.selected && dayjs(date).isBefore(result[1])
    }

    return false
  }

  const isPickedDateLastInRange = (date: Date, modifiers: CalendarModifiers) => {
    if (pickedDate instanceof Date && hoveredDay instanceof Date) {
      const result = [hoveredDay, pickedDate]
      result.sort((a, b) => a.getTime() - b.getTime())
      return modifiers.selected && dayjs(date).isAfter(result[0])
    }

    return false
  }

  return (
    <CalendarBase
      ref={ref}
      dayStyle={dayStyle}
      onMouseLeave={handleMouseLeave}
      onDayMouseEnter={(date) => setHoveredDay(date)}
      onChange={setRangeDate}
      value={pickedDate || value[0]}
      range={value}
      dateViewCount={dateViewCount}
      paginateBy={paginateBy || dateViewCount}
      hideOutOfMonthDates={dateViewCount > 1}
      isDateInRange={shouldHighlightDate}
      isDateFirstInRange={isPickedDateFirstInRange}
      isDateLastInRange={isPickedDateLastInRange}
      {...rest}
    />
  )
})

export default RangeCalendar
