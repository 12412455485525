import React, { FC } from 'react'
import Icon from '../../../components/ui/Icon'

const IconExample: FC = () => {
  return (
    <div style={styles.container}>
      <Icon name={'check'} color={'green-dark'} size={30} />
      <Icon name={'log-out'} />
      <Icon name={'mail'} />
      <Icon name={'activity'} />
      <Icon name={'map-pin'} />
      <Icon name={'airplay'} />
      <Icon name={'map'} />
      <Icon name={'alert-circle'} />
      <Icon name={'maximize-2'} />
      <Icon name={'alert-octagon'} />
      <Icon name={'meh'} />
      <Icon name={'alert-triangle'} />
      <Icon name={'message-circle'} />
      <Icon name={'align-center'} />
      <Icon name={'message-square'} />
      <Icon name={'align-justify'} />
      <Icon name={'mic-off'} />
      <Icon name={'align-left'} />
      <Icon name={'mic'} />
      <Icon name={'align-right'} />
      <Icon name={'minimize-2'} />
      <Icon name={'anchor'} />
      <Icon name={'minimize'} />
      <Icon name={'aperture'} />
      <Icon name={'minus-circle'} />
      <Icon name={'archive'} />
      <Icon name={'minus-square'} />
      <Icon name={'arrow-down-circle'} />
      <Icon name={'minus'} />
      <Icon name={'arrow-down-left'} />
      <Icon name={'monitor'} />
      <Icon name={'arrow-down-right'} />
      <Icon name={'moon'} />
      <Icon name={'arrow-down'} />
      <Icon name={'more-horizontal'} />
      <Icon name={'arrow-left-circle'} />
      <Icon name={'more-vertical'} />
      <Icon name={'arrow-left'} />
      <Icon name={'mouse-pointer'} />
      <Icon name={'arrow-right-circle'} />
      <Icon name={'move'} />
      <Icon name={'arrow-right'} />
      <Icon name={'music'} />
      <Icon name={'arrow-up-circle'} />
      <Icon name={'navigation-2'} />
      <Icon name={'arrow-up-left'} />
      <Icon name={'navigation'} />
      <Icon name={'arrow-up-right'} />
      <Icon name={'number-00'} />
      <Icon name={'arrow-up'} />
      <Icon name={'number-01'} />
      <Icon name={'at-sign'} />
      <Icon name={'number-02'} />
      <Icon name={'award'} />
      <Icon name={'number-03'} />
      <Icon name={'bar-chart-2'} />
      <Icon name={'number-04'} />
      <Icon name={'bar-chart'} />
      <Icon name={'number-05'} />
      <Icon name={'battery-charging'} />
      <Icon name={'number-06'} />
      <Icon name={'battery'} />
      <Icon name={'number-07'} />
      <Icon name={'bell-off'} />
      <Icon name={'number-08'} />
      <Icon name={'bell'} />
      <Icon name={'number-09'} />
      <Icon name={'blank'} />
      <Icon name={'number-10'} />
      <Icon name={'bluetooth'} />
      <Icon name={'octagon'} />
      <Icon name={'bold'} />
      <Icon name={'package'} />
      <Icon name={'book-open'} />
      <Icon name={'paperclip'} />
      <Icon name={'book'} />
      <Icon name={'pause-circle'} />
      <Icon name={'bookmark'} />
      <Icon name={'pause'} />
      <Icon name={'box'} />
      <Icon name={'pen-tool'} />
      <Icon name={'briefcase'} />
      <Icon name={'pie-chart'} />
      <Icon name={'calendar-target'} />
      <Icon name={'placeholder'} />
      <Icon name={'calendar'} />
      <Icon name={'play-circle'} />
      <Icon name={'camera-off'} />
      <Icon name={'play'} />
      <Icon name={'camera'} />
      <Icon name={'plus-circle'} />
      <Icon name={'cards'} />
      <Icon name={'plus-square'} />
      <Icon name={'cast'} />
      <Icon name={'plus'} />
      <Icon name={'check-circle'} />
      <Icon name={'pocket'} />
      <Icon name={'check-square'} />
      <Icon name={'power'} />
      <Icon name={'printer'} />
      <Icon name={'chevron-down'} />
      <Icon name={'question'} />
      <Icon name={'chevron-left'} />
      <Icon name={'radio'} />
      <Icon name={'chevron-right'} />
      <Icon name={'refresh-ccw'} />
      <Icon name={'chevron-up'} />
      <Icon name={'refresh-cw'} />
      <Icon name={'chrome'} />
      <Icon name={'repeat'} />
      <Icon name={'circle'} />
      <Icon name={'rewind'} />
      <Icon name={'clipboard'} />
      <Icon name={'rotate-ccw'} />
      <Icon name={'clock'} />
      <Icon name={'rotate-cw'} />
      <Icon name={'cloud-rain'} />
      <Icon name={'rss'} />
      <Icon name={'cloud-snow'} />
      <Icon name={'save'} />
      <Icon name={'cloud'} />
      <Icon name={'scissors'} />
      <Icon name={'code'} />
      <Icon name={'search'} />
      <Icon name={'codepen'} />
      <Icon name={'send'} />
      <Icon name={'codesandbox'} />
      <Icon name={'server'} />
      <Icon name={'coffee'} />
      <Icon name={'settings'} />
      <Icon name={'columns'} />
      <Icon name={'share-2'} />
      <Icon name={'command'} />
      <Icon name={'share'} />
      <Icon name={'compass'} />
      <Icon name={'shield-off'} />
      <Icon name={'component'} />
      <Icon name={'shield'} />
      <Icon name={'copy'} />
      <Icon name={'shopping-bag'} />
      <Icon name={'corner-down-left'} />
      <Icon name={'shopping-cart'} />
      <Icon name={'corner-down-right'} />
      <Icon name={'shuffle'} />
      <Icon name={'corner-left-down'} />
      <Icon name={'sidebar'} />
      <Icon name={'corner-left-up'} />
      <Icon name={'skip-back'} />
      <Icon name={'corner-right-down'} />
      <Icon name={'skip-forward'} />
      <Icon name={'corner-right-up'} />
      <Icon name={'slash'} />
      <Icon name={'corner-up-left'} />
      <Icon name={'sliders'} />
      <Icon name={'corner-up-right'} />
      <Icon name={'smartphone'} />
      <Icon name={'cpu'} />
      <Icon name={'smile'} />
      <Icon name={'credit-card'} />
      <Icon name={'sort'} />
      <Icon name={'crop'} />
      <Icon name={'speaker'} />
      <Icon name={'crosshair'} />
      <Icon name={'square'} />
      <Icon name={'dashboard'} />
      <Icon name={'star'} />
      <Icon name={'database'} />
      <Icon name={'stop-circle'} />
      <Icon name={'delete'} />
      <Icon name={'sum'} />
      <Icon name={'disc'} />
      <Icon name={'sun'} />
      <Icon name={'divide-circle'} />
      <Icon name={'sunrise'} />
      <Icon name={'divide-square'} />
      <Icon name={'sunset'} />
      <Icon name={'divide'} />
      <Icon name={'tablet'} />
      <Icon name={'dollar-sign'} />
      <Icon name={'tag'} />
      <Icon name={'download-cloud'} />
      <Icon name={'target'} />
      <Icon name={'download'} />
      <Icon name={'terminal'} />
      <Icon name={'dribbble'} />
      <Icon name={'thermometer'} />
      <Icon name={'droplet'} />
      <Icon name={'thumbs-down'} />
      <Icon name={'edit-2'} />
      <Icon name={'thumbs-up'} />
      <Icon name={'edit-3'} />
      <Icon name={'toggle-left'} />
      <Icon name={'edit'} />
      <Icon name={'toggle-right'} />
      <Icon name={'external-link'} />
      <Icon name={'tool'} />
      <Icon name={'eye-off'} />
      <Icon name={'training'} />
      <Icon name={'eye'} />
      <Icon name={'trash-2'} />
      <Icon name={'facebook'} />
      <Icon name={'trash'} />
      <Icon name={'fast-forward'} />
      <Icon name={'trello'} />
      <Icon name={'feather'} />
      <Icon name={'trending-down'} />
      <Icon name={'figma'} />
      <Icon name={'trending-up'} />
      <Icon name={'file-minus'} />
      <Icon name={'truck'} />
      <Icon name={'file-plus'} />
      <Icon name={'tv'} />
      <Icon name={'file-text'} />
      <Icon name={'twitch'} />
      <Icon name={'file'} />
      <Icon name={'twitter'} />
      <Icon name={'film'} />
      <Icon name={'type'} />
      <Icon name={'filter'} />
      <Icon name={'umbrella'} />
      <Icon name={'flag'} />
      <Icon name={'underline'} />
      <Icon name={'folder-minus'} />
      <Icon name={'unlock'} />
      <Icon name={'folder-plus'} />
      <Icon name={'upload-cloud'} />
      <Icon name={'folder'} />
      <Icon name={'upload'} />
      <Icon name={'frown'} />
      <Icon name={'user-check'} />
      <Icon name={'gift'} />
      <Icon name={'user-minus'} />
      <Icon name={'globe'} />
      <Icon name={'user-plus'} />
      <Icon name={'grid'} />
      <Icon name={'user-x'} />
      <Icon name={'hard-drive'} />
      <Icon name={'user'} />
      <Icon name={'hash'} />
      <Icon name={'users'} />
      <Icon name={'headphones'} />
      <Icon name={'video-off'} />
      <Icon name={'heart'} />
      <Icon name={'video'} />
      <Icon name={'help-circle'} />
      <Icon name={'voicemail'} />
      <Icon name={'hexagon'} />
      <Icon name={'volume-1'} />
      <Icon name={'home'} />
      <Icon name={'volume-2'} />
      <Icon name={'image'} />
      <Icon name={'volume-x'} />
      <Icon name={'inbox'} />
      <Icon name={'volume'} />
      <Icon name={'info'} />
      <Icon name={'warn'} />
      <Icon name={'instagram'} />
      <Icon name={'wifi-off'} />
      <Icon name={'italic'} />
      <Icon name={'wifi'} />
      <Icon name={'key'} />
      <Icon name={'wind'} />
      <Icon name={'layers'} />
      <Icon name={'x-circle'} />
      <Icon name={'layout'} />
      <Icon name={'x-octagon'} />
      <Icon name={'leaf'} />
      <Icon name={'x-small'} />
      <Icon name={'life-buoy'} />
      <Icon name={'x-square'} />
      <Icon name={'link-2'} />
      <Icon name={'x'} />
      <Icon name={'link'} />
      <Icon name={'yes-no'} />
      <Icon name={'linkedin'} />
      <Icon name={'youtube'} />
      <Icon name={'list'} />
      <Icon name={'zap-off'} />
      <Icon name={'loader'} />
      <Icon name={'zap'} />
      <Icon name={'lock'} />
      <Icon name={'zoom-in'} />
      <Icon name={'log-in'} />
      <Icon name={'zoom-out'} />
      <Icon name={'group'} />
      <Icon name={'phone'} />
      <Icon name={'menu-expanded'} />
      <Icon name={'menu'} />
      <Icon name={'check-bold'} />
    </div>
  )
}

const styles: CSSObject = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: 12,
    padding: 100,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100vw',
  },
}

export default IconExample
