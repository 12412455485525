import React from 'react'
import { ProgramFieldsFragment } from '../../../../../graphql'
import { Icon } from '../../../../ui'

export type ProgramDropdownItemProps = {
  program: ProgramFieldsFragment
  selected?: boolean
}

const ProgramDropdownItem = ({ program, selected = false }: ProgramDropdownItemProps) => {
  return (
    <div className={`flex items-center flex-1`}>
      <img
        src={program.icon?.src}
        alt={`${program.name}-icon`}
        className={`w-[40px] h-[40px] mr-[10px] border border-gray-light rounded-[7px]`}
      />
      <div className={`flex flex-col flex-1 mr-[10px]`}>
        <h4 className={'m-0'}>{program.name}</h4>
        <div className={'body-sm text-gray-dark'}>{program.process.name}</div>
      </div>
      {selected && <Icon name={'check'} />}
    </div>
  )
}

export default ProgramDropdownItem
