import React, { FC, useState } from 'react'
import Pagination from '../../../components/ui/Pagination'

const PaginationExample: FC = () => {
  const [pageSize, setPageSize] = useState(10)
  const [pageIndex, setPageIndex] = useState(1)
  const [basicPageSize, setBasicPageSize] = useState(10)
  const [basicPageIndex, setBasicPageIndex] = useState(1)

  const total = 500
  const basicTotal = 50

  const handlePaginationChange = (page: number | undefined) => {
    setPageIndex(Number(page))
  }

  const handleSelectChange = (size: number | undefined) => {
    setPageSize(Number(size))
  }

  const handleBasicPaginationChange = (page: number | undefined) => {
    setBasicPageIndex(Number(page))
  }

  const handleBasicSelectChange = (size: number | undefined) => {
    setBasicPageSize(Number(size))
  }

  return (
    <>
      <div className="flex justify-center flex-col gap-3 items-center bg-gray-200 py-[100px]">
        <Pagination
          pageSize={pageSize}
          currentPage={pageIndex}
          total={total}
          displayTotal={true}
          onChange={handlePaginationChange}
          onSelectChange={handleSelectChange}
        />
      </div>
      <div className="flex justify-center flex-col gap-3 items-center py-[100px]">
        <Pagination
          pageSize={pageSize}
          currentPage={pageIndex}
          total={total}
          displayTotal={true}
          onChange={handlePaginationChange}
          onSelectChange={handleSelectChange}
          darkHover={true}
        />
      </div>
      <div className="flex justify-center flex-col gap-3 items-center bg-gray-200 py-[100px]">
        <Pagination
          pageSize={pageSize}
          currentPage={pageIndex}
          total={total}
          onChange={handlePaginationChange}
          onSelectChange={handleSelectChange}
        />
      </div>
      <div className="flex justify-center flex-col gap-3 items-center py-[100px]">
        <Pagination
          pageSize={basicPageSize}
          pageSizes={[]}
          currentPage={basicPageIndex}
          total={basicTotal}
          displayTotal={false}
          onChange={handleBasicPaginationChange}
          onSelectChange={handleBasicSelectChange}
          darkHover={true}
        />
      </div>
    </>
  )
}

export default PaginationExample
