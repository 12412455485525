import React, { useCallback, useState } from 'react'
import classNames from 'classnames'
import useTimeout from '../../../hooks/useTimeout'
import CloseButton from '../CloseButton'
import StatusIcon from '../StatusIcon'

export type NotificationProps = {
  duration?: number
  onClose?: (e?: React.MouseEvent<HTMLSpanElement>) => void
  type?: 'info' | 'success' | 'warning' | 'error'
  title?: React.ReactNode
  closable?: boolean
  width?: number
  customIcon?: React.ReactNode
  customIconUrl?: string
  className?: string
  children?: React.ReactNode | (() => React.ReactNode)
  triggerByToast?: boolean
  style?: React.CSSProperties
}

const Index = React.forwardRef((props: NotificationProps, ref) => {
  const {
    duration = 3000,
    onClose = () => {},
    type,
    title,
    closable = false,
    className,
    children,
    width = 350,
    customIcon,
    triggerByToast = false,
    style,
    customIconUrl,
    ...rest
  } = props

  const [display, setDisplay] = useState('show')

  const { clear } = useTimeout(onClose, duration, duration > 0)

  const handleClose = useCallback(
    (e: React.MouseEvent<HTMLSpanElement, MouseEvent> | undefined) => {
      setDisplay('hiding')
      onClose?.(e)
      clear()
      if (!triggerByToast) {
        setTimeout(() => {
          setDisplay('hide')
        }, 400)
      }
    },
    [onClose, clear, triggerByToast],
  )

  const notificationClass = classNames('notification', className, 'shadow-[3px_3px_30px_0px_rgba(0,0,0,0.10)]')

  if (display === 'hide') {
    return null
  }

  return (
    <div
      ref={ref as React.Ref<HTMLDivElement>}
      {...rest}
      className={notificationClass}
      style={{ width: width, ...style }}>
      <div className={classNames('notification-content', !children && 'no-child')}>
        {type && !customIcon && !customIconUrl ? (
          <div className={'mr-[15px] pt-[2px]'}>
            <StatusIcon type={type} />
          </div>
        ) : null}
        {customIcon && <div className={`mr-[15px]`}>{customIcon}</div>}
        {customIconUrl && (
          <div className={`mr-[15px] w-[40px] h-[40px]`}>
            <img
              alt={'custom-icon-example'}
              className={'rounded-full max-w-none'}
              src={customIconUrl}
              width={40}
              height={40}
            />
          </div>
        )}
        <div className="mr-4">
          {title && <h3 className={classNames('mt-[0px]', children && 'mb-[2px]')}>{title}</h3>}
          <div className="notification-description body-sm">
            {typeof children === 'function' ? children() : children}
          </div>
        </div>
      </div>
      {closable && <CloseButton className="notification-close" onClick={handleClose} hoverBackground absolute />}
    </div>
  )
})

export default Index
