import React, { ElementType } from 'react'
import { ReactComponent as Loader } from '../../../assets/icons/icon-loader.svg'

type IndicatorProps = {
  color?: string
  indicator?: ElementType | undefined
  isSpinning?: boolean
  size?: number | string
}

const Indicator = ({ indicator, color = 'gray-900', isSpinning = false, size = 20 }: IndicatorProps) => {
  const SVG = indicator ? indicator : Loader
  return (
    <SVG
      className={`${isSpinning ? 'animate-spin ' : ''} ${color ? 'text-' + color : ''} shrink-0`}
      style={{ color }}
      height={size}
      width={size}
    />
  )
}

export default Indicator
