import React from 'react'
import { ForgotPassword, ResetPassword, SignUp } from '../dummyComponents'
import SignIn from '../../views/auth/SignIn'
import { RouteConfig } from './index'

const publicRoutes: RouteConfig[] = [
  {
    title: 'Sign In',
    key: 'signIn',
    path: '/sign-in',
    layout: {
      side: true,
    },
    component: React.lazy(() => Promise.resolve({ default: SignIn })),
  },
  {
    title: 'Forgot Password',
    key: 'forgotPassword',
    path: '/forgot-password',
    component: React.lazy(() => Promise.resolve({ default: ForgotPassword })),
  },
  {
    title: 'Reset Password',
    key: 'resetPassword',
    path: '/reset-password',
    component: React.lazy(() => Promise.resolve({ default: ResetPassword })),
  },
  {
    title: 'Sign Up',
    key: 'signUp',
    path: '/sign-up',
    component: React.lazy(() => Promise.resolve({ default: SignUp })),
  },
]

export default publicRoutes
