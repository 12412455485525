import React from 'react'
import classNames from 'classnames'
import { Button } from '../../index'
import Icon from '../../Icon'

const IconRight = <Icon name={'chevron-right'} />
const IconLeft = <Icon name={'chevron-left'} />

type HeaderProps = {
  hasNext: boolean
  hasPrevious: boolean
  onNext: () => void
  onPrevious: () => void
  onNextLevel?: () => void
  label?: string
  nextLevelDisabled?: boolean
  nextLabel?: string
  previousLabel?: string
  preventLevelFocus?: boolean
  renderCenter?: boolean
  preventFocus?: boolean
  children?: React.ReactNode
}

const Header = (props: HeaderProps) => {
  const {
    hasNext,
    hasPrevious,
    onNext,
    onPrevious,
    onNextLevel,
    label,
    nextLevelDisabled,
    nextLabel,
    previousLabel,
    preventLevelFocus = false,
    renderCenter = false,
    preventFocus,
    children,
    ...rest
  } = props

  const headerLabel = (
    <button
      className="picker-header-label"
      disabled={nextLevelDisabled}
      onClick={onNextLevel}
      tabIndex={preventLevelFocus ? -1 : 0}
      onMouseDown={(event) => preventFocus && event.preventDefault()}>
      {label}
    </button>
  )

  const renderChildren = children ? children : headerLabel

  return (
    <div className="picker-header flex items-center justify-between mb-2" {...rest}>
      {!renderCenter && renderChildren}
      <div className={classNames(renderCenter && 'justify-between w-full', 'flex items-center rtl:flex-row-reverse')}>
        <Button
          variant="plain"
          size="sm"
          icon={IconLeft}
          disabled={!hasPrevious}
          onClick={onPrevious}
          aria-label={previousLabel}
          onMouseDown={(event: React.MouseEvent) => preventFocus && event.preventDefault()}
        />
        {renderCenter && renderChildren}
        <Button
          variant="plain"
          size="sm"
          icon={IconRight}
          disabled={!hasNext}
          onClick={onNext}
          aria-label={nextLabel}
          onMouseDown={(event: React.MouseEvent) => preventFocus && event.preventDefault()}
        />
      </div>
    </div>
  )
}

export default Header
