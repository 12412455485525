import React from 'react'
import appConfig from '../../../../config/app'
import { Navigate, Outlet } from 'react-router-dom'
import useAuth from '../../../../hooks/useAuth'

const { authenticatedEntryPath } = appConfig

const PublicRoute = () => {
  const { authenticated } = useAuth()

  return authenticated ? <Navigate to={authenticatedEntryPath} /> : <Outlet />
}

export default PublicRoute
