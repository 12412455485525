import { useMemo } from 'react'
import isEmpty from 'lodash/isEmpty'
import { Role } from '../graphql'

function useAuthority(roles: Role[] = [], authority: Role[] = [], emptyCheck = false) {
  const roleMatched = useMemo(() => {
    return authority.some((role) => roles.includes(role))
  }, [authority, roles])

  if (roles.includes(Role.Admin)) {
    return true
  }

  if (isEmpty(authority) || isEmpty(roles) || typeof authority === 'undefined') {
    return !emptyCheck
  }

  return roleMatched
}

export default useAuthority
