import React from 'react'
import CalendarBase from './CalendarBase'
import { isSameDate } from './utils'

export type CalendarModifiers = {
  disabled: boolean
  weekend: boolean
  selectedInRange: boolean
  selected: boolean
  inRange: boolean
  firstInRange: boolean
  lastInRange: boolean
  outOfMonth: boolean
}

export type CalendarProps = {
  locale: string
  value: Date | Date[]
  month: Date
  defaultMonth?: Date
  onMonthChange: (date?: Date) => void
  onChange: (date: Date | Date[]) => void
  labelFormat: { weekday: string; month: string; year: string }
  dayClassName?: (date: Date, modifiers: CalendarModifiers) => string
  dayStyle?: (date: Date, modifiers: CalendarModifiers) => React.CSSProperties
  disableOutOfMonth: boolean
  disableDate?: (date: Date) => boolean
  preventFocus: boolean
  defaultView?: 'date' | 'month' | 'year'
  hideOutOfMonthDates: boolean
  hideWeekdays: boolean
  renderDay?: (date: Date) => React.ReactNode
  weekendDays: number[]
  dateViewCount?: number
  firstDayOfWeek?: 'monday' | 'sunday'
  paginateBy?: number
  enableHeaderLabel?: boolean
  multipleSelection?: boolean
  className?: string
  minDate?: Date
  maxDate?: Date
  isDateFirstInRange?: (date: Date, modifiers: CalendarModifiers) => boolean
  isDateInRange?: (date: Date, modifiers: CalendarModifiers) => boolean
  isDateLastInRange?: (date: Date, modifiers: CalendarModifiers) => boolean
  onDayMouseEnter?: (date: Date) => void
  range?: [Date, Date]
  style?: React.CSSProperties
}

const Calendar = (props: CalendarProps) => {
  const { multipleSelection = false, value, onChange, ...rest } = props

  const handleChange = (date: Date) => {
    if (!multipleSelection) {
      return onChange(date)
    }

    if (Array.isArray(value)) {
      const isSelected = value.some((val) => isSameDate(val, date))

      return onChange(isSelected ? value.filter((val) => !isSameDate(val, date)) : [...value, date])
    }

    return onChange([date])
  }

  return <CalendarBase onChange={handleChange} value={value} {...rest} />
}

export default Calendar
