import { ApolloClient, InMemoryCache } from '@apollo/client'
import decorateLink from './LinkService'
import { DecoratedClientProps } from './index'
import { CachePersistor } from 'apollo3-cache-persist'

export const cache = new InMemoryCache({
  typePolicies: {
    Me: {
      keyFields: [],
    },
  },
})

export const persistor = new CachePersistor({
  cache,
  storage: localStorage,
})

persistor.restore()

const decorateClient = (props: DecoratedClientProps) =>
  new ApolloClient({
    link: decorateLink(props),
    cache,
  })

export default decorateClient
