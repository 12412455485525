import React from 'react'

type LabelProps = {
  label?: string
  color?: string
}

const Label = ({ label = '', color = 'gray' }: LabelProps) => {
  return (
    <div
      className={`border-${color} border-[1px] h-[22px] rounded-[5px] py-[6px] px-[5px] flex justify-center items-center gap-10 w-fit mt-[8px]`}>
      <h6 className={`text-${color} m-0 text-center align-middle uppercase`}>{label}</h6>
    </div>
  )
}

export default Label
