import { createSlice } from '@reduxjs/toolkit'

export interface CommonState {
  currentRouteKey: string
  isModalOpen: boolean
}

export const initialState: CommonState = {
  currentRouteKey: '',
  isModalOpen: false,
}

export const commonSlice = createSlice({
  name: 'base/common',
  initialState,
  reducers: {
    setCurrentRouteKey: (state, action) => {
      state.currentRouteKey = action.payload
    },
    openModal: (state) => {
      state.isModalOpen = true
    },
    closeModal: (state) => {
      state.isModalOpen = false
    },
  },
})

export const { setCurrentRouteKey, openModal, closeModal } = commonSlice.actions

export default commonSlice.reducer
