import React, { FC } from 'react'
import { Avatar, Card, Icon, ProgressLoader, Tag, Button } from '../../../components/ui'
import CoffeeChatStockImage from '../../../assets/samples/CoffeeChatStockImage.svg'

const footerButton = (color: string) => {
  return (
    <div className={'ml-auto'}>
      <Button color={color} size={'md'} iconLocation={'right'} label={'Open'} variant={'plain'}>
        <Icon name={'arrow-right'} color={color} size={25} />
      </Button>
    </div>
  )
}

const CardExample: FC = () => {
  return (
    <div className={'container mx-auto px-4 py-6 bg-gray-200'}>
      <div className={'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'}>
        <div>
          <Card
            onClick={() => console.log('Card clicked!')}
            headerDivider={false}
            headerClass={'text-lg font-bold text-[#333132]'}>
            <h3 className={'font-semibold mt-2'}>Card Title</h3>
            <div className={'text-md font-medium text-[#737373]'}>
              Some quick example text to build on the card title and make up the bulk of the card's content.
            </div>
          </Card>
        </div>
        <div>
          <Card
            onClick={() => console.log('Card clicked!')}
            header={'STEP 1'}
            headerIcon={<Icon name={'leaf'} color={'#0891B2'} size={20} />}
            headerColor={'#0891B2'}
            footer={
              <div className={'flex justify-between w-full'}>
                <div className={'flex items-center'}>
                  <Avatar.Group
                    chained
                    maxCount={4}
                    omittedAvatarProps={{ shape: 'circle', size: 'lg' }}
                    onOmittedAvatarClick={() => console.log('Omitted avatars clicked!')}
                    omittedAvatarTooltip={true}>
                    <Avatar
                      shape={'circle'}
                      size={'sm'}
                      src={'https://randomuser.me/api/portraits/men/7.jpg'}
                      name={'User 1'}
                    />
                    <Avatar
                      shape={'circle'}
                      size={'sm'}
                      src={'https://randomuser.me/api/portraits/men/5.jpg'}
                      name={'User 2'}
                    />
                    <Avatar
                      shape={'circle'}
                      size={'sm'}
                      src={'https://randomuser.me/api/portraits/women/71.jpg'}
                      name={'User 3'}
                    />
                    <Avatar
                      shape={'circle'}
                      size={'sm'}
                      src={'https://randomuser.me/api/portraits/men/4.jpg'}
                      name={'User 4'}
                    />
                  </Avatar.Group>
                  <div className={'ml-2 font-normal text-[#737373]'}>4 Enrolled</div>
                </div>
                <div className={'ml-auto'}>{footerButton('#428183')}</div>
              </div>
            }
            headerClass={'text-lg font-semibold'}
            footerClass={'text-blue-500 font-bold'}>
            <h3 className={'font-semibold'}>Discipleship Basics</h3>
            <div className={'text-md font-medium text-[#737373]'}>8 Sessions | 5 Assignments</div>
          </Card>
        </div>
        <div>
          <Card
            header={'PRACTICAL CHRISTIAN LIVING'}
            headerIcon={false}
            headerDivider={false}
            footer={
              <div className={'flex justify-between w-full'}>
                <div className={'flex items-center'}>
                  <Avatar
                    shape={'circle'}
                    size={'md'}
                    src={'https://randomuser.me/api/portraits/men/7.jpg'}
                    name={'User 1'}
                  />
                  <div className={'flex flex-col ml-2 leading-tight'}>
                    <h4 className={'m-0'}>Sean Moorehead</h4>
                    <div className={'font-normal text-[#838383] m-0 text-[14px]'}>July 14th, 2023</div>
                  </div>
                </div>
                {footerButton('#428183')}
              </div>
            }
            headerClass={'text-lg font-semibold'}
            footerClass={'text-blue-500 font-bold'}>
            <h3 className={'font-semibold'}>5 Ways to Evaluate Spiritual Growth in a Small Group Setting</h3>
            <div className={'text-md font-normal text-[#737373]'}>For Leaders</div>
          </Card>
        </div>
        <div>
          <Card
            media={CoffeeChatStockImage}
            header={'STEP 1'}
            headerDivider
            headerColor={'#C026D3'}
            headerIcon={<Icon name={'training'} color={'#C026D3'} size={20} />}
            footer={
              <div className={'flex justify-between w-full'}>
                <div className={'flex items-center'}>
                  <Tag prefix={true} type={'solid'} color={'green'}>
                    Discipleship
                  </Tag>
                </div>
                {footerButton('#C026D3')}
              </div>
            }
            headerClass={'text-lg font-semibold'}
            footerClass={'text-blue-500 font-bold'}>
            <h3 className={'font-semibold'}>Card Title</h3>
            <div className={'text-md font-medium text-[#737373]'}>
              Some quick example text to build on the card title and make up the bulk of the card's content. Some quick
              example text to build on the card title and make up the bulk of the card's content. Some quick example
              text to build on the card title and make up the bulk of the card's content. Some quick example text to
              build on the card title and make up the bulk of the card's content.
            </div>
          </Card>
        </div>
        <div>
          <Card
            media={CoffeeChatStockImage}
            header={'STEP 5'}
            headerDivider
            headerColor={'#EA580C'}
            headerIcon={<Icon name={'users'} color={'#EA580C'} size={20} />}
            footer={
              <div className={'flex justify-between w-full'}>
                <div className={'flex items-center'}>
                  <ProgressLoader
                    size={'sm'}
                    color={'#EA580C'}
                    variant={'circle'}
                    percent={50}
                    customInfo={'5/10 steps'}
                  />
                </div>
                {footerButton('#EA580C')}
              </div>
            }
            headerClass={'text-lg font-semibold'}
            footerClass={'text-blue-500 font-bold'}>
            <h3 className={'font-semibold'}>Card Title</h3>
            <div className={'text-md font-medium text-[#737373]'}>8 Sessions | 5 Assignments</div>
          </Card>
        </div>
      </div>
    </div>
  )
}

export default CardExample
