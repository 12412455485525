import React, { FC, useEffect, useRef } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { useDispatch } from 'react-redux'
import { openModal, closeModal } from '../../../store/base/commonSlice'
import { useSelector } from 'react-redux'
import { selectIsModalOpen } from '../../../store/selectors/Selectors'

import DialogueHeader from './DialogueHeader'
import DialogueBody from './DialogueBody'
import DialogueFooter from './DialogueFooter'

const dialogueVariants = {
  hidden: { opacity: 0, y: 10 },
  visible: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 15 },
}

const overlayVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
  exit: { opacity: 0 },
}

type DialogueHeaderProps = {
  children: React.ReactNode
  className?: string
  style?: React.CSSProperties
}

type DialogueBodyProps = {
  children: React.ReactNode
  className?: string
  style?: React.CSSProperties
}

type DialogueFooterProps = {
  children: React.ReactNode
  className?: string
  style?: React.CSSProperties
}

type DialogueProps = {
  isOpen: boolean
  onRequestClose?: (event: React.MouseEvent<HTMLDivElement, MouseEvent> | KeyboardEvent) => void
  shouldCloseOnOverlayClick?: boolean
  style?: {
    overlay?: React.CSSProperties
    content?: React.CSSProperties
  }
  width?: string | number
  height?: string | number
  contentClassName?: string
  portalClassName?: string
  bodyOpenClassName?: string
  htmlOpenClassName?: string
  overlayClassName?: string
  onAfterOpen?: (overlay: HTMLDivElement, content: HTMLDivElement) => void
  closeTimeoutMS?: number
  shouldFocusAfterRender?: boolean
  shouldReturnFocusAfterClose?: boolean
  shouldCloseOnEsc?: boolean
  appElement?: HTMLElement
  parentSelector?: () => HTMLElement
  overlayRef?: (ref: HTMLDivElement) => void
  contentRef?: (ref: HTMLDivElement) => void
  closable?: boolean
  onClose?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  children: React.ReactNode
}

const Dialogue: FC<DialogueProps> & {
  Header: FC<DialogueHeaderProps>
  Body: FC<DialogueBodyProps>
  Footer: FC<DialogueFooterProps>
} = ({
  isOpen,
  onRequestClose,
  shouldCloseOnOverlayClick = true,
  style,
  width = '300px',
  height = '300px',
  contentClassName = '',
  portalClassName = '',
  bodyOpenClassName = '',
  htmlOpenClassName = '',
  overlayClassName = '',
  onAfterOpen,
  closeTimeoutMS = 150,
  shouldFocusAfterRender = true,
  shouldReturnFocusAfterClose = true,
  shouldCloseOnEsc = true,
  appElement,
  overlayRef,
  contentRef,
  closable = true,
  onClose,
  children,
}) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (isOpen) {
      dispatch(openModal())
    } else {
      dispatch(closeModal())
    }
  }, [isOpen, dispatch])

  const isModalOpen = useSelector(selectIsModalOpen)

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }

    return () => {
      document.body.style.overflow = ''
    }
  }, [isModalOpen])

  const overlayEl = useRef<HTMLDivElement>(null)
  const contentEl = useRef<HTMLDivElement>(null)

  const handleContentClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation()
  }

  const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (shouldCloseOnOverlayClick && onRequestClose) {
      onRequestClose(event)
    }
  }

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (shouldCloseOnEsc && event.key === 'Escape' && onRequestClose) {
        onRequestClose(event as unknown as React.MouseEvent<HTMLDivElement, MouseEvent>)
      }
    }

    if (bodyOpenClassName && isOpen) {
      document.body.classList.add(bodyOpenClassName)
    }

    if (htmlOpenClassName && isOpen) {
      document.documentElement.classList.add(htmlOpenClassName)
    }

    if (onAfterOpen && overlayEl.current && contentEl.current && isOpen) {
      onAfterOpen(overlayEl.current, contentEl.current)
    }

    if (appElement && isOpen) {
      appElement.setAttribute('aria-hidden', 'true')
    }

    if (overlayRef && overlayEl.current) {
      overlayRef(overlayEl.current)
    }

    if (contentRef && contentEl.current) {
      contentRef(contentEl.current)
    }

    window.addEventListener('keydown', handleEsc)

    return () => {
      if (bodyOpenClassName) {
        document.body.classList.remove(bodyOpenClassName)
      }

      if (htmlOpenClassName) {
        document.documentElement.classList.remove(htmlOpenClassName)
      }

      if (appElement) {
        appElement.removeAttribute('aria-hidden')
      }

      window.removeEventListener('keydown', handleEsc)
    }
  }, [
    isOpen,
    bodyOpenClassName,
    htmlOpenClassName,
    onAfterOpen,
    appElement,
    overlayRef,
    contentRef,
    onRequestClose,
    shouldCloseOnEsc,
  ])

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>

    if (!isOpen) {
      timeoutId = setTimeout(() => {
        if (shouldReturnFocusAfterClose && document.activeElement instanceof HTMLElement) {
          document.activeElement.blur()
        }
      }, closeTimeoutMS)
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [isOpen, shouldReturnFocusAfterClose, closeTimeoutMS])

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className="fixed inset-0 flex gray-900/30 justify-center items-center"
          onClick={handleOverlayClick}
          ref={overlayEl}
          initial="hidden"
          animate="visible"
          exit="exit"
          variants={overlayVariants}
          transition={{ duration: 0.5 }}
          style={{ zIndex: 9 }}>
          <motion.div
            onClick={handleContentClick}
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={dialogueVariants}
            transition={{ duration: 0.3 }}
            className="relative bg-white flex flex-col rounded-lg p-5 shadow-md"
            style={{
              width,
              maxHeight: 450,
              height: height > '300px' ? 'calc(100vh - 40px)' : height,
              zIndex: 10,
              boxShadow: '3px 3px 30px rgba(0, 0, 0, 0.3)',
              margin: '20px',
            }}
            tabIndex={-1}
            ref={contentEl}>
            {children}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

Dialogue.Header = DialogueHeader
Dialogue.Body = DialogueBody
Dialogue.Footer = DialogueFooter

export default Dialogue
