import React, { FC } from 'react'
import { useSegment } from './context'

interface SegmentItemProps {
  value: string
  disabled?: boolean
  children: React.ReactNode | ((isActive: boolean) => React.ReactNode)
  isFirst?: boolean
  isLast?: boolean
}

const SegmentItem: FC<SegmentItemProps> = ({ value, disabled, children, isFirst, isLast }) => {
  const { activeValues, onSegmentItemClick, size } = useSegment()
  const isActive = activeValues.includes(value)

  const styles = {
    lg: { width: 'w-[103px]', height: 'h-[56px]', fontSize: 'text-[16px]' },
    md: { width: 'w-[95px]', height: 'h-[44px]', fontSize: 'text-[14px]' },
    sm: { width: 'w-[83px]', height: 'h-[36px]', fontSize: 'text-[14px]' },
    xs: { width: 'w-[79px]', height: 'h-[29px]', fontSize: 'text-[12px]', padding: 'px-[10px]' },
  }

  const borderColor = disabled
    ? 'z-1 border-t-#EDEDED border-b-#EDEDED border-r-gray-light border-l-gray-light'
    : 'z-5 border-gray-light'

  let borderClass = `border-t-2 border-b-2 border-r-2 border-l-2 ${borderColor}`

  if (isFirst) {
    borderClass = `border-t-2 border-b-2 border-l-2 border-r-1 ${borderColor}`
  } else if (isLast) {
    borderClass = `border-t-2 border-b-2 border-r-2 border-l-1 ${borderColor}`
  }

  const sizeClasses = styles[size || 'md']

  const borderRadiusClass = isFirst
    ? 'rounded-tl-[10px] rounded-bl-[10px]'
    : isLast
    ? 'rounded-tr-[10px] rounded-br-[10px]'
    : ''

  const bgColorClass = isActive ? 'bg-gray-light' : 'bg-gray-300'
  const hoverBgColorClass = disabled || isActive ? '' : 'hover:bg-gray-200'
  const textColor = disabled ? '#A6A6A6' : '#333132'

  const handleClick = () => {
    if (!disabled && onSegmentItemClick) {
      onSegmentItemClick(value)
    }
  }

  return (
    <button
      className={`relative ${sizeClasses.width} ${sizeClasses.height} ${sizeClasses.fontSize} ${borderRadiusClass} font-semibold ${bgColorClass} ${borderClass} ${hoverBgColorClass}`}
      style={{ color: textColor, zIndex: disabled ? 0 : 10 }}
      disabled={disabled}
      onClick={handleClick}>
      {typeof children === 'function' ? children(isActive) : children}
    </button>
  )
}

export default SegmentItem
