import { combineReducers } from 'redux'
import sidePeek from './sidePeekSlice'
import layout from './layoutSlice'

const reducer = combineReducers({
  sidePeek,
  layout,
})

export default reducer
