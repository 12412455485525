import React, { FC } from 'react'
import Tooltip from '../../../components/ui/Tooltip'

const TooltipExample: FC = () => {
  const classes = 'border-[2px] rounded-[5px] border-gray-900 p-10'
  return (
    <div className="flex justify-center flex-col gap-20 items-center m-24">
      <Tooltip title={<div className={'text-yellow'}>Top HTML</div>} placement={'top'} wrapperClass={classes}>
        <span>Top HTML</span>
      </Tooltip>
      <Tooltip title={'Top'} placement={'top'} wrapperClass={classes}>
        <span>Top</span>
      </Tooltip>
      <Tooltip title={'Top Start'} placement={'top-start'} wrapperClass={classes}>
        <span>Top Start</span>
      </Tooltip>
      <Tooltip title={'Top End'} placement={'top-end'} wrapperClass={classes}>
        <span>Top End</span>
      </Tooltip>
      <Tooltip title={'Bottom'} placement={'bottom'} wrapperClass={classes}>
        <span>Bottom</span>
      </Tooltip>
      <Tooltip title={'Bottom Start'} placement={'bottom-start'} wrapperClass={classes}>
        <span>Bottom Start</span>
      </Tooltip>
      <Tooltip title={'Bottom End'} placement={'bottom-end'} wrapperClass={classes}>
        <span>Bottom End</span>
      </Tooltip>
      <Tooltip title={'Right'} placement={'right'} wrapperClass={classes}>
        <span>Right</span>
      </Tooltip>
      <Tooltip title={'Right Start'} placement={'right-start'} wrapperClass={classes}>
        <span>Right Start</span>
      </Tooltip>
      <Tooltip title={'Right End'} placement={'right-end'} wrapperClass={classes}>
        <span>Right End</span>
      </Tooltip>
      <Tooltip title={'Left'} placement={'left'} wrapperClass={classes}>
        <span>Left</span>
      </Tooltip>
      <Tooltip title={'Left Start'} placement={'left-start'} wrapperClass={classes}>
        <span>Left Start</span>
      </Tooltip>
      <Tooltip title={'Left End'} placement={'left-end'} wrapperClass={classes}>
        <span>Left End</span>
      </Tooltip>
    </div>
  )
}

export default TooltipExample
