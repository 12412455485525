import React, { ReactNode } from 'react'
import { Divider } from '../index'

type DropdownHeaderProps = {
  children?: ReactNode
}

const DropdownHeader = ({ children }: DropdownHeaderProps) => {
  return (
    <>
      <div className={'px-[20px]'}>{children}</div>
      <div className={'p-4'}>
        <Divider />
      </div>
    </>
  )
}

export default DropdownHeader
