import { createContext, useContext } from 'react'

interface SegmentContextType {
  activeValues: string[]
  onSegmentItemClick?: (value: string) => void
  size: 'lg' | 'md' | 'sm' | 'xs'
  isFirst?: boolean
  isLast?: boolean
}

const SegmentContext = createContext<SegmentContextType | undefined>(undefined)

export const SegmentContextProvider = SegmentContext.Provider

export const useSegment = () => {
  const context = useContext(SegmentContext)
  if (!context) {
    throw new Error('useSegment must be used within a SegmentContextProvider')
  }
  return context
}

export default SegmentContext
