import React from 'react'
import { toast, Notification } from '../components/ui'
import { toastDefaultProps } from '../components/ui/Toast/ToastWrapper'

function useToast() {
  return {
    push: (title: string, message: string, type: 'info' | 'success' | 'warning' | 'error' = 'info') => {
      toast.push(
        <Notification title={title} type={type} duration={5000} closable>
          {message}
        </Notification>,
        toastDefaultProps,
      )
    },
  }
}

export default useToast
