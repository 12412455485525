import React, { FC } from 'react'
import appConfig from '../../../config/app'

type BrandProps = {
  className?: string
  type?: 'full' | 'streamline'
  mode?: 'dark' | 'light'
}

const Brand: FC<BrandProps> = ({ className, type = 'full', mode = 'light' }) => {
  return (
    <img
      className={className}
      src={`${appConfig.issuePath}/img/logo/logo-${mode}-${type}.png`}
      alt={'TMS Brand Logo'}
    />
  )
}

export default Brand
