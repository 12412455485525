import React from 'react'
import { Badge } from '../../../components/ui'
import { Avatar } from '../../../components/ui'
import BronzeSvg from '../../../assets/BronzeSVG'

const BadgeExample = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen space-y-5">
      <Badge position="top-right" content="1" render={() => <BronzeSvg />}>
        <Avatar shape={'rounded'} size={'lg'} name={'Ned Stark'} />
      </Badge>
      <Badge position="top-right" content="1">
        <Avatar shape={'rounded'} size={'lg'} name={'Ned Stark'} />
      </Badge>
      <Badge position="top-left" content="1">
        <Avatar shape={'rounded'} size={'md'} name={'Ned Stark'} />
      </Badge>
      <Badge position="top-right" content={100} maxCount={199}>
        <Avatar shape={'rounded'} size={'lg'} name={'Ned Stark'} />
      </Badge>
      <Badge position="top-right" content={100} maxCount={99}>
        <Avatar shape={'rounded'} size={'lg'} name={'Ned Stark'} />
      </Badge>
      <Badge position="bottom-left" content="1" render={() => <BronzeSvg />}>
        <Avatar shape={'circle'} size={'md'} name={'Ned Stark'} />
      </Badge>
      <Badge position="top-right" content={1}>
        <Avatar shape={'square'} size={'sm'} name={'Ned Stark'} />
      </Badge>
      <Badge position="bottom-right" content="1" render={() => <BronzeSvg />}>
        <Avatar shape={'circle'} size={'md'} name={'Ned Stark'} />
      </Badge>
      <Badge position="top-right">
        <Avatar shape={'rounded'} size={'sm'} name={'Ned Stark'} />
      </Badge>
      <Badge position="top-right">
        <Avatar shape={'rounded'} size={'sm'} name={'Ned Stark'} />
      </Badge>
    </div>
  )
}

export default BadgeExample
