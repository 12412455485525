import { AuthFieldsFragmentDoc, AuthFieldsFragment } from '../graphql'
import { useFragment } from '@apollo/client'
import useSignIn from './useSignIn'
import useSignOut from './useSignOut'

function useAuth() {
  const signIn = useSignIn()
  const signOut = useSignOut()

  const { data } = useFragment<AuthFieldsFragment>({
    fragment: AuthFieldsFragmentDoc,
    from: 'Auth:{}',
  })

  return {
    authenticated: !!data?.token,
    token: data.token,
    user: data.me as AuthFieldsFragment['me'] | undefined,
    signIn,
    signOut,
  }
}

export default useAuth
