import React, { FC } from 'react'

type DialogueBodyProps = {
  children: React.ReactNode
  className?: string
  style?: React.CSSProperties
}

const DialogueBody: FC<DialogueBodyProps> = ({ children }) => (
  <div className="flex-grow overflow-y-auto text-sm">{children}</div>
)

export default DialogueBody
