import React, { forwardRef, KeyboardEventHandler, MouseEventHandler } from 'react'
import classNames from 'classnames'
import useTheme from '../../../../../hooks/useTheme'
import { DayProps } from './utils/getDayProps'

function getDayTabIndex({
  focusable,
  hasValue,
  selected,
  firstInMonth,
}: {
  focusable: boolean
  hasValue: boolean
  selected: boolean
  firstInMonth: boolean
}) {
  if (!focusable) {
    return -1
  }

  if (hasValue) {
    return selected ? 0 : -1
  }

  return firstInMonth ? 0 : -1
}

type DayComponentProps = {
  className?: string
  value: Date
  focusable?: boolean
  hideOutOfMonthDates?: boolean
  renderDay?: (date: Date, props: DayProps | null) => React.ReactNode
  selected: boolean
  weekend: boolean
  disabled: boolean
  outOfMonth: boolean
  onMouseEnter: (date: Date) => void
  hasValue: boolean
  firstInRange: boolean
  lastInRange: boolean
  inRange: boolean
  isToday: boolean
  firstInMonth: boolean
  onClick: MouseEventHandler<HTMLButtonElement>
  onMouseDown: MouseEventHandler<HTMLButtonElement>
  onKeyDown: KeyboardEventHandler<HTMLButtonElement>
  style: React.CSSProperties
  fullWidth: boolean | undefined
  styles: React.CSSProperties | undefined
}

const Day = forwardRef<HTMLButtonElement, DayComponentProps>((props: DayComponentProps, ref) => {
  const {
    className,
    value,
    selected,
    weekend,
    outOfMonth,
    onMouseEnter,
    hasValue,
    firstInRange,
    lastInRange,
    inRange,
    isToday,
    firstInMonth,
    focusable,
    hideOutOfMonthDates,
    renderDay,
    disabled,
    ...others
  } = props

  const { color, colorLevel } = useTheme()

  return (
    <button
      {...others}
      type={'button'}
      ref={ref}
      disabled={disabled}
      onMouseEnter={() => onMouseEnter(value)}
      tabIndex={getDayTabIndex({ focusable: focusable || false, hasValue, selected, firstInMonth })}
      className={classNames(
        'date-picker-cell-content',
        disabled && 'date-picker-cell-disabled',
        isToday && `border border-${color}-${colorLevel}`,
        weekend && !disabled && 'date-picker-cell-weekend',
        outOfMonth && !disabled && 'date-picker-other-month',
        outOfMonth && hideOutOfMonthDates && 'd-none',
        !outOfMonth && !disabled && !selected && 'date-picker-cell-current-month',
        !disabled && !selected && !inRange && 'date-picker-cell-hoverable',
        selected && !disabled && `bg-${color}-${colorLevel} text-gray-100`,
        inRange && !disabled && !firstInRange && !lastInRange && !selected && `bg-${color}-${colorLevel} bg-opacity-10`,
        !inRange && !firstInRange && !lastInRange && 'rounded-lg',
        firstInRange && !disabled && 'rounded-l-lg',
        lastInRange && !disabled && 'rounded-r-lg',
        selected && 'date-picker-selected-date',
        className,
      )}>
      {typeof renderDay === 'function' ? renderDay(value, null) : value?.getDate()}
    </button>
  )
})

export default Day
