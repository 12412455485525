import { toastDefaultProps, getToastWrapperInstance, ToastWrapperProps } from './ToastWrapper'

const defaultWrapperId = 'default'
const wrappers = new Map()

function castPlacement(placement: string) {
  if (/\top\b/.test(placement)) {
    return 'top-full'
  }

  if (/\bottom\b/.test(placement)) {
    return 'bottom-full'
  }
}

async function createWrapper(
  wrapperId: string,
  props: ToastWrapperProps & { wrapper?: HTMLElement | (() => HTMLElement) },
) {
  const [wrapper] = await getToastWrapperInstance(props)

  wrappers.set(wrapperId || defaultWrapperId, wrapper)

  return wrapper
}

function getWrapper(wrapperId: string) {
  if (wrappers.size === 0) {
    return null
  }
  return wrappers.get(wrapperId || defaultWrapperId)
}

const toast = (
  message: string | JSX.Element,
  options: ToastWrapperProps & { wrapper?: HTMLElement | (() => HTMLElement) | undefined },
) => {
  toast.push(message, options)
}

toast.push = (
  message: string | JSX.Element,
  options: ToastWrapperProps & { wrapper?: HTMLElement | (() => HTMLElement) | undefined } = toastDefaultProps,
) => {
  let id: string | undefined = options.placement
  if (options.block) {
    id = castPlacement(options.placement || 'top-end')
  }

  let wrapper = getWrapper(id || defaultWrapperId)

  if (wrapper?.current) {
    return wrapper.current.push(message)
  }

  return createWrapper(id ?? '', options).then((ref) => {
    if (ref?.current && 'push' in ref?.current && typeof ref?.current?.push === 'function') {
      return ref?.current?.push(message)
    }
  })
}

toast.remove = (key: string) => {
  wrappers.forEach((elm) => elm.current.remove(key))
}

toast.removeAll = () => {
  wrappers.forEach((elm) => elm.current.removeAll())
}

export default toast
