import React from 'react'
import NotificationDropdownButton from '../../../components/layout/components/Header/HeaderItem/NotificationDropdown/NotificationDropdownButton'

const NotificationDropdownButtonExample = () => {
  const [selectedIndex, setSelectedIndex] = React.useState<number>(-1)

  return (
    <div className={'flex m-5 gap-5'}>
      <NotificationDropdownButton selected={selectedIndex === 0} onClick={() => setSelectedIndex(0)} />
      <NotificationDropdownButton unreadCount={5} selected={selectedIndex === 1} onClick={() => setSelectedIndex(1)} />
      <NotificationDropdownButton unreadCount={35} selected={selectedIndex === 2} onClick={() => setSelectedIndex(2)} />
      <NotificationDropdownButton
        unreadCount={105}
        selected={selectedIndex === 3}
        onClick={() => setSelectedIndex(3)}
      />
    </div>
  )
}

export default NotificationDropdownButtonExample
