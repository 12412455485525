import React, { ForwardedRef, forwardRef, ReactNode } from 'react'
import { useTabs } from './context'

type TabContentProps = {
  value: string
  children?: ReactNode
  className?: string
}

const TabContent = forwardRef(
  ({ value, children, className, ...rest }: TabContentProps, ref: ForwardedRef<HTMLDivElement>) => {
    const context = useTabs()
    const isSelected = value === context.value
    return (
      <div role="tabpanel" tabIndex={0} className={className} ref={ref} {...rest}>
        {isSelected && children}
      </div>
    )
  },
)

export default TabContent
