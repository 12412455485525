import React, { FC } from 'react'
import Button from '../../../components/ui/Button'
import Icon from '../../../components/ui/Icon'

const IconComponent = <Icon name={'placeholder'} />
const IconComponentSolid = <Icon name={'placeholder'} color={'white'} />

const ButtonExample: FC = () => {
  return (
    <div style={{ padding: 40, display: 'flex', flexDirection: 'column', gap: 40 }}>
      <div
        style={{ marginTop: 40, marginBottom: 40, display: 'flex', flexDirection: 'row', gap: 20, flexWrap: 'wrap' }}>
        <Button
          label={'Button'}
          variant={'solid'}
          size={'xs'}
          shape={'rounded'}
          icon={IconComponentSolid}
          disabled={false}
        />
        <Button
          label={'Button'}
          variant={'default'}
          size={'xs'}
          shape={'rounded'}
          iconLocation={'right'}
          icon={IconComponent}
          disabled={false}
        />
        <Button
          label={'Button'}
          variant={'plain'}
          size={'xs'}
          shape={'rounded'}
          icon={IconComponent}
          disabled={false}
        />
        <Button
          label={'Button'}
          variant={'solid'}
          size={'xs'}
          shape={'rounded'}
          icon={IconComponentSolid}
          disabled={true}
        />
        <Button
          label={'Button'}
          variant={'default'}
          size={'xs'}
          shape={'rounded'}
          icon={IconComponent}
          disabled={true}
        />
        <Button label={'Button'} variant={'plain'} size={'xs'} shape={'rounded'} icon={IconComponent} disabled={true} />
        <Button
          label={'Button'}
          variant={'solid'}
          size={'xs'}
          shape={'circle'}
          icon={IconComponentSolid}
          disabled={false}
        />
        <Button
          label={'Button'}
          variant={'default'}
          size={'xs'}
          shape={'circle'}
          icon={IconComponent}
          disabled={false}
        />
        <Button label={'Button'} variant={'plain'} size={'xs'} shape={'circle'} icon={IconComponent} disabled={false} />
        <Button
          label={'Button'}
          variant={'solid'}
          size={'xs'}
          shape={'circle'}
          icon={IconComponentSolid}
          disabled={true}
        />
        <Button
          label={'Button'}
          variant={'default'}
          size={'xs'}
          shape={'circle'}
          icon={IconComponent}
          disabled={true}
        />
        <Button label={'Button'} variant={'plain'} size={'xs'} shape={'circle'} icon={IconComponent} disabled={true} />
        <Button
          label={'Button'}
          variant={'solid'}
          size={'xs'}
          shape={'circle'}
          icon={IconComponentSolid}
          loading={true}
          disabled={true}
        />
        <Button shape={'rounded'} size={'xs'} icon={IconComponent} />
        <Button shape={'circle'} icon={IconComponentSolid} size={'xs'} variant={'solid'} />
      </div>
      <div
        style={{ marginTop: 40, marginBottom: 40, display: 'flex', flexDirection: 'row', gap: 20, flexWrap: 'wrap' }}>
        <Button
          label={'Button'}
          variant={'solid'}
          size={'sm'}
          shape={'rounded'}
          icon={IconComponentSolid}
          disabled={false}
        />
        <Button
          label={'Button'}
          variant={'default'}
          size={'sm'}
          shape={'rounded'}
          icon={IconComponent}
          disabled={false}
        />
        <Button
          label={'Button'}
          variant={'plain'}
          size={'sm'}
          shape={'rounded'}
          icon={IconComponent}
          disabled={false}
        />
        <Button
          label={'Button'}
          variant={'solid'}
          size={'sm'}
          shape={'rounded'}
          icon={IconComponentSolid}
          disabled={true}
        />
        <Button
          label={'Button'}
          variant={'default'}
          size={'sm'}
          shape={'rounded'}
          icon={IconComponent}
          disabled={true}
        />
        <Button label={'Button'} variant={'plain'} size={'sm'} shape={'rounded'} icon={IconComponent} disabled={true} />
        <Button
          label={'Button'}
          variant={'solid'}
          size={'sm'}
          shape={'circle'}
          icon={IconComponentSolid}
          disabled={false}
        />
        <Button
          label={'Button'}
          variant={'default'}
          size={'sm'}
          shape={'circle'}
          icon={IconComponent}
          disabled={false}
        />
        <Button label={'Button'} variant={'plain'} size={'sm'} shape={'circle'} icon={IconComponent} disabled={false} />
        <Button
          label={'Button'}
          variant={'solid'}
          size={'sm'}
          shape={'circle'}
          icon={IconComponentSolid}
          disabled={true}
        />
        <Button
          label={'Button'}
          variant={'default'}
          size={'sm'}
          shape={'circle'}
          icon={IconComponent}
          disabled={true}
        />
        <Button label={'Button'} variant={'plain'} size={'sm'} shape={'circle'} icon={IconComponent} disabled={true} />
        <Button
          label={'Button'}
          variant={'solid'}
          size={'sm'}
          shape={'circle'}
          icon={IconComponentSolid}
          loading={true}
          disabled={true}
        />
        <Button shape={'rounded'} size={'sm'} icon={IconComponent} />
        <Button shape={'circle'} loading={true} icon={IconComponentSolid} size={'sm'} variant={'solid'} />
      </div>
      <div
        style={{ marginTop: 40, marginBottom: 40, display: 'flex', flexDirection: 'row', gap: 20, flexWrap: 'wrap' }}>
        <Button
          label={'Button'}
          variant={'solid'}
          size={'md'}
          shape={'rounded'}
          icon={IconComponentSolid}
          disabled={false}
        />
        <Button
          label={'Button'}
          variant={'default'}
          size={'md'}
          shape={'rounded'}
          icon={IconComponent}
          block={true}
          disabled={false}
        />
        <Button
          label={'Button'}
          variant={'plain'}
          size={'md'}
          shape={'rounded'}
          icon={IconComponent}
          disabled={false}
        />
        <Button
          label={'Button'}
          variant={'solid'}
          size={'md'}
          shape={'rounded'}
          icon={IconComponentSolid}
          disabled={true}
        />
        <Button
          label={'Button'}
          variant={'default'}
          size={'md'}
          shape={'rounded'}
          icon={IconComponent}
          disabled={true}
        />
        <Button label={'Button'} variant={'plain'} size={'md'} shape={'rounded'} icon={IconComponent} disabled={true} />
        <Button
          label={'Button'}
          variant={'solid'}
          size={'md'}
          shape={'circle'}
          icon={IconComponentSolid}
          disabled={false}
        />
        <Button
          label={'Button'}
          variant={'default'}
          size={'md'}
          shape={'circle'}
          icon={IconComponent}
          disabled={false}
        />
        <Button label={'Button'} variant={'plain'} size={'md'} shape={'circle'} icon={IconComponent} disabled={false} />
        <Button
          label={'Button'}
          variant={'solid'}
          size={'md'}
          shape={'circle'}
          icon={IconComponentSolid}
          disabled={true}
        />
        <Button
          label={'Button'}
          variant={'default'}
          size={'md'}
          shape={'circle'}
          icon={IconComponent}
          disabled={true}
        />
        <Button label={'Button'} variant={'plain'} size={'md'} shape={'circle'} icon={IconComponent} disabled={true} />
        <Button
          label={'Button'}
          variant={'solid'}
          size={'md'}
          shape={'circle'}
          icon={IconComponentSolid}
          loading={true}
          disabled={true}
          block={true}
        />
        <Button shape={'rounded'} size={'md'} icon={IconComponent} />
        <Button shape={'circle'} icon={IconComponentSolid} size={'md'} variant={'solid'} />
      </div>
      <div
        style={{ marginTop: 40, marginBottom: 40, display: 'flex', flexDirection: 'row', gap: 20, flexWrap: 'wrap' }}>
        <Button
          label={'Button'}
          variant={'solid'}
          size={'lg'}
          shape={'rounded'}
          icon={IconComponentSolid}
          disabled={false}
        />
        <Button
          label={'Button'}
          variant={'default'}
          size={'lg'}
          shape={'rounded'}
          icon={IconComponent}
          disabled={false}
        />
        <Button
          label={'Button'}
          variant={'plain'}
          size={'lg'}
          shape={'rounded'}
          icon={IconComponent}
          disabled={false}
        />
        <Button
          label={'Button'}
          variant={'solid'}
          size={'lg'}
          shape={'rounded'}
          icon={IconComponentSolid}
          disabled={true}
        />
        <Button label={'Button'} variant={'default'} size={'lg'} shape={'rounded'} disabled={true} />
        <Button label={'Button'} variant={'plain'} size={'lg'} shape={'rounded'} icon={IconComponent} disabled={true} />
        <Button
          label={'Button'}
          variant={'solid'}
          size={'lg'}
          shape={'circle'}
          icon={IconComponentSolid}
          disabled={false}
        />
        <Button
          label={'Button'}
          variant={'default'}
          size={'lg'}
          shape={'circle'}
          icon={IconComponent}
          disabled={false}
        />
        <Button label={'Button'} variant={'plain'} size={'lg'} shape={'circle'} icon={IconComponent} disabled={false} />
        <Button
          label={'Button'}
          variant={'solid'}
          size={'lg'}
          shape={'circle'}
          icon={IconComponentSolid}
          disabled={true}
        />
        <Button
          label={'Button'}
          variant={'default'}
          size={'lg'}
          shape={'circle'}
          icon={IconComponent}
          iconLocation={'right'}
          disabled={true}
        />
        <Button label={'Button'} variant={'plain'} size={'lg'} shape={'circle'} icon={IconComponent} disabled={true} />
        <Button label={'Button'} variant={'solid'} size={'lg'} shape={'circle'} loading={true} disabled={true} />
        <Button size={'lg'} shape={'rounded'} icon={IconComponent} />
        <Button size={'lg'} shape={'circle'} icon={IconComponentSolid} variant={'solid'} />
      </div>
      <div
        style={{ marginTop: 40, marginBottom: 40, display: 'flex', flexDirection: 'row', gap: 20, flexWrap: 'wrap' }}>
        <Button
          label={'Button'}
          variant={'solid'}
          size={'lg'}
          shape={'rounded'}
          icon={IconComponentSolid}
          disabled={false}
          color={'orange'}
        />
        <Button
          label={'Button'}
          variant={'default'}
          size={'lg'}
          shape={'rounded'}
          icon={IconComponent}
          disabled={false}
          color={'orange'}
        />
        <Button
          label={'Button'}
          variant={'plain'}
          size={'lg'}
          shape={'rounded'}
          icon={IconComponent}
          disabled={false}
          color={'orange'}
        />
        <Button
          label={'Button'}
          variant={'solid'}
          size={'lg'}
          shape={'rounded'}
          icon={IconComponentSolid}
          disabled={true}
          color={'orange'}
          loading={true}
        />
        <Button label={'Button'} variant={'default'} size={'lg'} shape={'rounded'} disabled={true} color={'orange'} />
        <Button
          label={'Button'}
          variant={'plain'}
          size={'lg'}
          shape={'rounded'}
          icon={IconComponent}
          disabled={true}
          color={'orange'}
        />
        <Button
          label={'Button'}
          variant={'solid'}
          size={'lg'}
          shape={'circle'}
          icon={IconComponentSolid}
          disabled={false}
          color={'orange'}
        />
        <Button
          label={'Button'}
          variant={'default'}
          size={'lg'}
          shape={'circle'}
          icon={IconComponent}
          disabled={false}
          color={'orange'}
        />
        <Button
          label={'Button'}
          variant={'plain'}
          size={'lg'}
          shape={'circle'}
          icon={IconComponent}
          disabled={false}
          color={'orange'}
        />
        <Button
          label={'Button'}
          variant={'solid'}
          size={'lg'}
          shape={'circle'}
          icon={IconComponentSolid}
          disabled={true}
          color={'orange'}
        />
        <Button
          label={'Button'}
          variant={'default'}
          size={'lg'}
          shape={'circle'}
          icon={IconComponent}
          iconLocation={'right'}
          disabled={true}
          color={'orange'}
        />
        <Button
          label={'Button'}
          variant={'plain'}
          size={'lg'}
          shape={'circle'}
          icon={IconComponent}
          disabled={true}
          color={'orange'}
        />
        <Button
          label={'Button'}
          variant={'solid'}
          size={'lg'}
          shape={'circle'}
          loading={true}
          disabled={true}
          color={'orange'}
        />
        <Button size={'lg'} shape={'rounded'} icon={IconComponent} color={'orange'} />
        <Button size={'lg'} shape={'circle'} icon={IconComponentSolid} variant={'solid'} color={'orange'} />
      </div>
    </div>
  )
}

export default ButtonExample
