import React, { useState } from 'react'
import { Button, Form, Input, Checkbox, Alert } from '../../../components/ui'
import { Field, Formik } from 'formik'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'
import useSignIn from '../../../hooks/useSignIn'
import { useApolloClient } from '@apollo/client'

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email address').required('Email is required'),
  password: Yup.string().required('Password is required'),
})

const SignInForm = () => {
  const navigate = useNavigate()
  const [alertType, setAlertType] = useState<'warning' | 'danger' | 'info'>('info')
  const [alertMessage, setAlertMessage] = useState<string | null>(null)

  const handleCloseAlert = () => {
    setAlertMessage(null)
  }

  const signIn = useSignIn()

  const client = useApolloClient()
  const serializedState = client.cache.extract()
  console.log(serializedState)

  return (
    <Formik
      initialValues={{ email: '', password: '', staySignedIn: false }}
      validationSchema={validationSchema}
      onSubmit={async ({ email, password, staySignedIn }, { setSubmitting }) => {
        const res = await signIn({
          email,
          password,
          staySignedIn,
        })
        setSubmitting(false)
        if (res.status !== 'success') {
          setAlertMessage(res.message)
          if (res.status === 'invalid') {
            setAlertType('warning')
          } else {
            setAlertType('danger')
          }
        } else {
          setAlertType('info')
          setAlertMessage(null)
        }
      }}>
      {({ isSubmitting, errors, touched }) => (
        <div className="w-full max-w-[319px] self-center justify-self-center">
          <Form.Container>
            <h2 className={'m-0'}>Sign In</h2>
            <Form.Item
              size={'sm'}
              errorMessage={touched.email && errors.email ? errors.email : undefined}
              invalid={touched.email && !!errors.email}>
              <Field type={'email'} size={'sm'} name={'email'} placeholder={'Email'} component={Input} />
            </Form.Item>
            <Form.Item
              size={'sm'}
              errorMessage={touched.password && errors.password ? errors.password : undefined}
              invalid={touched.password && !!errors.password}>
              <Field type={'password'} size={'sm'} name={'password'} placeholder={'Password'} component={Input} />
            </Form.Item>
            <Form.Item size={'sm'}>
              <div className="flex items-center">
                <Field name={'staySignedIn'} size={'sm'} label={'Stay signed in'} component={Checkbox} />
              </div>
            </Form.Item>
            <div className="h-[4px]" />
            <Alert show={!!alertMessage} label={alertMessage} type={alertType} onClose={handleCloseAlert} />
            <Form.Actions layout={'vertical'}>
              <Button type={'submit'} variant={'solid'} block={true} size={'sm'} loading={isSubmitting}>
                Sign In
              </Button>
              <Button block={true} size={'sm'} onClick={() => navigate('/forgot-password')}>
                Forgot password?
              </Button>
            </Form.Actions>
            <div>
              Don't have an account?{' '}
              <span onClick={() => navigate('/sign-up')} className="text-breakerBay cursor-pointer hover:underline">
                Sign up
              </span>
            </div>
          </Form.Container>
        </div>
      )}
    </Formik>
  )
}

export default SignInForm
