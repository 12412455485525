import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never }
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  DateTime: { input: any; output: any }
}

export type Auth = {
  __typename?: 'Auth'
  me: Me
  token: Scalars['String']['output']
}

export type Image = {
  __typename?: 'Image'
  src: Scalars['String']['output']
}

export type LoginInput = {
  email: Scalars['String']['input']
  password: Scalars['String']['input']
}

export type Me = User & {
  __typename?: 'Me'
  avatar?: Maybe<Image>
  email: Scalars['String']['output']
  firstName: Scalars['String']['output']
  id: Scalars['String']['output']
  lastName: Scalars['String']['output']
  lastViewedAs?: Maybe<ViewAs>
  lastViewedProgramId?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  roles: Array<Role>
  viewingAs?: Maybe<ViewAs>
  viewingProgramId?: Maybe<Scalars['String']['output']>
}

export type Mutation = {
  __typename?: 'Mutation'
  login: Auth
  upsertNotifications: Array<Notification>
}

export type MutationLoginArgs = {
  data: LoginInput
}

export type MutationUpsertNotificationsArgs = {
  data: UpsertNotificationsInput
}

export type Notification = {
  __typename?: 'Notification'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['String']['output']
  person?: Maybe<Person>
  text: Scalars['String']['output']
  title: Scalars['String']['output']
  type: NotificationType
  viewed: Scalars['Boolean']['output']
}

export type NotificationInput = {
  programId: Scalars['String']['input']
  viewingAs: ViewAs
}

export enum NotificationType {
  Error = 'error',
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
}

export type Person = {
  __typename?: 'Person'
  avatar?: Maybe<Image>
  firstName: Scalars['String']['output']
  id: Scalars['String']['output']
  lastName?: Maybe<Scalars['String']['output']>
}

export type Process = {
  __typename?: 'Process'
  name: Scalars['String']['output']
}

export type Program = {
  __typename?: 'Program'
  icon?: Maybe<Image>
  id: Scalars['String']['output']
  name: Scalars['String']['output']
  process: Process
  tenant: Tenant
}

export type Query = {
  __typename?: 'Query'
  me: Me
  notifications: Array<Notification>
  programs: Array<Program>
  slides: Array<Slide>
}

export type QueryNotificationsArgs = {
  data: NotificationInput
}

export type QuerySlidesArgs = {
  data: SlideInput
}

export enum Role {
  Admin = 'admin',
  Apprentice = 'apprentice',
  Leader = 'leader',
  Owner = 'owner',
}

export type Slide = {
  __typename?: 'Slide'
  image?: Maybe<Image>
  slider: Slider
  subtitle?: Maybe<Scalars['String']['output']>
  text?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export type SlideInput = {
  slider: Slider
}

export enum Slider {
  Auth = 'auth',
}

export type Tenant = {
  __typename?: 'Tenant'
  id: Scalars['String']['output']
  name: Scalars['String']['output']
}

export type UpsertNotificationInput = {
  id: Scalars['String']['input']
  viewed: Scalars['Boolean']['input']
}

export type UpsertNotificationsInput = {
  notifications: Array<UpsertNotificationInput>
  programId: Scalars['String']['input']
  viewingAs: ViewAs
}

export type User = {
  avatar?: Maybe<Image>
  email: Scalars['String']['output']
  firstName: Scalars['String']['output']
  id: Scalars['String']['output']
  lastName: Scalars['String']['output']
  name: Scalars['String']['output']
  roles: Array<Role>
}

export enum ViewAs {
  Admin = 'admin',
  Apprentice = 'apprentice',
  Leader = 'leader',
}

export type UserFieldsFragment = {
  __typename?: 'Me'
  id: string
  name: string
  firstName: string
  lastName: string
  email: string
  roles: Array<Role>
  avatar?: { __typename?: 'Image'; src: string } | null
}

export type AuthFieldsFragment = {
  __typename?: 'Auth'
  token: string
  me: {
    __typename?: 'Me'
    id: string
    name: string
    firstName: string
    lastName: string
    email: string
    roles: Array<Role>
    lastViewedAs?: ViewAs | null
    viewingAs?: ViewAs | null
    lastViewedProgramId?: string | null
    viewingProgramId?: string | null
    avatar?: { __typename?: 'Image'; src: string } | null
  }
}

export type PersonFieldsFragment = {
  __typename?: 'Person'
  id: string
  firstName: string
  lastName?: string | null
  avatar?: { __typename?: 'Image'; src: string } | null
}

export type NotificationFieldsFragment = {
  __typename?: 'Notification'
  id: string
  title: string
  text: string
  type: NotificationType
  viewed: boolean
  createdAt: any
  person?: {
    __typename?: 'Person'
    id: string
    firstName: string
    lastName?: string | null
    avatar?: { __typename?: 'Image'; src: string } | null
  } | null
}

export type ProgramFieldsFragment = {
  __typename?: 'Program'
  id: string
  name: string
  process: { __typename?: 'Process'; name: string }
  icon?: { __typename?: 'Image'; src: string } | null
  tenant: { __typename?: 'Tenant'; id: string; name: string }
}

export type LoginMutationVariables = Exact<{
  data: LoginInput
}>

export type LoginMutation = {
  __typename?: 'Mutation'
  login: {
    __typename?: 'Auth'
    token: string
    me: {
      __typename?: 'Me'
      id: string
      name: string
      firstName: string
      lastName: string
      email: string
      roles: Array<Role>
      lastViewedAs?: ViewAs | null
      viewingAs?: ViewAs | null
      lastViewedProgramId?: string | null
      viewingProgramId?: string | null
      avatar?: { __typename?: 'Image'; src: string } | null
    }
  }
}

export type UpsertNotificationsMutationVariables = Exact<{
  data: UpsertNotificationsInput
}>

export type UpsertNotificationsMutation = {
  __typename?: 'Mutation'
  upsertNotifications: Array<{
    __typename?: 'Notification'
    id: string
    title: string
    text: string
    type: NotificationType
    viewed: boolean
    createdAt: any
    person?: {
      __typename?: 'Person'
      id: string
      firstName: string
      lastName?: string | null
      avatar?: { __typename?: 'Image'; src: string } | null
    } | null
  }>
}

export type SlidesQueryVariables = Exact<{
  data: SlideInput
}>

export type SlidesQuery = {
  __typename?: 'Query'
  slides: Array<{
    __typename?: 'Slide'
    title?: string | null
    subtitle?: string | null
    text?: string | null
    image?: { __typename?: 'Image'; src: string } | null
  }>
}

export type MeQueryVariables = Exact<{ [key: string]: never }>

export type MeQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'Me'
    lastViewedProgramId?: string | null
    viewingProgramId?: string | null
    viewingAs?: ViewAs | null
    id: string
    name: string
    firstName: string
    lastName: string
    email: string
    roles: Array<Role>
    avatar?: { __typename?: 'Image'; src: string } | null
  }
}

export type NotificationsQueryVariables = Exact<{
  data: NotificationInput
}>

export type NotificationsQuery = {
  __typename?: 'Query'
  notifications: Array<{
    __typename?: 'Notification'
    id: string
    title: string
    viewed: boolean
    text: string
    type: NotificationType
    createdAt: any
    person?: {
      __typename?: 'Person'
      id: string
      firstName: string
      lastName?: string | null
      avatar?: { __typename?: 'Image'; src: string } | null
    } | null
  }>
}

export type ProgramsQueryVariables = Exact<{ [key: string]: never }>

export type ProgramsQuery = {
  __typename?: 'Query'
  programs: Array<{
    __typename?: 'Program'
    id: string
    name: string
    process: { __typename?: 'Process'; name: string }
    icon?: { __typename?: 'Image'; src: string } | null
    tenant: { __typename?: 'Tenant'; id: string; name: string }
  }>
}

export const UserFieldsFragmentDoc = gql`
  fragment userFields on User {
    id
    name
    firstName
    lastName
    email
    roles
    avatar {
      src
    }
  }
`
export const AuthFieldsFragmentDoc = gql`
  fragment authFields on Auth {
    token
    me {
      id
      name
      firstName
      lastName
      email
      roles
      lastViewedAs
      viewingAs @client
      lastViewedProgramId
      viewingProgramId @client
      avatar {
        src
      }
    }
  }
`
export const PersonFieldsFragmentDoc = gql`
  fragment personFields on Person {
    id
    firstName
    lastName
    avatar {
      src
    }
  }
`
export const NotificationFieldsFragmentDoc = gql`
  fragment notificationFields on Notification {
    id
    title
    text
    type
    viewed
    createdAt
    person {
      ...personFields
    }
  }
  ${PersonFieldsFragmentDoc}
`
export const ProgramFieldsFragmentDoc = gql`
  fragment programFields on Program {
    id
    name
    process {
      name
    }
    icon {
      src
    }
    tenant {
      id
      name
    }
  }
`
export const LoginDocument = gql`
  mutation Login($data: LoginInput!) {
    login(data: $data) {
      ...authFields
    }
  }
  ${AuthFieldsFragmentDoc}
`
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options)
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>
export const UpsertNotificationsDocument = gql`
  mutation UpsertNotifications($data: UpsertNotificationsInput!) {
    upsertNotifications(data: $data) {
      ...notificationFields
    }
  }
  ${NotificationFieldsFragmentDoc}
`
export type UpsertNotificationsMutationFn = Apollo.MutationFunction<
  UpsertNotificationsMutation,
  UpsertNotificationsMutationVariables
>

/**
 * __useUpsertNotificationsMutation__
 *
 * To run a mutation, you first call `useUpsertNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertNotificationsMutation, { data, loading, error }] = useUpsertNotificationsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpsertNotificationsMutation(
  baseOptions?: Apollo.MutationHookOptions<UpsertNotificationsMutation, UpsertNotificationsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpsertNotificationsMutation, UpsertNotificationsMutationVariables>(
    UpsertNotificationsDocument,
    options,
  )
}
export type UpsertNotificationsMutationHookResult = ReturnType<typeof useUpsertNotificationsMutation>
export type UpsertNotificationsMutationResult = Apollo.MutationResult<UpsertNotificationsMutation>
export type UpsertNotificationsMutationOptions = Apollo.BaseMutationOptions<
  UpsertNotificationsMutation,
  UpsertNotificationsMutationVariables
>
export const SlidesDocument = gql`
  query Slides($data: SlideInput!) {
    slides(data: $data) {
      title
      subtitle
      text
      image {
        src
      }
    }
  }
`

/**
 * __useSlidesQuery__
 *
 * To run a query within a React component, call `useSlidesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSlidesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSlidesQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSlidesQuery(baseOptions: Apollo.QueryHookOptions<SlidesQuery, SlidesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SlidesQuery, SlidesQueryVariables>(SlidesDocument, options)
}
export function useSlidesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SlidesQuery, SlidesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SlidesQuery, SlidesQueryVariables>(SlidesDocument, options)
}
export function useSlidesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<SlidesQuery, SlidesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<SlidesQuery, SlidesQueryVariables>(SlidesDocument, options)
}
export type SlidesQueryHookResult = ReturnType<typeof useSlidesQuery>
export type SlidesLazyQueryHookResult = ReturnType<typeof useSlidesLazyQuery>
export type SlidesSuspenseQueryHookResult = ReturnType<typeof useSlidesSuspenseQuery>
export type SlidesQueryResult = Apollo.QueryResult<SlidesQuery, SlidesQueryVariables>
export const MeDocument = gql`
  query Me {
    me {
      ...userFields
      lastViewedProgramId
      viewingProgramId @client
      viewingAs @client
    }
  }
  ${UserFieldsFragmentDoc}
`

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options)
}
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options)
}
export function useMeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<MeQuery, MeQueryVariables>(MeDocument, options)
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>
export type MeSuspenseQueryHookResult = ReturnType<typeof useMeSuspenseQuery>
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>
export const NotificationsDocument = gql`
  query Notifications($data: NotificationInput!) {
    notifications(data: $data) {
      id
      title
      viewed
      text
      type
      person {
        id
        firstName
        lastName
        avatar {
          src
        }
      }
      createdAt
    }
  }
`

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useNotificationsQuery(
  baseOptions: Apollo.QueryHookOptions<NotificationsQuery, NotificationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options)
}
export function useNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<NotificationsQuery, NotificationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options)
}
export function useNotificationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<NotificationsQuery, NotificationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options)
}
export type NotificationsQueryHookResult = ReturnType<typeof useNotificationsQuery>
export type NotificationsLazyQueryHookResult = ReturnType<typeof useNotificationsLazyQuery>
export type NotificationsSuspenseQueryHookResult = ReturnType<typeof useNotificationsSuspenseQuery>
export type NotificationsQueryResult = Apollo.QueryResult<NotificationsQuery, NotificationsQueryVariables>
export const ProgramsDocument = gql`
  query Programs {
    programs {
      ...programFields
    }
  }
  ${ProgramFieldsFragmentDoc}
`

/**
 * __useProgramsQuery__
 *
 * To run a query within a React component, call `useProgramsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProgramsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProgramsQuery(baseOptions?: Apollo.QueryHookOptions<ProgramsQuery, ProgramsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ProgramsQuery, ProgramsQueryVariables>(ProgramsDocument, options)
}
export function useProgramsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProgramsQuery, ProgramsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ProgramsQuery, ProgramsQueryVariables>(ProgramsDocument, options)
}
export function useProgramsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ProgramsQuery, ProgramsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ProgramsQuery, ProgramsQueryVariables>(ProgramsDocument, options)
}
export type ProgramsQueryHookResult = ReturnType<typeof useProgramsQuery>
export type ProgramsLazyQueryHookResult = ReturnType<typeof useProgramsLazyQuery>
export type ProgramsSuspenseQueryHookResult = ReturnType<typeof useProgramsSuspenseQuery>
export type ProgramsQueryResult = Apollo.QueryResult<ProgramsQuery, ProgramsQueryVariables>
