import React from 'react'
import LargeVertical from './LargeVertical'
import LargeHorizontal from './LargeHorizontal'
import MediumVertical from './MediumVertical'
import MediumHorizontal from './MediumHorizontal'
import SmallVertical from './SmallVertical'
import SmallHorizontal from './SmallHorizontal'

const FormExample = () => {
  return (
    <div className={'flex'}>
      <div className={'w-600'}>
        <div className={'mt-10'}>
          <LargeVertical />
        </div>
        <div className={'mt-10'}>
          <LargeHorizontal />
        </div>
      </div>
      <div className={'w-500'}>
        <div className={'mt-10'}>
          <MediumVertical />
        </div>
        <div className={'mt-10'}>
          <MediumHorizontal />
        </div>
      </div>
      <div className={'w-400'}>
        <div className={'mt-10'}>
          <SmallVertical />
        </div>
        <div className={'mt-10'}>
          <SmallHorizontal />
        </div>
      </div>
    </div>
  )
}

export default FormExample
