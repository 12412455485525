import React from 'react'

type TotalProps = {
  total?: number
}

const Total = ({ total = 0 }: TotalProps) => {
  return <h6 className="m-0 mt-0.5 text-gray-dark">Total {total} Items</h6>
}

export default Total
