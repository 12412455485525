import React from 'react'
import Avatar from '../../../../../ui/Avatar'
import Label from '../../../../../ui/Label'
import useAuth from '../../../../../../hooks/useAuth'
import { ViewAs } from '../../../../../../graphql'

const UserDropdownHeader = () => {
  const { user } = useAuth()
  const labelColor =
    user?.viewingAs === ViewAs.Admin ? 'silver-400' : user?.viewingAs === ViewAs.Leader ? 'gold-400' : 'bronze-400'
  const label = user?.viewingAs?.toString()
  return (
    <div className={'flex flex-row justify-start pt-[8px] items-center'}>
      <Avatar shape="circle" size={'lg'} name={user?.name} src={user?.avatar?.src} />
      <div className={'ml-[16px]'}>
        <h4 className={'m-0'}>{user?.firstName}</h4>
        <p className={'body-sm text-[#838383]'}>{user?.email}</p>
        <Label label={label} color={labelColor} />
      </div>
    </div>
  )
}

export default UserDropdownHeader
