import { createSelector, createSlice } from '@reduxjs/toolkit'

// Todo: add states for drawer and side peak components (whether they are open or closed, want content should be in the drawer etc.)

export type LayoutState = {}

export const initialState: LayoutState = {}

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {},
})

export const selectLayout = createSelector(
  (state: { layout: LayoutState }) => state.layout,
  (layout) => layout,
)

export default layoutSlice.reducer
