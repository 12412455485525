import React, { lazy, Suspense, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import View from './components/View'
import useAuth from '../../hooks/useAuth'
import { Role } from '../../graphql'

const Layout = () => {
  const { pathname } = useLocation()

  const { authenticated, user } = useAuth()

  const viewingAs = user?.viewingAs
  const roles = user?.roles

  const AppLayout = useMemo(() => {
    const pathParts = pathname.split('/')
    let noLayout = false
    if (pathParts.length > 1 && pathParts[1] === 'dev') {
      noLayout = true
    }
    if (!noLayout) {
      if (!authenticated) {
        return lazy(() => import('./AuthLayout'))
      }
      let layout: Role | undefined
      if (roles?.includes(Role.Apprentice)) {
        layout = Role.Apprentice
      }
      if (roles?.includes(Role.Leader)) {
        if (viewingAs === 'leader' || viewingAs === undefined) {
          layout = Role.Leader
        }
      }
      if (roles?.includes(Role.Admin)) {
        if (viewingAs === 'admin' || viewingAs === undefined) {
          layout = Role.Admin
        }
      }
      if (layout) {
        switch (layout) {
          case Role.Apprentice:
            return lazy(() => import('./ApprenticeLayout'))
          case Role.Leader:
            return lazy(() => import('./LeaderLayout'))
          case Role.Admin:
            return lazy(() => import('./AdminLayout'))
        }
      }
    }
    return View
  }, [authenticated, roles, viewingAs, pathname])

  return (
    // Todo: Replace this with a Loading component once it is built
    <Suspense fallback={<div className="flex flex-auto flex-col h-[100vh]"></div>}>
      <AppLayout />
    </Suspense>
  )
}

export default Layout
