import React, { ChangeEvent, FocusEvent } from 'react'
import Input from '../../../components/ui/Input'
import UserIcon from '../../../assets/user-icon.svg'

const placeholder = 'The quick brown fox jumps over the lazy dog'
const InputExample = () => {
  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    console.log('Input Value:', e.target.value)
  }

  const handleInputFocus = (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    console.log('Input Focused')
  }

  return (
    <div className="p-6 flex h-screen">
      <div className="flex-grow">
        <h3>Large</h3>
        <div className="mt-6">
          <Input size={'lg'} placeholder={placeholder} onChange={handleInputChange} onFocus={handleInputFocus} />
        </div>
        <div className="mt-6">
          <Input
            size={'lg'}
            type={'password'}
            placeholder={placeholder}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
        </div>
        <div className="mt-6">
          <Input
            size={'lg'}
            prefix={<img src={UserIcon} alt={'User Icon'} />}
            placeholder={placeholder}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
        </div>
        <div className="mt-6">
          <Input
            size={'lg'}
            prefix={<img src={UserIcon} alt={'User Icon'} />}
            type={'password'}
            placeholder={placeholder}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
        </div>
        <div className="mt-6">
          <Input
            size={'lg'}
            disabled={true}
            placeholder={placeholder}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
        </div>
        <div className="mt-6">
          <Input
            size={'lg'}
            placeholder={placeholder}
            value={'Controlled'}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
        </div>
        <div className="mt-6">
          <Input
            size={'lg'}
            textArea={true}
            placeholder={placeholder}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
        </div>
        <h3>Large Invalid</h3>
        <div className="mt-6">
          <Input
            size={'lg'}
            invalid={true}
            placeholder={placeholder}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
        </div>
        <div className="mt-6">
          <Input
            size={'lg'}
            invalid={true}
            type={'password'}
            placeholder={placeholder}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
        </div>
        <div className="mt-6">
          <Input
            size={'lg'}
            invalid={true}
            prefix={<img src={UserIcon} alt={'User Icon'} />}
            placeholder={placeholder}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
        </div>
        <div className="mt-6">
          <Input
            size={'lg'}
            invalid={true}
            prefix={<img src={UserIcon} alt={'User Icon'} />}
            placeholder={placeholder}
            type={'password'}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
        </div>
        <div className="mt-6">
          <Input
            size={'lg'}
            invalid={true}
            textArea={true}
            placeholder={placeholder}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
        </div>
      </div>
      <div className="flex-grow">
        <h4>Medium</h4>
        <div className="mt-6">
          <Input size={'md'} placeholder={placeholder} onChange={handleInputChange} onFocus={handleInputFocus} />
        </div>
        <div className="mt-6">
          <Input
            size={'md'}
            type={'password'}
            placeholder={placeholder}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
        </div>
        <div className="mt-6">
          <Input
            size={'md'}
            suffix={<img src={UserIcon} alt={'User Icon'} />}
            placeholder={placeholder}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
        </div>
        <div className="mt-6">
          <Input
            size={'md'}
            prefix={<img src={UserIcon} alt={'User Icon'} />}
            type={'password'}
            placeholder={placeholder}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
        </div>
        <div className="mt-6">
          <Input
            size={'md'}
            disabled={true}
            placeholder={placeholder}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
        </div>
        <div className="mt-6">
          <Input
            size={'md'}
            placeholder={placeholder}
            value={'Controlled'}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
        </div>
        <div className="mt-6">
          <Input
            size={'md'}
            textArea={true}
            placeholder={placeholder}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
        </div>
        <h4>Medium Invalid</h4>
        <div className="mt-6">
          <Input
            size={'md'}
            invalid={true}
            placeholder={placeholder}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
        </div>
        <div className="mt-6">
          <Input
            size={'md'}
            invalid={true}
            suffix={<img src={UserIcon} alt={'User Icon'} />}
            placeholder={placeholder}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
        </div>
        <div className="mt-6">
          <Input
            size={'md'}
            invalid={true}
            prefix={<img src={UserIcon} alt={'User Icon'} />}
            placeholder={placeholder}
            type={'password'}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
        </div>
        <div className="mt-6">
          <Input
            size={'md'}
            invalid={true}
            type={'password'}
            placeholder={placeholder}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
        </div>
        <div className="mt-6">
          <Input
            size={'md'}
            invalid={true}
            textArea={true}
            placeholder={placeholder}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
        </div>
      </div>
      <div className="flex-grow">
        <h5>Small</h5>
        <div className="mt-6">
          <Input size={'sm'} placeholder={placeholder} onChange={handleInputChange} onFocus={handleInputFocus} />
        </div>
        <div className="mt-6">
          <Input
            size={'sm'}
            type={'password'}
            placeholder={placeholder}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
        </div>
        <div className="mt-6">
          <Input
            size={'sm'}
            suffix={<img src={UserIcon} alt={'User Icon'} />}
            placeholder={placeholder}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
        </div>
        <div className="mt-6">
          <Input
            size={'sm'}
            prefix={<img src={UserIcon} alt={'User Icon'} />}
            type={'password'}
            placeholder={placeholder}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
        </div>
        <div className="mt-6">
          <Input
            size={'sm'}
            disabled={true}
            placeholder={placeholder}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
        </div>
        <div className="mt-6">
          <Input
            size={'sm'}
            placeholder={placeholder}
            value={'Controlled'}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
        </div>
        <div className="mt-6">
          <Input
            size={'sm'}
            textArea={true}
            placeholder={placeholder}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
        </div>
        <h5>Small Invalid</h5>
        <div className="mt-6">
          <Input
            invalid={true}
            size={'sm'}
            placeholder={placeholder}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
        </div>
        <div className="mt-6">
          <Input
            invalid={true}
            size={'sm'}
            type={'password'}
            placeholder={placeholder}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
        </div>
        <div className="mt-6">
          <Input
            size={'sm'}
            invalid={true}
            suffix={<img src={UserIcon} alt={'User Icon'} />}
            placeholder={placeholder}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
        </div>
        <div className="mt-6">
          <Input
            size={'sm'}
            invalid={true}
            prefix={<img src={UserIcon} alt={'User Icon'} />}
            placeholder={placeholder}
            type={'password'}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
        </div>
        <div className="mt-6">
          <Input
            invalid={true}
            size={'sm'}
            textArea={true}
            placeholder={placeholder}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
        </div>
      </div>
    </div>
  )
}

export default InputExample
