import { AuthFieldsFragmentDoc, LoginInput, MeDocument, Role, useLoginMutation } from '../graphql'
import { REDIRECT_URL_KEY } from '../constants'
import appConfig from '../config/app'
import { useNavigate } from 'react-router-dom'
import useParams from './useParams'
import { persistor } from '../services/ApolloService'
import { ApolloError } from '@apollo/client'

function useSignIn() {
  const navigate = useNavigate()
  const params = useParams()

  const [login] = useLoginMutation({
    update: (cache, { data }) => {
      const lastViewedAs = data?.login.me.lastViewedAs
      let initialViewAs = Role.Apprentice
      if (data?.login.me.roles.includes(Role.Admin)) {
        initialViewAs = Role.Admin
      } else if (data?.login.me.roles.includes(Role.Leader)) {
        initialViewAs = Role.Leader
      }
      const viewingAs = lastViewedAs ? lastViewedAs : initialViewAs

      const viewingProgramId = data?.login.me.lastViewedProgramId

      cache.writeQuery({
        query: MeDocument,
        data: {
          me: {
            ...data?.login.me,
            viewingAs,
            viewingProgramId,
          },
        },
      })
      cache.writeFragment({
        data: {
          ...data?.login,
          me: {
            ...data?.login.me,
            viewingAs,
            viewingProgramId,
          },
        },
        id: 'Auth:{}',
        fragment: AuthFieldsFragmentDoc,
      })
    },
  })

  return async (data: LoginInput & { staySignedIn: boolean }) => {
    let message = ''
    let status: 'failed' | 'success' | 'invalid' = 'success'
    try {
      const { staySignedIn, ...rest } = data
      if (!staySignedIn) {
        persistor.pause()
      }
      const resp = await login({
        variables: {
          data: rest,
        },
      })
      if (resp.data?.login) {
        const lastViewedAs = resp.data.login.me.lastViewedAs
        let initialViewAs = Role.Apprentice
        if (resp.data?.login.me.roles.includes(Role.Admin)) {
          initialViewAs = Role.Admin
        } else if (resp.data?.login.me.roles.includes(Role.Leader)) {
          initialViewAs = Role.Leader
        }
        localStorage.setItem('viewingAs', lastViewedAs ? lastViewedAs : initialViewAs)
        const redirectUrl = params.get(REDIRECT_URL_KEY)
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath)
      }
    } catch (error) {
      message = 'Something went wrong'
      status = 'failed'
      if (error instanceof ApolloError) {
        if (error.graphQLErrors.length > 0) {
          const gqlError = error.graphQLErrors[0]
          message = gqlError.message
          if (gqlError.extensions?.code === 'FORBIDDEN') {
            status = 'invalid'
          }
        }
      } else if (error instanceof Error) {
        message = error.message
      }
    }
    setTimeout(() => persistor.resume(), 1500)
    return {
      status,
      message,
    }
  }
}

export default useSignIn
