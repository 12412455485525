import React, { MouseEvent } from 'react'
import Icon from '../../../../../ui/Icon'
import { Button } from '../../../../../ui'

const CheckIcon = <Icon name={'check'} />

export type NotificationDropdownHeaderProps = {
  onMarkAllRead: (e: MouseEvent<HTMLButtonElement>) => void
}

const NotificationDropdownHeader = ({ onMarkAllRead }: NotificationDropdownHeaderProps) => {
  return (
    <div className={'px-[20px] pt-[10px] pb-[20px] flex flex-wrap justify-between items-center gap-[10px] '}>
      <div className={'flex-1 text-gray-900 text-[22px] leading-[28.6px] font-semibold'}>Notifications</div>
      <Button onClick={onMarkAllRead} size={'sm'} icon={CheckIcon}>
        Mark all as read
      </Button>
    </div>
  )
}

export default NotificationDropdownHeader
