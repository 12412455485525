import React from 'react'
import Icon from '../Icon'

type ArrowProps = {
  placement?:
    | 'top'
    | 'top-start'
    | 'top-end'
    | 'bottom'
    | 'bottom-start'
    | 'bottom-end'
    | 'right'
    | 'right-start'
    | 'right-end'
    | 'left'
    | 'left-start'
    | 'left-end'
}

const Arrow = ({ placement = 'top' }: ArrowProps) => {
  const getArrow = () => {
    let classes = 'absolute '
    let icon: IconName = 'triangle'
    switch (placement) {
      case 'top':
        classes += '-bottom-3'
        break
      case 'top-start':
        classes += '-bottom-3 left-0 ml-2'
        break
      case 'top-end':
        classes += '-bottom-3 right-0 mr-2'
        break
      case 'right':
        classes += '-left-3 top-1/2 transform -translate-y-1/2 rotate-90'
        break
      case 'right-start':
        classes += '-left-3 top-0 mt-0.5 rotate-90'
        break
      case 'right-end':
        classes += '-left-3 bottom-0 mb-0.5 rotate-90'
        break
      case 'bottom':
        classes += '-top-3 rotate-180'
        break
      case 'bottom-start':
        classes += '-top-3 left-0 ml-2 rotate-180'
        break
      case 'bottom-end':
        classes += '-top-3 right-0 mr-2 rotate-180'
        break
      case 'left':
        classes += '-right-3 top-1/2 transform -translate-y-1/2 -rotate-90'
        break
      case 'left-start':
        classes += '-right-3 top-0 mt-0.5 -rotate-90'
        break
      case 'left-end':
        classes += '-right-3 bottom-0 mb-0.5 -rotate-90'
        break
      default:
        break
    }

    return (
      <div className={classes}>
        <Icon name={icon} color={'gray-900'} size={24} />
      </div>
    )
  }

  return getArrow()
}

export default Arrow
