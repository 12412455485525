import React, { FC } from 'react'
import { ProgressLoader } from '../../../components/ui'

const ProgressLoaderExampleScreen: FC = () => {
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center items-center space-y-10">
      <h1 className="text-2xl font-bold mb-4">Progress Loaders</h1>
      <div className="grid grid-rows-4 gap-16 w-full max-w-screen-xl">
        <div className="flex flex-col items-center space-y-4">
          <h2>Establishing</h2>
          <div className="flex items-center space-x-20">
            <ProgressLoader variant={'circle'} size={'sm'} percent={25} color={'blue-600'} infoPosition={'center'} />
            <ProgressLoader variant={'line'} size={'sm'} percent={25} color={'blue-600'} />
          </div>
        </div>
        <div className="flex flex-col items-center space-y-4">
          <h2>Equipping</h2>
          <div className="flex items-center space-x-20">
            <ProgressLoader variant={'circle'} size={'lg'} percent={60} color={'orange-600'} />
            <ProgressLoader
              variant={'line'}
              size={'lg'}
              percent={60}
              color={'orange-600'}
              customInfo={'7 out of 10 completed'}
            />
          </div>
        </div>
        <div className="flex flex-col items-center space-y-4">
          <h2>Training</h2>
          <div className="flex items-center space-x-20">
            <ProgressLoader variant={'circle'} size={'sm'} percent={75} color={'fuchsia-600'} />
            <ProgressLoader
              variant={'line'}
              size={'sm'}
              percent={75}
              color={'fuchsia-600'}
              customInfo={'7 out of 10'}
            />
          </div>
        </div>
        <div className="flex flex-col items-center space-y-4">
          <h2>Custom Colors</h2>
          <div className="flex items-center space-x-20">
            <ProgressLoader variant={'circle'} size={'lg'} percent={90} color={'red'} infoPosition={'center'} />
            <ProgressLoader
              variant={'line'}
              size={'lg'}
              percent={90}
              color={'red'}
              customInfo={'9 out of 10 completed'}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProgressLoaderExampleScreen
