import React, { PropsWithChildren, useEffect, useRef } from 'react'
import { AnimatePresence, motion, useAnimation } from 'framer-motion'
import classNames from 'classnames'

type ModalSheetProps = PropsWithChildren<{
  open: boolean
  onClose: () => void
  className?: string
  radius?: number
}>
const ModalSheet = ({ radius = 10, open, onClose, className, children }: ModalSheetProps) => {
  const controls = useAnimation()
  const contentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (open) {
      controls.start({
        y: 0,
      })
    } else {
      controls.start({
        y: '100%',
      })
    }
  }, [open, controls])

  return (
    <div
      className={'fixed bottom-0 left-0 right-0 z-10' + (open ? ' pointer-events-auto' : ' pointer-events-none')}
      onClick={(e) => e.stopPropagation()}>
      <AnimatePresence>
        {open && (
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <div
              onClick={() => onClose()}
              className={'fixed top-0 bottom-0 right-0 left-0 bg-gray-900 opacity-30 -z-10'}
            />
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {open && (
          <motion.div
            drag={'y'}
            initial={{ y: '100%' }}
            animate={controls}
            exit={{ y: '100%' }}
            onDragEnd={(event, info) => {
              const contentHeight = contentRef?.current?.offsetHeight || 0
              const shouldClose = info.velocity.y > 50 || (info.offset.y > contentHeight / 2 && info.velocity.y >= 0)
              if (shouldClose) {
                onClose()
              } else if (open) {
                controls.start({
                  y: 0,
                })
              }
            }}
            dragConstraints={{
              top: 0,
            }}>
            <div ref={contentRef} className={classNames(className, 'color-red w-full', `rounded-t-[${radius}px]`)}>
              {children}
            </div>
            <div className={classNames(className, 'color-red absolute h-[1000px] bottom-[-999px] rounded-0 w-full')} />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default ModalSheet
