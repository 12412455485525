import { useRef, useEffect, useMemo } from 'react'

function useCallbackRef<T>(cb: ((a?: T) => void) | undefined) {
  const cbRef = useRef(cb)

  useEffect(() => {
    cbRef.current = cb
  })

  return useMemo(
    () =>
      (...args: T[]) =>
        cbRef.current?.(...args),
    [],
  )
}

export default useCallbackRef
