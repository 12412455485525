import React, { ForwardedRef } from 'react'
import ReactSelect, { GroupBase, Props } from 'react-select'
import { AsyncPaginateProps, WithAsyncPaginateType } from 'react-select-async-paginate'
import AsyncReactSelect, { AsyncProps } from 'react-select/async'
import Icon from '../Icon'
import Indicator from '../Indicator'
import './_select.css'

const DefaultOption = ({ innerProps, label, isSelected, isDisabled }: any) => {
  return (
    <div className={`select-option ${isSelected && 'selected'} ${isDisabled && 'disabled'}`} {...innerProps}>
      <span className="ml-2">{label}</span>
      {isSelected && <Icon name={`check`} />}
    </div>
  )
}

const DefaultDropdownIndicator = () => {
  return (
    <div className="select-dropdown-indicator">
      <Icon name={`chevron-down`} />
    </div>
  )
}

const DefaultClearIndicator = (props: any) => {
  const {
    innerProps: { ref, ...restInnerProps },
  } = props
  return (
    <div {...restInnerProps} ref={ref}>
      <div className="select-clear-indicator">
        <Icon name={'x'} size={16} />
      </div>
    </div>
  )
}

const DefaultLoadingIndicator = () => {
  return <Indicator isSpinning={true} />
}

const DefaultInvalidIndicator = () => {
  return <Icon name={'alert-triangle'} color={'red'} />
}

type PaginateProps<Option, Additional> = AsyncPaginateProps<Option, GroupBase<Option>, Additional, boolean>

export type SelectProps<Option, Additional> =
  | (
      | Omit<Props<Option, boolean, GroupBase<Option>>, 'form'>
      | Omit<AsyncProps<Option, boolean, GroupBase<Option>>, 'form'>
      | Omit<PaginateProps<Option, Additional>, 'form'>
    ) & {
      size?: 'lg' | 'md' | 'sm' | 'xs'
      componentAs?: ReactSelect | AsyncReactSelect | WithAsyncPaginateType
      isInvalid?: boolean
      errorMessage?: string
    }

declare module 'react' {
  function forwardRef<T, P>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null,
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null
}

const Select = React.forwardRef(function Select<Option, Additional>(
  {
    size = 'md',
    components,
    componentAs: Component = ReactSelect,
    isInvalid = false,
    errorMessage,
    ...rest
  }: SelectProps<Option, Additional>,
  ref: ForwardedRef<Option>,
) {
  const selectClass = `select w-full select-${size} ${isInvalid ? 'invalid' : ''}`
  const SelectComponent: any = Component
  return (
    <SelectComponent
      className={selectClass}
      classNamePrefix={'select'}
      ref={ref}
      components={{
        IndicatorSeparator: isInvalid ? DefaultInvalidIndicator : null,
        Option: DefaultOption,
        LoadingIndicator: DefaultLoadingIndicator,
        DropdownIndicator: DefaultDropdownIndicator,
        ClearIndicator: DefaultClearIndicator,
        ...components,
      }}
      {...rest}
    />
  )
})

export default Select
