import { createContext, useContext } from 'react'

const TabsContext = createContext<{
  value?: string
  onValueChange: (value: string) => void
  variant?: 'underline' | 'pill'
}>({
  value: undefined,
  onValueChange: () => {},
  variant: undefined,
})

export const TabsContextProvider = TabsContext.Provider

export function useTabs() {
  return useContext(TabsContext)
}

export default TabsContext
