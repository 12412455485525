import React from 'react'
import { MockedProvider } from '@apollo/client/testing'
import mockOperations from './operations'
import { cache } from '../services/ApolloService'

type DecoratedMockedProviderProps = {
  children: React.ReactNode
}

const DecoratedMockedProvider = ({ children }: DecoratedMockedProviderProps) => {
  return (
    <MockedProvider cache={cache} mocks={mockOperations}>
      {children}
    </MockedProvider>
  )
}

export default DecoratedMockedProvider
