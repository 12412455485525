import React, { useEffect } from 'react'
import { ProgramDropdown } from '../../../components/layout/components/SidePeek/ProgramDropdown'
import { useApolloClient } from '@apollo/client'
import { AuthFieldsFragmentDoc, Role } from '../../../graphql'
import { Button } from '../../../components/ui'
import { useDispatch } from 'react-redux'
import { toggleSidePeek } from '../../../store/layout/sidePeekSlice'

const ProgramDropdownExample = () => {
  const dispatch = useDispatch()

  const client = useApolloClient()

  useEffect(() => {
    client.cache.writeFragment({
      data: {
        __typename: 'Auth',
        me: {
          avatar: {
            src: 'https://randomuser.me/api/portraits/men/85.jpg',
          },
          firstName: 'Test',
          lastName: 'User',
          name: 'Test User',
          email: 'testuser@email.com',
          viewingAs: Role.Admin,
          lastViewedProgramId: '2',
          type: 'apprentice',
          __typename: 'Me',
        },
      },
      id: 'Auth:{}',
      fragment: AuthFieldsFragmentDoc,
    })
  }, [client])

  return (
    <div className={'ml-[100px] my-[300px]'}>
      <div className={'flex items-center bg-gray-900 w-[500px]'}>
        <ProgramDropdown />
      </div>
      <Button onClick={() => dispatch(toggleSidePeek())}>Toggle Side Peek Collapsed</Button>
    </div>
  )
}

export default ProgramDropdownExample
