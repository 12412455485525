const getTimeLapsedString = (date: Date) => {
  const now = new Date()
  const diff = now.getTime() - date.getTime()
  const seconds = Math.floor(diff / 1000)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)

  if (days >= 365) return `${Math.floor(days / 365)} year${Math.floor(days / 365) > 1 ? 's' : ''} ago`
  else if (days > 30) return `${Math.floor(days / 30)} month${Math.floor(days / 30) > 1 ? 's' : ''} ago`
  else if (days >= 7) return `${Math.floor(days / 7)} week${Math.floor(days / 7) > 1 ? 's' : ''} ago`
  else if (days > 0) return `${days} day${days > 1 ? 's' : ''} ago`
  else if (hours > 0) return `${hours} hour${hours > 1 ? 's' : ''} ago`
  else if (minutes > 0) return `${minutes} minute${minutes > 1 ? 's' : ''} ago`
  else if (seconds >= 0) return 'now'
}

export default getTimeLapsedString
