import React, { FC, ReactNode } from 'react'

type TagProps = {
  type: 'outline' | 'solid'
  prefix?: boolean | ReactNode
  suffix?: boolean | ReactNode
  suffixOnClick?: () => void
  prefixClass?: string
  suffixClass?: string
  children?: ReactNode
  color: 'blue' | 'fuchsia' | 'green' | 'orange' | 'red' | 'yellow' | 'gray' | 'darkGray'
  textColor?: boolean
}

const Tag: FC<TagProps> = ({
  type = 'outline',
  color = 'blue',
  prefix = false,
  suffix = false,
  suffixOnClick,
  suffixClass,
  children,
  textColor = true,
}) => {
  const outlineColor = color === 'darkGray' ? 'gray-400' : `${color}-200`
  const fillColor = color === 'darkGray' ? 'gray-200' : `${color}-100`

  const baseClasses = 'h-7 inline-flex items-center justify-center rounded-[14px] text-[15px] font-semibold'
  const typeClasses =
    type === 'outline'
      ? `border-2 border-${outlineColor} bg-transparent text-${
          textColor ? (color === 'darkGray' ? 'gray-800' : `${color}-600`) : 'gray-900'
        }`
      : `bg-${fillColor} text-${textColor ? color : '#333132'}`

  const prefixCircleClasses =
    color === 'darkGray' ? `bg-gray-600 rounded-full h-2.5 w-2.5` : `bg-${color}-400 rounded-full h-2.5 w-2.5`

  return (
    <div className={`${baseClasses} ${typeClasses} pl-[12px] ${!suffix ? 'pr-[12px]' : 'pr-[4px]'}`}>
      {typeof prefix === 'boolean' && prefix === true ? (
        <span className={`${prefixCircleClasses} mr-2`}></span>
      ) : (
        prefix
      )}
      {children}
      {suffix && (
        <button onClick={suffixOnClick} className={'cursor-pointer ml-[5px]'}>
          {typeof suffix === 'boolean' && suffix === true ? <span className={suffixClass}></span> : suffix}
        </button>
      )}
    </div>
  )
}

export default Tag
