import React, { useState, useRef, forwardRef, useEffect } from 'react'
import dayjs from 'dayjs'
import useControllableState from '../../../hooks/useControllableState'
import useMergedRef from '../../../hooks/useMergeRef'
import Calendar from './Calendar'
import BasePicker from './BasePicker'
import capitalize from '../../../utils/capitalize'
import useTheme from '../../../hooks/useTheme'

const DEFAULT_INPUT_FORMAT = 'MM/DD/YYYY'

export type DatePickerProps = {
  className?: string
  clearable?: boolean
  clearButton?: React.ReactNode
  closePickerOnChange?: boolean
  dateViewCount?: number
  dayClassName?: (date: Date) => string
  dayStyle?: (date: Date) => React.CSSProperties
  defaultMonth?: Date
  defaultOpen?: boolean
  defaultValue?: Date
  defaultView?: 'date' | 'month' | 'year'
  disabled?: boolean
  disableDate?: (date: Date) => boolean
  disableOutOfMonth?: boolean
  enableHeaderLabel?: boolean
  firstDayOfWeek?: 'monday' | 'sunday'
  hideOutOfMonthDates?: boolean
  hideWeekdays?: boolean
  inputFormat?: string
  inputPrefix?: React.ReactNode
  inputSuffix?: React.ReactNode
  inputtable?: boolean
  labelFormat?: {
    month: string
    year: string
    weekday: string
  }
  locale?: string
  maxDate?: Date
  minDate?: Date
  name?: string
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  onChange?: (date?: Date | string | null) => void
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void
  onDropdownClose?: () => void
  onDropdownOpen?: () => void
  openPickerOnClear?: boolean
  renderDay?: (date: Date) => React.ReactNode
  size?: 'lg' | 'md' | 'sm'
  style?: React.CSSProperties
  type?: string
  value?: Date | string
  weekendDays?: number[]
  placeholder?: string
}

const DatePicker = forwardRef((props: DatePickerProps, ref) => {
  const {
    className,
    clearable = true,
    clearButton,
    closePickerOnChange = true,
    dateViewCount = 1,
    dayClassName,
    dayStyle,
    defaultMonth,
    defaultOpen = false,
    defaultValue,
    defaultView,
    disabled = false,
    disableDate,
    enableHeaderLabel = true,
    disableOutOfMonth = false,
    firstDayOfWeek = 'sunday',
    hideOutOfMonthDates = false,
    hideWeekdays = false,
    inputFormat = 'MM/DD/YYYY',
    inputPrefix,
    inputSuffix,
    inputtable = true,
    labelFormat = {
      month: 'MMM',
      year: 'YYYY',
      weekday: 'ddd',
    },
    locale = 'en',
    maxDate,
    minDate,
    name = 'date',
    onBlur,
    onChange,
    onFocus,
    onDropdownClose,
    onDropdownOpen,
    openPickerOnClear = false,
    renderDay,
    size = 'md',
    style,
    type,
    value,
    weekendDays = [0, 6],
    ...rest
  } = props

  const { locale: themeLocale } = useTheme()

  const finalLocale = locale || themeLocale

  const dateFormat = type === 'date' ? DEFAULT_INPUT_FORMAT : inputFormat || DEFAULT_INPUT_FORMAT

  const [dropdownOpened, setDropdownOpened] = useState(defaultOpen)

  const inputRef = useRef<HTMLInputElement>()

  const [lastValidValue, setLastValidValue] = useState<Date | null>(defaultValue ?? null)

  const [_value, setValue] = useControllableState<Date | string | null>({
    prop: value || null,
    defaultProp: defaultValue || null,
    onChange,
  })

  const [calendarMonth, setCalendarMonth] = useState<Date | string | undefined>(
    _value && typeof _value !== 'function' ? _value : defaultMonth || new Date(),
  )

  const [focused, setFocused] = useState(false)

  const [inputState, setInputState] = useState(
    _value instanceof Date ? capitalize(dayjs(_value).locale(finalLocale).format(dateFormat)) : '',
  )

  const closeDropdown = () => {
    setDropdownOpened(false)
    onDropdownClose?.()
  }

  const openDropdown = () => {
    setDropdownOpened(true)
    onDropdownOpen?.()
  }

  useEffect(() => {
    if (value === null && !focused) {
      setInputState('')
    }

    if (value instanceof Date && !focused) {
      setInputState(capitalize(dayjs(value).locale(finalLocale).format(dateFormat)))
    }
  }, [value, focused, themeLocale, finalLocale, dateFormat])

  useEffect(() => {
    if (defaultValue && inputState && !focused) {
      setInputState(
        capitalize(
          dayjs(typeof _value !== 'function' ? _value : new Date())
            .locale(finalLocale)
            .format(dateFormat),
        ),
      )
    }
  }, [_value, dateFormat, defaultValue, finalLocale, focused, inputState, themeLocale])

  const handleValueChange = (date: Date | Date[]) => {
    if (typeof setValue === 'function' && !Array.isArray(date)) {
      setValue(date)
    }
    setInputState(
      capitalize(
        dayjs(!Array.isArray(date) ? date : new Date())
          .locale(finalLocale)
          .format(dateFormat),
      ),
    )
    closePickerOnChange && closeDropdown()
    window.setTimeout(() => inputRef.current?.focus(), 0)
  }

  const handleClear = () => {
    if (typeof setValue === 'function') {
      setValue(null)
    }
    setLastValidValue(null)
    setInputState('')
    openPickerOnClear && openDropdown()
    inputRef.current?.focus()
  }

  const parseDate = (date: Date | string) => dayjs(date, dateFormat, finalLocale).toDate()

  const setDateFromInput = () => {
    let date = typeof _value === 'string' ? parseDate(_value) : _value

    if (typeof date === 'function') {
      date = new Date()
    }

    if (maxDate && dayjs(date).isAfter(maxDate)) {
      date = maxDate
    }

    if (minDate && dayjs(date).isBefore(minDate)) {
      date = minDate
    }

    if (dayjs(date).isValid()) {
      if (typeof setValue === 'function' && date) {
        setValue(date)
      }
      setLastValidValue(date || null)
      setInputState(capitalize(dayjs(date).locale(finalLocale).format(dateFormat)))
      setCalendarMonth(date || new Date())
    } else {
      if (typeof setValue === 'function') {
        setValue(lastValidValue)
      }
    }
  }

  const handleInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    typeof onBlur === 'function' && onBlur(event)
    setFocused(false)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement> | React.KeyboardEvent<HTMLTextAreaElement>) => {
    console.log('handleKeyDown', event.key)
    if (event.key === 'Enter' && inputtable) {
      closeDropdown()
      setDateFromInput()
    }
  }

  const handleInputFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    typeof onFocus === 'function' && onFocus(event)
    setFocused(true)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log('handleChange', event.target.value)
    openDropdown()

    console.log('event.target.value', event.target.value)

    const date = parseDate(event.target.value)

    console.log('date', date)
    if (dayjs(date).isValid()) {
      typeof setValue === 'function' && setValue(date)
      setLastValidValue(date)
      setInputState(event.target.value)
      setCalendarMonth(date)
    } else {
      setInputState(event.target.value)
    }
  }

  return (
    <div className={'relative'}>
      <BasePicker
        inputtable={inputtable}
        dropdownOpened={dropdownOpened}
        setDropdownOpened={setDropdownOpened}
        ref={useMergedRef(ref, inputRef)}
        size={size}
        style={style}
        className={className}
        onChange={handleChange}
        onBlur={handleInputBlur}
        onFocus={handleInputFocus}
        onKeyDown={handleKeyDown}
        name={name}
        inputLabel={inputState}
        clearable={clearable && !!_value && !disabled}
        clearButton={clearButton}
        onClear={handleClear}
        disabled={disabled}
        onDropdownClose={onDropdownClose}
        onDropdownOpen={onDropdownOpen}
        type={type}
        inputPrefix={inputPrefix}
        inputSuffix={inputSuffix}
        {...rest}>
        <Calendar
          className={'test'}
          locale={finalLocale}
          month={parseDate(calendarMonth as Date)}
          defaultMonth={defaultMonth ? defaultMonth : typeof _value === 'function' ? new Date() : (_value as Date)}
          onMonthChange={setCalendarMonth}
          value={dayjs(typeof _value === 'function' ? new Date() : _value).toDate()}
          onChange={handleValueChange}
          labelFormat={labelFormat}
          dayClassName={dayClassName}
          dayStyle={dayStyle}
          disableOutOfMonth={disableOutOfMonth}
          minDate={minDate}
          maxDate={maxDate}
          disableDate={disableDate}
          firstDayOfWeek={firstDayOfWeek}
          preventFocus={true}
          dateViewCount={dateViewCount}
          enableHeaderLabel={enableHeaderLabel}
          defaultView={defaultView}
          hideOutOfMonthDates={hideOutOfMonthDates}
          hideWeekdays={hideWeekdays}
          renderDay={renderDay}
          weekendDays={weekendDays}
        />
      </BasePicker>
    </div>
  )
})

export default DatePicker
