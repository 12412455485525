import React, { FC } from 'react'
import { Brand } from '../../ui'

type FloatingBrandProps = {
  side?: boolean
}

const FloatingBrand: FC<FloatingBrandProps> = ({ side = false }) => {
  const sideClasses = side ? ' block lg:hidden' : ''
  // Disable pointer events on the floating brand to allow tests to find inputs that may be behind the backdrop
  const wrapperSideClasses = side ? '  z-10 pointer-events-none backdrop-blur-lg sm:backdrop-blur-none' : ''
  return (
    <div className={'fixed left-0 right-0 top-0 h-[100px]' + wrapperSideClasses}>
      <Brand className={'left-[40px] top-[35px] w-[126px] absolute' + sideClasses} />
    </div>
  )
}

export default FloatingBrand
