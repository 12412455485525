import React, { FC } from 'react'

type ContainerProps = {
  children: React.ReactNode
  backgroundColor?: string
}

type ViewProps = {
  children: React.ReactNode
  backgroundColor?: string
}

type DrawerProps = {
  isOpen: boolean
  children: React.ReactNode
}

export const DrawerContainer: React.FC<ContainerProps> = ({ children, backgroundColor = 'white' }) => {
  return <div className={'flex w-full h-full bg-' + backgroundColor}>{children}</div>
}

export const DrawerView: React.FC<ViewProps> = ({ children, backgroundColor = 'white' }) => {
  return <div className={'flex flex-grow justify-center items-center p-4 bg-' + backgroundColor}>{children}</div>
}

const Drawer: React.FC<DrawerProps> & { Container: FC<ContainerProps>; View: FC<ViewProps> } = ({
  isOpen,
  children,
}) => {
  const drawerStyles = isOpen ? 'w-drawer p-10' : 'w-0 p-0'
  const contentStyles = isOpen ? 'opacity-1 transition-opacity duration-200 delay-200' : 'opacity-0'

  return (
    <div className={`h-full bg-pink-500 overflow-y-auto transition-all duration-200 ease-out ${drawerStyles}`}>
      <div className={`${contentStyles}`}>
        <div className="h-px bg-gray-500 w-full my-2"></div>
        {children}
      </div>
    </div>
  )
}

Drawer.Container = DrawerContainer
Drawer.View = DrawerView

export default Drawer
