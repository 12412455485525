import React, { useState, useRef, forwardRef } from 'react'
import dayjs from 'dayjs'
import useControllableState from '../../../hooks/useControllableState'
import useMergedRef from '../../../hooks/useMergeRef'
import capitalize from '../../../utils/capitalize'
import RangeCalendar from './RangeCalendar'
import BasePicker from './BasePicker'
import useTheme from '../../../hooks/useTheme'

const validationRule = (val: Date[]) => Array.isArray(val) && val.length === 2

const isFirstDateSet = (val: Date[]) => Array.isArray(val) && val.length === 2

export type DatePickerRangeProps = {
  className?: string
  clearable?: boolean
  clearButton?: React.ReactNode
  closePickerOnChange?: boolean
  dateViewCount?: number
  dayClassName?: (date: Date) => string
  dayStyle?: (date: Date) => React.CSSProperties
  defaultMonth?: Date
  defaultOpen?: boolean
  defaultValue?: [Date | null, Date | null]
  defaultView?: 'month' | 'year' | 'date'
  disabled?: boolean
  disableDate?: (date: Date) => boolean
  disableOutOfMonth?: boolean
  enableHeaderLabel?: boolean
  firstDayOfWeek?: 'monday' | 'sunday'
  hideOutOfMonthDates?: boolean
  hideWeekdays?: boolean
  inputtable?: boolean
  inputFormat?: string
  inputPrefix?: React.ReactNode
  inputSuffix?: React.ReactNode
  labelFormat?: {
    month: string
    year: string
    weekday: string
  }
  locale?: string
  maxDate?: Date
  minDate?: Date
  name?: string
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  onChange?: (date?: [Date | null, Date | null] | null) => void
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void
  onDropdownClose?: () => void
  onDropdownOpen?: () => void
  openPickerOnClear?: boolean
  placeholder?: string
  renderDay?: (date: Date) => React.ReactNode
  singleDate?: boolean
  size?: 'lg' | 'md' | 'sm'
  style?: React.CSSProperties
  value?: [Date | null, Date | null]
  weekendDays?: number[]
  yearLabelFormat?: string
  separator?: string
}

const DatePickerRange = forwardRef((props: DatePickerRangeProps, ref) => {
  const {
    className,
    clearable = true,
    clearButton,
    closePickerOnChange = true,
    dateViewCount = 1,
    dayClassName = () => '',
    dayStyle = () => ({}),
    defaultMonth = new Date(),
    defaultOpen = false,
    defaultValue,
    defaultView = 'date',
    disabled,
    disableDate = () => false,
    enableHeaderLabel,
    disableOutOfMonth = false,
    firstDayOfWeek = 'sunday',
    hideOutOfMonthDates = false,
    hideWeekdays = false,
    inputtable = true,
    inputFormat,
    inputPrefix,
    inputSuffix,
    labelFormat = {
      month: 'MMMM',
      year: 'YYYY',
      weekday: 'dd',
    },
    separator = '→',
    locale,
    maxDate,
    minDate,
    onChange,
    onDropdownClose,
    onDropdownOpen,
    openPickerOnClear = false,
    placeholder = '',
    renderDay = (date: Date) => date.getDate().toString(),
    singleDate = false,
    size = 'md',
    style,
    value,
    weekendDays = [0, 6],
    yearLabelFormat,
    ...rest
  } = props

  const { locale: themeLocale } = useTheme()

  const finalLocale = locale || themeLocale

  const dateFormat = inputFormat || 'MM/DD/YYYY'

  const [dropdownOpened, setDropdownOpened] = useState(defaultOpen)
  const [calendarMonth, setCalendarMonth] = useState<Date | string | undefined>(defaultMonth)

  const inputRef = useRef<HTMLInputElement>()

  const [_value, setValue] = useControllableState<[Date | null, Date | null] | null>({
    prop: value || null,
    defaultProp: defaultValue !== undefined ? defaultValue : [null, null],
    onChange,
  })

  const handleValueChange = (range: [Date | null, Date | null]) => {
    if (typeof setValue !== 'function') return
    setValue(range)
    if (closePickerOnChange && !!range[1] && validationRule(range as [Date, Date])) {
      setDropdownOpened(false)
      onDropdownClose?.()
      window.setTimeout(() => inputRef.current?.focus(), 0)
    }
  }

  const valueValid = validationRule(_value as [Date, Date])
  const firstValueValid =
    isFirstDateSet(_value as [Date, Date]) && !!(_value && typeof _value !== 'function' && _value[1] !== null)

  const firstDateLabel =
    Array.isArray(_value) && _value[0] ? capitalize(dayjs(_value[0]).locale(finalLocale).format(dateFormat)) : ''

  const secondDateLabel =
    Array.isArray(_value) && _value[1] ? capitalize(dayjs(_value[1]).locale(finalLocale).format(dateFormat)) : ''

  const handleClear = () => {
    if (typeof setValue !== 'function') return
    setValue([null, null])
    openPickerOnClear && onDropdownOpen?.()
    inputRef.current?.focus()
  }

  const handleDropdownToggle = (isOpened: boolean) => {
    if (!isOpened && firstValueValid && Array.isArray(_value) && _value[1] === null) {
      handleClear()
    }
    setDropdownOpened(isOpened)
  }

  return (
    <div className={'relative'}>
      <BasePicker
        dropdownOpened={dropdownOpened}
        setDropdownOpened={handleDropdownToggle}
        ref={useMergedRef(ref, inputRef)}
        size={size}
        style={style}
        className={className}
        inputLabel={firstValueValid ? `${firstDateLabel} ${separator} ${secondDateLabel}` : ''}
        clearable={clearable && firstValueValid}
        clearButton={clearButton}
        onClear={handleClear}
        dateViewCount={dateViewCount}
        onDropdownClose={onDropdownClose}
        onDropdownOpen={onDropdownOpen}
        disabled={disabled}
        inputPrefix={inputPrefix}
        inputSuffix={inputSuffix}
        inputtable={inputtable}
        placeholder={placeholder}
        {...rest}>
        <RangeCalendar
          onMonthChange={setCalendarMonth}
          month={calendarMonth as Date}
          preventFocus={true}
          locale={finalLocale}
          defaultMonth={valueValid && Array.isArray(_value) ? (_value[0] as Date) : defaultMonth}
          value={_value as [Date, Date]}
          onChange={handleValueChange}
          labelFormat={labelFormat}
          dayClassName={dayClassName}
          dayStyle={dayStyle}
          disableOutOfMonth={disableOutOfMonth}
          minDate={minDate}
          maxDate={maxDate}
          disableDate={disableDate}
          firstDayOfWeek={firstDayOfWeek}
          enableHeaderLabel={enableHeaderLabel}
          singleDate={singleDate}
          dateViewCount={dateViewCount}
          defaultView={defaultView}
          hideOutOfMonthDates={hideOutOfMonthDates}
          hideWeekdays={hideWeekdays}
          renderDay={renderDay}
          weekendDays={weekendDays}
        />
      </BasePicker>
    </div>
  )
})

export default DatePickerRange
