import React from 'react'
import { Button, Form, Input } from '../../../../components/ui'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email address').required('Email is required'),
  password: Yup.string().required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), undefined], 'Passwords must match')
    .required('Confirm Password is required'),
})

const SmallHorizontal = () => {
  return (
    <Formik
      initialValues={{ email: '', username: '', password: '', confirmPassword: '', rememberMe: false }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        console.log('Submitted values:', values)
        setSubmitting(false)
      }}>
      {({ isSubmitting, errors, touched, getFieldProps }) => (
        <Form.Container>
          <Form.Item
            label={'Email'}
            errorMessage={touched.email && errors.email ? errors.email : undefined}
            invalid={touched.email && !!errors.email}>
            <Input
              size={'sm'}
              type={'email'}
              placeholder={'Email'}
              invalid={touched.email && !!errors.email}
              {...getFieldProps('email')}
            />
          </Form.Item>
          <Form.Item
            optional={true}
            label={'Username'}
            errorMessage={touched.username && errors.username ? errors.username : undefined}
            invalid={touched.username && !!errors.username}>
            <Input
              size={'sm'}
              type={'text'}
              placeholder={'Username'}
              invalid={touched.username && !!errors.username}
              {...getFieldProps('username')}
            />
          </Form.Item>
          <Form.Item
            label={'Password'}
            errorMessage={touched.password && errors.password ? errors.password : undefined}
            invalid={touched.password && !!errors.password}>
            <Input
              size={'sm'}
              type={'password'}
              placeholder={'Password'}
              invalid={touched.password && !!errors.password}
              {...getFieldProps('password')}
            />
          </Form.Item>
          <Form.Item
            label={'Confirm Password'}
            errorMessage={touched.confirmPassword && errors.confirmPassword ? errors.confirmPassword : undefined}
            invalid={touched.confirmPassword && !!errors.confirmPassword}>
            <Input
              size={'sm'}
              type={'password'}
              placeholder={'Confirm Password'}
              invalid={touched.confirmPassword && !!errors.confirmPassword}
              {...getFieldProps('confirmPassword')}
            />
          </Form.Item>
          <Form.Item>
            <div className={'flex items-center'}>
              <Field type={'checkbox'} {...getFieldProps('rememberMe')} id={'rememberMe'} />
              <label htmlFor={'rememberMe'} className="ml-1">
                Remember me
              </label>
            </div>
          </Form.Item>
          <Form.Actions layout={'horizontal'}>
            <Button size={'sm'}>Reset</Button>
            <Button variant={'solid'} size={'sm'} disabled={isSubmitting}>
              Submit
            </Button>
          </Form.Actions>
        </Form.Container>
      )}
    </Formik>
  )
}

export default SmallHorizontal
