import React from 'react'
import Tabs from '../../../components/ui/Tabs'
import Icon from '../../../components/ui/Icon'

const TabsExample: React.FC = () => {
  return (
    <div className={'flex flex-col p-[20%] gap-6'}>
      <div className={'w-full'}>
        <Tabs defaultValue="tab1">
          <Tabs.TabList>
            <Tabs.TabNav value="tab1" icon={<Icon name={'home'} color={'gray-dark'} />}>
              Home
            </Tabs.TabNav>
            <Tabs.TabNav value="tab2">Profile</Tabs.TabNav>
            <Tabs.TabNav value="tab3">Contact</Tabs.TabNav>
            <Tabs.TabNav value="tab5" disabled={true} icon={<Icon name={'coffee'} color={'gray-dark'} />}>
              More
            </Tabs.TabNav>
          </Tabs.TabList>
          <div className="p-4">
            <Tabs.TabContent value="tab1">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
                deserunt mollit anim id est laborum.
              </p>
            </Tabs.TabContent>
            <Tabs.TabContent value="tab2">
              <p>
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam
                rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt
                explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia
                consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui
                dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora
                incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum
                exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem
                vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum
                qui dolorem eum fugiat quo voluptas nulla pariatur?
              </p>
            </Tabs.TabContent>
            <Tabs.TabContent value="tab3">
              <p>
                At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum
                deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non
                provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.
                Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est
                eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas
                assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum
                necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum
                rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut
                perferendis doloribus asperiores repellat.
              </p>
            </Tabs.TabContent>
            <Tabs.TabContent value="tab5">
              <p>Disabled</p>
            </Tabs.TabContent>
          </div>
        </Tabs>
      </div>
      <div className={'w-full'}>
        <Tabs defaultValue="tab6" variant={'pill'}>
          <Tabs.TabList variant={'pill'}>
            <Tabs.TabNav value="tab6" icon={<Icon name={'home'} color={'gray-dark'} />}>
              Home
            </Tabs.TabNav>
            <Tabs.TabNav value="tab7">Profile</Tabs.TabNav>
            <Tabs.TabNav value="tab8">Contact</Tabs.TabNav>
            <Tabs.TabNav value="tab10" disabled={true} icon={<Icon name={'coffee'} color={'gray-dark'} />}>
              More
            </Tabs.TabNav>
          </Tabs.TabList>
          <div className="p-4">
            <Tabs.TabContent value="tab6">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
                deserunt mollit anim id est laborum.
              </p>
            </Tabs.TabContent>
            <Tabs.TabContent value="tab7">
              <p>
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam
                rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt
                explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia
                consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui
                dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora
                incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum
                exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem
                vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum
                qui dolorem eum fugiat quo voluptas nulla pariatur?
              </p>
            </Tabs.TabContent>
            <Tabs.TabContent value="tab8">
              <p>
                At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum
                deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non
                provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.
                Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est
                eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas
                assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum
                necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum
                rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut
                perferendis doloribus asperiores repellat.
              </p>
            </Tabs.TabContent>
            <Tabs.TabContent value="tab10">
              <p>Disabled</p>
            </Tabs.TabContent>
          </div>
        </Tabs>
      </div>
    </div>
  )
}

export default TabsExample
