import React, { FC } from 'react'

interface DividerProps {
  className?: string
}

const Divider: FC<DividerProps> = ({ className = '' }) => (
  <hr className={`flex-grow border-t border-gray-light ${className}`} />
)

export default Divider
