import React from 'react'
import { DatePicker } from '../../../components/ui'
import DatePickerRange from '../../../components/ui/DatePicker/DatePickerRange'

const DatePickerExample = () => {
  return (
    <div className={'w-100 p-[30px] flex justify-center'}>
      <div className={'w-[300px]'}>
        <div className={'mb-[20px] text-breakerBay-600 font-bold text-xl'}>Date Picker</div>
        <DatePicker placeholder={'Pick a Date'} />
        <div className={'my-[20px] text-breakerBay-600 font-bold text-xl'}>Date Picker (Ranged)</div>
        <DatePickerRange placeholder={'Pick a Range of Dates'} />
      </div>
    </div>
  )
}

export default DatePickerExample
