import React, { FC, useEffect } from 'react'
import UserDropdown from '../../../components/layout/components/Header/HeaderItem/UserDropdown'
import { useApolloClient } from '@apollo/client'
import { AuthFieldsFragmentDoc, Role } from '../../../graphql'

const UserDropdownExample: FC = () => {
  const client = useApolloClient()

  useEffect(() => {
    client.cache.writeFragment({
      data: {
        __typename: 'Auth',
        me: {
          avatar: {
            src: 'https://randomuser.me/api/portraits/men/85.jpg',
          },
          firstName: 'Test',
          lastName: 'User',
          name: 'Test User',
          email: 'testuser@email.com',
          viewingAs: Role.Admin,
          type: 'apprentice',
          __typename: 'Me',
        },
      },
      id: 'Auth:{}',
      fragment: AuthFieldsFragmentDoc,
    })
  }, [client])

  return (
    <div className="flex justify-center flex-col gap-3 items-center mt-24">
      <UserDropdown />
    </div>
  )
}

export default UserDropdownExample
