import FormContainer from './FormContainer'
import FormItem from './FormItem'
import { Form as FormikForm } from 'formik'
import FormActions from './FormActions'

const Form = FormikForm as typeof FormikForm & {
  Container: typeof FormContainer
  Item: typeof FormItem
  Actions: typeof FormActions
}
Form.Container = FormContainer
Form.Item = FormItem
Form.Actions = FormActions

export default Form
