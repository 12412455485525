import React from 'react'
import Icon from '../Icon'

const SuccessIcon = <Icon name={'check-circle'} color={'green-400'} />
const InfoIcon = <Icon name={'info'} color={'blue-400'} />
const WarningIcon = <Icon name={'alert-triangle'} color={'yellow-400'} />
const DangerIcon = <Icon name={'x-circle'} color={'red-400'} />

const ICONS = {
  success: {
    icon: SuccessIcon,
  },
  info: {
    icon: InfoIcon,
  },
  warning: {
    icon: WarningIcon,
  },
  error: {
    icon: DangerIcon,
  },
}

export type StatusIconProps = {
  type?: 'info' | 'success' | 'warning' | 'error'
  custom?: React.ReactNode
  iconColor?: string
}

const StatusIcon = (props: StatusIconProps) => {
  const { type = 'info', custom, iconColor } = props

  const icon = ICONS[type]

  return <span className={`text-2xl ${iconColor}`}>{custom || icon.icon}</span>
}

export default StatusIcon
