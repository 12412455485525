import React from 'react'
import Avatar from '../../../../../ui/Avatar'
import useAuth from '../../../../../../hooks/useAuth'

const UserDropdownButton = () => {
  const { user } = useAuth()
  return <Avatar shape="circle" size={30} name={user?.name} src={user?.avatar?.src} />
}

export default UserDropdownButton
