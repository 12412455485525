import React, { useState } from 'react'
import { Drawer } from '../../../components/ui'

const ExampleScreen = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen)
  }

  // move the Drawer above the Drawer.View to see it open from the left

  return (
    <div className="h-screen p-4 flex items-center justify-center flex-col">
      <Drawer.Container>
        <Drawer.View>
          <button className="text-lg mt-5 mr-5" onClick={toggleDrawer}>
            {isDrawerOpen ? 'Close Drawer' : 'Open Drawer'}
          </button>
          <h2 className="text-2xl mb-2">Main Content</h2>
        </Drawer.View>
        <Drawer isOpen={isDrawerOpen}>
          <div className="flex items-center w-full">
            <h2 className="text-sm mb-2 font-Figtree">THURSDAY July 20</h2>
            <hr className="w-1/2 border-gray-400 mx-2 mt-5" />
          </div>
          <p>Drawer Content</p>
        </Drawer>
      </Drawer.Container>
    </div>
  )
}

export default ExampleScreen
