import React, { ForwardedRef, forwardRef } from 'react'
import classNames from 'classnames'

type TabListProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  variant?: string
  className?: string
}

const TabList = forwardRef(function TabList(
  { className, children, variant = 'underline', ...rest }: TabListProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const tabListClass = classNames(
    'flex items-center',
    variant === 'underline' ? 'border-b-[1px] border-gray-200' : '',
    className,
  )
  return (
    <div role="tablist" className={'overflow-x-auto overflow-y-hidden'} ref={ref} {...rest}>
      <div className={tabListClass}>{children}</div>
    </div>
  )
})

export default TabList
