import React, { ForwardedRef, HTMLProps, ElementType, ReactNode, forwardRef } from 'react'
import classNames from 'classnames'

type TrProps<HTMLPropsT = HTMLTableRowElement> = HTMLProps<HTMLPropsT> & {
  asElement?: ElementType
  children?: ReactNode
}

const Tr = forwardRef(function Tr<HTMLPropsT, HTMLElementT = HTMLTableRowElement>(
  { children, asElement: Component = 'tr', className, onClick, ...rest }: TrProps<HTMLPropsT>,
  ref: ForwardedRef<HTMLElementT>,
) {
  const trClass = classNames(Component !== 'tr' && 'tr', className, onClick ? 'row-selectable' : '')
  return (
    <Component className={trClass} ref={ref} onClick={onClick} {...rest}>
      {children}
    </Component>
  )
})

export default Tr
