import React, { MouseEvent } from 'react'
import Icon from '../Icon'

type NextProps = {
  currentPage?: number
  pageCount?: number | null
  onNext?: (e: MouseEvent<HTMLSpanElement>) => void
  darkHover?: boolean
}

const Next = ({ currentPage = 1, pageCount = 1, onNext, darkHover = false }: NextProps) => {
  const disabled = currentPage === pageCount || pageCount === 0

  const onNextClick = (e: MouseEvent<HTMLSpanElement>) => {
    e.preventDefault()
    if (disabled) {
      return
    }
    onNext?.(e)
  }

  const pagerNextClass = `relative m-0 p-0 inline-flex items-center h-[30px] w-[30px] justify-center select-none rounded-[10px] ${
    disabled ? 'bg-white cursor-not-allowed' : `cursor-pointer bg-white hover:bg-${darkHover ? 'gray-100' : 'gray-50'}`
  }`

  return (
    <span className={pagerNextClass} onClick={onNextClick}>
      <Icon name={'chevron-right'} color={disabled ? 'gray-light' : 'gray-900'} />
    </span>
  )
}

export default Next
