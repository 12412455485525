import { useCallback, useEffect, useRef, useState } from 'react'
import useCallbackRef from './useCallbackRef'

type UncontrolledStateParams<Value> = {
  defaultProp: Value
  onChange?: (value?: Value) => void
}

function useUncontrolledState<Value>({ defaultProp, onChange }: UncontrolledStateParams<Value>) {
  const uncontrolledState = useState(defaultProp)
  const [value] = uncontrolledState
  const prevValueRef = useRef(value)
  const handleChange = useCallbackRef<Value>(onChange)

  useEffect(() => {
    if (prevValueRef.current !== value) {
      if (value) {
        handleChange(value)
      }
      prevValueRef.current = value
    }
  }, [value, prevValueRef, handleChange])

  return uncontrolledState
}

type ControllableStateParams<Value> = {
  prop?: Value
  defaultProp: Value
  onChange?: (value?: Value) => void
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
function useControllableState<Value>({
  prop,
  defaultProp,
  onChange = () => {},
}: ControllableStateParams<Value>): [Value, (value: Value) => void] {
  const [uncontrolledProp, setUncontrolledProp] = useUncontrolledState({ defaultProp, onChange })
  const isControlled = prop !== undefined
  const value = prop || uncontrolledProp
  const handleChange = useCallbackRef<Value>(onChange)

  const setValue = useCallback(
    (nextValue: Value) => {
      if (isControlled) {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const value = typeof nextValue === 'function' ? nextValue(prop) : nextValue
        if (value !== prop) {
          handleChange(value)
        }
      } else {
        setUncontrolledProp(nextValue)
      }
    },
    [isControlled, prop, setUncontrolledProp, handleChange],
  )

  return [value, setValue]
}

export default useControllableState
