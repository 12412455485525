import rootReducer from './rootReducer'
import { configureStore } from '@reduxjs/toolkit'

const middlewares: any[] = []

const store = configureStore({
  reducer: rootReducer(),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(middlewares),
  devTools: true,
})

export default store
