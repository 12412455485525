import React, { FC } from 'react'
import { Avatar } from '../../../components/ui'
const AvatarExample: FC = () => {
  return (
    <div style={styles.container}>
      <Avatar shape="rounded" size={'sm'} src={'https://randomuser.me/api/portraits/men/85.jpg'} />
      <Avatar shape="rounded" size={'sm'} name={'NED'} />
      <Avatar shape="rounded" size={'lg'} src={'https://randomuser.me/api/portraits/men/18.jpg'} />
      <Avatar shape="circle" size={'sm'} src={'https://randomuser.me/api/portraits/men/85.jpg'} />
      <Avatar shape="circle" size={'md'} name={'NED'} />
      <Avatar shape="circle" size={'lg'} src={'https://randomuser.me/api/portraits/men/18.jpg'} />
      <Avatar shape="square" size={'sm'} src={'https://randomuser.me/api/portraits/men/85.jpg'} />
      <Avatar shape="square" size={'md'} name={'NED'} />
      <Avatar shape="square" size={'lg'} src={'https://randomuser.me/api/portraits/men/18.jpg'} />
      <Avatar shape="square" size={'lg'} name={'NED'} />
    </div>
  )
}

const styles: CSSObject = {
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
}
export default AvatarExample
