import React, { FC } from 'react'

type DialogueHeaderProps = {
  children: React.ReactNode
  className?: string
  style?: React.CSSProperties
}

const DialogueHeader: FC<DialogueHeaderProps> = ({ children, className }) => (
  <div className={`flex justify-between items-center mb-5 font-semibold text-[18px] ${className}`}>{children}</div>
)

export default DialogueHeader
