import React from 'react'

export const SignIn = () => {
  return <div>Sign In</div>
}

export const ForgotPassword = () => {
  return <div>Forgot Password</div>
}

export const ResetPassword = () => {
  return <div>Reset Password</div>
}

export const Dashboard = () => {
  return <div>Dashboard</div>
}

export const People = () => {
  return <div>People</div>
}

export const Connections = () => {
  return <div>Connections</div>
}

export const Steps = () => {
  return <div>Steps</div>
}

export const Phases = () => {
  return <div>Phases</div>
}

export const MyJourney = () => {
  return <div>My Journey</div>
}

export const Organization = () => {
  return <div>Organization</div>
}

export const SignUp = () => {
  return <div>Sign Up</div>
}
