import React, { ChangeEventHandler, FocusEvent, FocusEventHandler, ForwardedRef, forwardRef } from 'react'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { Input } from '../index'
import useRootClose from '../../../hooks/useRootClose'
import { usePopper } from 'react-popper'
import CloseButton from '../CloseButton'
import Icon from '../Icon'
import { FieldInputProps, FormikProps } from 'formik'

dayjs.extend(localizedFormat)

const IconCalendar = <Icon name={'calendar'} />

export type BasePickerProps<FormValues, FieldValue> = {
  inputtable?: boolean
  className?: string
  clearButton?: React.ReactNode
  children?: React.ReactNode
  form?: FormikProps<FormValues>
  field?: FieldInputProps<FieldValue>
  size: 'lg' | 'md' | 'sm'
  inputLabel?: string
  inputPrefix?: React.ReactNode
  inputSuffix?: React.ReactNode
  clearable?: boolean
  placeholder?: string
  name?: string
  dropdownOpened: boolean
  setDropdownOpened: (value: boolean) => void
  onDropdownOpen?: () => void
  onDropdownClose?: () => void
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  onFocus?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement> | React.KeyboardEvent<HTMLTextAreaElement>) => void
  onClear?: () => void
  disabled?: boolean
  type?: string
  style?: React.CSSProperties
  dateViewCount?: number
}

const BasePicker = forwardRef(function <FormValues, FieldValue>(
  props: BasePickerProps<FormValues, FieldValue>,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const {
    inputtable,
    className,
    clearButton,
    children,
    form,
    field,
    size,
    inputLabel,
    inputPrefix,
    inputSuffix = IconCalendar,
    clearable = true,
    placeholder,
    name,
    dropdownOpened,
    setDropdownOpened,
    onDropdownOpen,
    onDropdownClose,
    onBlur,
    onFocus,
    onChange,
    onKeyDown,
    onClear,
    disabled,
    type,
  } = props

  const handleInputClick = () => {
    !inputtable ? toggleDropdown() : openDropdown()
  }

  const closeDropdown = () => {
    setDropdownOpened(false)
    onDropdownClose?.()
  }

  const suffixIconSlot = clearable ? (
    <div onClick={onClear}>{clearButton || <CloseButton className={'flex'} hoverBackground />}</div>
  ) : (
    <>{inputSuffix}</>
  )

  const toggleDropdown = () => {
    setDropdownOpened(!dropdownOpened)
    !dropdownOpened ? onDropdownOpen?.() : onDropdownClose?.()
  }

  const openDropdown = () => {
    setDropdownOpened(true)
    onDropdownOpen?.()
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement> | React.KeyboardEvent<HTMLTextAreaElement>) => {
    typeof onKeyDown === 'function' && onKeyDown(event)
    if ((event.key === 'Space' || event.key === 'Enter') && !inputtable) {
      event.preventDefault()
      openDropdown()
    }
  }

  const handleInputBlur = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    typeof onBlur === 'function' && onBlur(event)
    if (inputtable || event.relatedTarget === null) {
      closeDropdown()
    }
  }

  const handleInputFocus = (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    typeof onFocus === 'function' && onFocus(event)
    if (inputtable) {
      openDropdown()
    }
  }

  const [referenceElement, setReferenceElement] = React.useState<HTMLDivElement | null>(null)
  const [popperElement, setPopperElement] = React.useState<HTMLDivElement | null>(null)

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        enabled: true,
        options: {
          offset: [0, 45],
        },
      },
    ],
  })

  useRootClose(() => closeDropdown(), {
    triggerTarget: referenceElement,
    overlayTarget: popperElement,
    disabled: !dropdownOpened,
    listenEscape: false,
  })

  return (
    <>
      <div ref={setReferenceElement} className="relative" />
      <Input
        ref={ref}
        form={form}
        field={field}
        className={className}
        placeholder={placeholder}
        size={size}
        name={name}
        value={inputLabel}
        readOnly={!inputtable}
        suffix={suffixIconSlot}
        suffixClickable={clearable}
        prefix={inputPrefix}
        onClick={handleInputClick}
        onKeyDown={handleKeyDown}
        onBlur={handleInputBlur}
        onFocus={handleInputFocus}
        onChange={onChange}
        autoComplete="off"
        type={type}
        closeButtonSuffix={clearable}
        disabled={disabled}
      />
      <div className="picker" ref={setPopperElement} style={{ ...styles.popper, right: 0 }} {...attributes.popper}>
        {dropdownOpened && <div className="picker-panel">{children}</div>}
      </div>
    </>
  )
})

export default BasePicker
