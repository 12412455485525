import React from 'react'
import NotificationDropdownFooter from '../../../components/layout/components/Header/HeaderItem/NotificationDropdown/NotificationDropdownFooter'

const NotificationDropdownFooterExample = () => {
  return (
    <div className={'flex justify-center items-center h-screen'}>
      <div className={'w-[530px] bg-gray-100 body-sm'}>
        <NotificationDropdownFooter onClickViewAll={() => alert('clicked view all!')} />
      </div>
    </div>
  )
}

export default NotificationDropdownFooterExample
