import React, { FC } from 'react'
import Bronze from './Bronze.svg'

const BronzeSvg: FC = () => {
  return (
    <div>
      <img src={Bronze} alt="Bronze" />
    </div>
  )
}
export default BronzeSvg
