import React, { ReactNode, InputHTMLAttributes, TextareaHTMLAttributes, useState, ForwardedRef, useMemo } from 'react'
import EyeClosedIcon from '../../../assets/eye-closed-icon.svg'
import EyeOpenIcon from '../../../assets/eye-open-icon.svg'
import Icon from '../Icon/index'
import classNames from 'classnames'
import { FormikProps, FieldInputProps } from 'formik'
import { get } from 'lodash'

type BaseProps<FormValues, FieldValue> = {
  disabled?: boolean
  invalid?: boolean
  prefix?: ReactNode | string
  size?: 'lg' | 'md' | 'sm' | 'xs'
  suffix?: ReactNode | string
  suffixClickable?: boolean
  textArea?: boolean
  unstyle?: boolean
  type?: string
  onInputShow?: () => void
  onInputBlur?: () => void
  field?: FieldInputProps<FieldValue>
  form?: FormikProps<FormValues>
  closeButtonSuffix?: boolean
  className?: string
}

type InputProps<FormValues, FieldValue> = Omit<InputHTMLAttributes<HTMLInputElement>, 'prefix' | 'form'> &
  BaseProps<FormValues, FieldValue>
type TextAreaProps<FormValues, FieldValue> = Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'prefix' | 'form'> &
  BaseProps<FormValues, FieldValue>

type Props<FormValues, FieldValue> = TextAreaProps<FormValues, FieldValue> | InputProps<FormValues, FieldValue>

const Input = React.forwardRef(function Input<FormValues, FieldValue>(
  {
    disabled = false,
    invalid: _invalid,
    prefix,
    size = 'md',
    suffix,
    textArea = false,
    type = 'text',
    unstyle = false,
    onInputShow,
    onInputBlur,
    suffixClickable,
    closeButtonSuffix = false,
    form,
    field,
    className,
    ...rest
  }: Props<FormValues, FieldValue>,
  ref: ForwardedRef<HTMLInputElement | HTMLTextAreaElement>,
) {
  const [isPasswordShown, setPasswordShown] = useState(false)

  const togglePasswordVisibility = () => {
    setPasswordShown((prevState) => {
      if (prevState && onInputBlur) {
        onInputBlur()
      }
      if (!prevState && onInputShow) {
        onInputShow()
      }
      return !prevState
    })
  }

  const invalid = useMemo(() => {
    let isInvalid = false
    let fieldName = field?.name || rest.name
    if (_invalid !== undefined) {
      isInvalid = _invalid
    } else if (form && fieldName) {
      const { touched, errors } = form
      const touchedField = get(touched, fieldName)
      const errorField = get(errors, fieldName)
      isInvalid = !!touchedField && !!errorField
    }
    return isInvalid
  }, [form, _invalid, field, rest.name])

  const inputClass = `w-full px-3 rounded-form border rounded-md focus:outline-none
${invalid ? 'ring-red ring-2 pr-10' : 'border-gray-light focus:ring-breakerBay focus:ring-2'}
${size === 'lg' ? 'h-lg text-xl' : size === 'sm' ? 'h-sm text-base' : size === 'xs' ? 'h-xs text-sm' : 'h-md text-lg'}
${disabled ? 'opacity-50 cursor-not-allowed' : ''}
${prefix ? 'pl-10' : ''} ${suffix || type === 'password' ? 'pr-10' : ''}`

  const wrapperClass = `relative flex items-center w-full bg-form-bg rounded-form`

  const inputType = type === 'password' && isPasswordShown ? 'text' : type

  const inputSuffixClass = closeButtonSuffix ? 'input-suffix x-button' : 'input-suffix'

  const inputProps = {
    ...field,
    ...rest,
  }

  return (
    <div className={wrapperClass}>
      {prefix && (
        <div
          className={`absolute inset-y-0 left-0 ${
            size === 'xs' ? 'pl-2.5' : 'pl-3.5'
          } pr-2 flex items-center pointer-events-auto`}>
          {prefix}
        </div>
      )}
      {textArea ? (
        <textarea
          ref={ref as React.MutableRefObject<HTMLTextAreaElement>}
          className={classNames(`${inputClass} py-2.5 px-3.75`, className)}
          style={{ height: '103px' }}
          disabled={disabled}
          {...(inputProps as InputHTMLAttributes<HTMLTextAreaElement>)}
        />
      ) : (
        <input
          ref={ref as React.MutableRefObject<HTMLInputElement>}
          className={classNames(inputClass, className)}
          type={inputType}
          disabled={disabled}
          onBlur={field?.onBlur}
          onChange={field?.onChange}
          {...(inputProps as InputHTMLAttributes<HTMLInputElement>)}
        />
      )}
      {type === 'password' && !invalid && (
        <button
          type={'button'}
          className="absolute inset-y-0 right-0 pr-3 flex items-center"
          onClick={togglePasswordVisibility}>
          {isPasswordShown ? <img src={EyeClosedIcon} alt="Eye Close" /> : <img src={EyeOpenIcon} alt="Eye Open" />}
        </button>
      )}
      {invalid && !textArea && (
        <div className="absolute inset-y-0 right-0 pr-3.5 pl-2 flex items-center pointer-events-none">
          <Icon name={'alert-triangle'} color={'red'} />
        </div>
      )}
      {!invalid && suffix && (
        <div
          className={`absolute inset-y-0 test right-0 ${inputSuffixClass} pl-2 flex items-center pointer-events-${
            suffixClickable ? 'auto' : 'none'
          }`}>
          {suffix}
        </div>
      )}
    </div>
  )
})

export default Input
